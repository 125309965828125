import { useEffect, useState } from "react";
import { fetchData } from "../../services/Service";

export default function CurrencyDropdown({ filterText, onCurrencyChange }) {
    const [currencyItemsTt, setCurrencyItemsTt] = useState([]);
    const [currencyItemsFc, setCurrencyItemsFc] = useState([]);
    const [currencyItemsCn, setCurrencyItemsCn] = useState([]);
    useEffect(() => {
        fetchData()
            .then((res) => {
                // console.log(res.responseData.CN, "asdasdasdasdasdasd");
                setCurrencyItemsTt(res.responseData.TT);
                setCurrencyItemsCn(res.responseData.CN);
                setCurrencyItemsFc(res.responseData.FC);
            })
            .catch((e) => {
                console.log(e.message);
            });
    }, []);
    // console.log("filterText", filterText);
    let currencyOptions = "";
    if (filterText == "buy_card") {
        let currencylistOptionsFc = currencyItemsFc.map((obj) => {
            return (
                <option key={obj.currency_code} value={obj.currency_code}>
                    {obj.currency_code} ({obj.currency_name})
                </option>
            );
        });
        currencyOptions = currencylistOptionsFc;
    } else if (filterText == "buy_currency") {
        let currencylistOptionsCn = currencyItemsCn.map((obj) => {
            return (
                <option key={obj.currency_code} value={obj.currency_code}>
                    {obj.currency_code} ({obj.currency_name})
                </option>
            );
        });
        currencyOptions = currencylistOptionsCn;
    } else {
        let currencylistOptionsTt = currencyItemsTt.map((obj) => {
            return (
                <option key={obj.currency_code} value={obj.currency_code}>
                    {obj.currency_code} ({obj.currency_name})
                </option>
            );
        });
        currencyOptions = currencylistOptionsTt;
    }
    return (
        <>
            <div className="col-md-6">
                <div className="mb-3">
                    <label htmlFor="product" className="form-label">
                        Currency<span>*</span>
                    </label>
                    <select
                        id="currencyId"
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => onCurrencyChange(e.target.value)}
                    >
                        <option value="">Select Currency</option>
                        {currencyOptions}
                    </select>
                </div>
            </div>
        </>
    );
}
