import React, { useEffect, useState } from 'react';
import Modal from "react-modal";
import { useParams } from 'react-router-dom';
import { commonConstant } from '../CommonElements/constants';
import { Payoffline } from '../Components/payment/PayOffline.js';
import '../css/Quotation.css';
const moment = require('moment-timezone');

const QuotationApproveScreen = () => {
  const [showMessage, setShowMessage] = useState('')
  const [remmitanceData, setRemmitanceData] = useState({});
  const [leadInfo, setLeadInfo] = useState({});
  const [quotDetailId, setQuotDetailId] = useState({});
  const [isQuotApproved, setIsQuotApprove] = useState(null);
  const [isQuotExp, setIsQuotExp] = useState(false)
  const [paymentStatus,setPaymentStatus]=useState(false)
  let params = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const handlePayOffline = () => {

    openModal();
  }

  const updateLeadStatus = () => {
    const data = new URLSearchParams();
    data.append("quot_detail_id", params.id);
    data.append("status", params.status);
    setQuotDetailId(params.id);
    fetch(
      commonConstant.BACKEND_API_URL + 'partners/quotations/status',
      {
        method: 'PUT',
        headers: {},
        body: data
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "......");
        let st=result?.responseData?.orderDetails?.payment_status
        setPaymentStatus(result.responseData.orderDetails.payment_status)

        // console.log(result.responseData.created)
        const current_time = moment().tz('Asia/Kolkata').format();
        const created_at = moment(result.responseData.created + "").tz('Asia/Kolkata').format();
        const timeDifference = moment(current_time).diff(created_at, 'seconds');


        if (timeDifference >= commonConstant.QUOTATION_EXPIRY_TIME && st!=='Success') {
          setIsQuotExp(true);
          setShowMessage("Your Quotation has expired. Please reach out to us for assistance.")
          console.log('Quotation  is no longer Valid ');
          return
        }

        if (result.responseCode === 200) {
          if(result.responseData.status === "Quotation Rejected")
          {
            setIsQuotApprove(false);
             setShowMessage("Thank you for your response. If you have any feedback or specific requirements, please let us know.")
          }else {
            setIsQuotApprove(true)
          }
          // result.responseData.status === "Quotation Rejected" ? setIsQuotApprove(false) : setIsQuotApprove(true)
          setShowMessage(<h2 dangerouslySetInnerHTML={{ __html: result.responseMessage }}></h2>);

          setRemmitanceData(result?.responseData?.remmitanceData?.data);
          setLeadInfo(result?.responseData?.leadInfo?.responseData?.rows);
        } else {
          setShowMessage(<h1 dangerouslySetInnerHTML={{ __html: result.responseMessage }}></h1>);
          setRemmitanceData(result?.responseData?.remmitanceData?.data);
          setLeadInfo(result?.responseData?.leadInfo?.responseData?.rows);
        }
        console.log(Object.keys(remmitanceData).length);
      })
      .catch((error) => {
        setShowMessage("Please retry");
        console.error('Error:', error);
      });
  }

  useEffect(() => {
    updateLeadStatus();
  }, []);
  useEffect(() => {
    const makePayment = () => {
      const fieldsToEncode = ['api_key', 'amount', 'name', 'email', 'mobile', 'etm_estimate_no', 'callback_uri', 'env'];
      fieldsToEncode.forEach(field => {
        const inputField = document.getElementById(field);
        if (inputField) {
          inputField.value = window.btoa(inputField.value);
        }
      });



      document.getElementById('myForm').submit();
    };
    const submitButton = document.getElementById('submitButton');
    if (submitButton) {
      submitButton.addEventListener('click', makePayment);
    }
    return () => {
      // Cleanup the event listener when the component unmounts
      document.getElementById('submitButton')?.removeEventListener('click', makePayment);
    };
  }, [remmitanceData]);
  const callbackUri = commonConstant.BACKEND_API_URL + `partners/quotations/payment-response/${quotDetailId}/${params.bankid}`;//`http://localhost:3005/PaymentSuccess/${quotDetailId}`;
  return (
    <>
    
        <div className='main-div'>
          <a href="/" class="navbar-brand mrg-t30 db text-center"><img src="https://www.remitx.com/remitx-images/remitx.svg" alt="Logo" title="" /></a>
          { JSON.stringify(showMessage)?.includes("unsuccessful") && <div className='text-center mrg-t30'><img width="32" height="32" src="https://img.icons8.com/external-those-icons-flat-those-icons/32/external-Delete-interface-those-icons-flat-those-icons.png" alt="external-Delete-interface-those-icons-flat-those-icons"/></div>}
          { JSON.stringify(showMessage)?.includes("Payment confirmed!") &&<div className='text-center mrg-t30'><img width="38" height="38" src="https://img.icons8.com/color/38/ok--v1.png" alt="ok--v1"/></div> }
          <h1 className='text-center'>{showMessage}</h1>
          {!!Object?.keys(remmitanceData)?.length && (
            <div className="outerbox">
              <div className="mainbx">
               <div id="cartbreakup">
                  <div className="table-responsive mrg-t20">
                    <table className="table">
                      <tbody><tr>
                        <td className="black">Total Amount</td>
                        <td className="remitx-c">{(remmitanceData.fx_inr_total*1).toFixed(2)}</td>
                      </tr>
                        <tr>
                          <td className="black">GST on Total Amount</td>
                          <td className="remitx-c">{(remmitanceData.gst_fx_value * 1).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td className="black">Bank Charges</td>
                          <td className="remitx-c">{(remmitanceData.transaction_fee * 1).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td className="black">GST on Bank Charges</td>
                          <td className="remitx-c">{(remmitanceData.gst_transaction_fee * 1).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td className="black">Bill Amount</td>
                          <td className="remitx-c">{(remmitanceData.total_bill_amount*1).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td className="black">TCS</td>
                          <td className="remitx-c">{(remmitanceData.tcs_value*1).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td className="black">Grand Value</td>
                          <td className="remitx-c bold">{(remmitanceData.final_bill_amount*1).toFixed()}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='col text-center'>
                    <form id="myForm" method="POST" action="https://uatpayment.remitx.com/" encType="application/x-www-form-urlencoded">
                      <input type="hidden" value={commonConstant.PAYMENT_API_KEY} name="api_key" id="api_key" />
                      <input type="hidden" value={leadInfo[0].first_name + " " + leadInfo[0].last_name} name="name" id="name" />
                      <input type="hidden" value={leadInfo[0].email} name="email" id="email" />
                      <input type="hidden" value={leadInfo[0].mobile} name="mobile" id="mobile" />
                      <input type="hidden" value={commonConstant.PAYMENT_ENV} name="env" id="env" />
                      <input type="hidden" value={ process.env.REACT_APP_PAYMENT_ENV==='prod'? (remmitanceData.final_bill_amount*1).toFixed():commonConstant.UAT_AMOUNT} name="amount" id="amount" />
                      <input type="hidden" value={quotDetailId} name="etm_estimate_no" id="etm_estimate_no" />
                      <input type="hidden" value={callbackUri} name="callback_uri" id="callback_uri" />

                      {paymentStatus==='Failed' || !paymentStatus? <>

                      {!isQuotExp && isQuotApproved && <input type="button" id="submitButton" value={JSON.stringify(showMessage)?.includes("unsuccessful")?"Retry Payment":"Pay Now"} className="btn btn-outline-primary paybtn mrg-r15" />}
                      {!isQuotExp&& isQuotApproved &&<input onClick={handlePayOffline} type="button" value="Pay Offline" className="btn btn-outline-primary paybtn" />}
                      </>: <p></p>}                   
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

      <Modal
        isOpen={isModalOpen}
        className="planModal"
        onRequestClose={closeModal}
      >
        <Payoffline bankId={params.bankid}  remmitanceData={remmitanceData} closeModal={closeModal} />
      </Modal>
    </>
  )
};

export default QuotationApproveScreen;