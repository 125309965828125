import React from 'react';
import { Routes, Route } from "react-router-dom";

import AdminHeader from '../Admin/AdminHeader';
import AdminSidebar from '../Admin/AdminSidebar';
import AdminDashboard from '../Admin/AdminDashboard';
import AdminUsers from '../Admin/AdminUsers';
import AdminProducts from '../Admin/AdminProducts';
const AdminPanel = () => {
    console.log("calling adminpanel");
    return (
      <div>
        <AdminSidebar />
        <Routes>
          <Route exact path="/admin" element={<AdminDashboard/>} />
          <Route path="/admin/users" element={<AdminUsers/>} />
          <Route path="/admin/products" element={<AdminProducts/>} />
        </Routes>
      </div>
    );
  };

  export default AdminPanel;