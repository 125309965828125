import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Modal from "react-modal";
import { headers, showInfoNotification, slicedData } from "../CommonElements/commonFunctions.js";
import { commonConstant } from "../CommonElements/constants";
import { Pagination } from '../Components/Pagination/Pagination';
import Addsubagent from '../Components/Subagent/Addsubagent';
import ConfirmationBox from '../Components/Subagent/ConfirmationBox';
import SubagentRow from '../Components/Subagent/Subagentrow';


function ManageSubagents() {
const [users,setUsers]=useState([]);
const [isModalOpen, setIsModalOpen] = useState(false);
const [currentUser,setCurrentUser]=useState(null);
const [mode,setMode]=useState("new")
const [isConfirmationOpen,setIsConfirmationOpen]=useState(false);
const [currentPage, setCurrentPage] = useState(1);
const [totalPages,setTotalPages]=useState(0)


const closeModal = () => {
    setIsModalOpen(false);
    setCurrentUser(null)
    
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleEdit = (user) => {

    setMode("edit");
    setCurrentUser(user)
    openModal();
    // console.log(user)
  };

  const handleDelete = async (user) => {
    
    try{
        let result=await axios.put(`${commonConstant.BACKEND_API_URL}partners/user/subagent/delete`,
         { userid: user.id }, 
         {headers:headers()});   
        showInfoNotification("Deleted Successfully")
      
        const updatedData=users.filter((item)=>item.id!=user.id);
        console.log("Deleted ");
        console.log(updatedData.length)

        setTotalPages(Math.floor(updatedData?.length / 11));
        setUsers(updatedData)
        
    }catch(e){console.log(e)}
    finally{
        setCurrentUser(null)
       

    }

  };

  useEffect(()=>{
    const fetchSubAgents= async()=>{
        try{
    
        let data=await axios.get(`${commonConstant.BACKEND_API_URL}partners/user/subagent`,{  headers: headers()})
        console.log(data.data.responseData)
        setUsers(data.data.responseData.rows)
        
        }catch(e){
    console.log(e)
        }
      }
      fetchSubAgents();
      setTotalPages(Math.floor(users?.length / 10));
  },[isModalOpen])

  let data = slicedData(users, currentPage);
  // const totalPages  = Math.floor(users?.length / 10);

  return (
    <>
    <div className="page-body-fx container mrg-t30">
        <div className="row table-responsive">
          <button className='subagent-btn btn btn-outline-primary subagent-add mb-3'
          onClick={()=>{setMode("new");openModal()}}
          >
            Add User</button>
        <table className='subagent-table table'>
        <thead>
          <tr>
          <th width="10" className='subagent-th'>#</th>
            <th width="20" className='subagent-th'>Name</th>
            <th width="15" className='subagent-th'>Code</th>
            <th width="20" className='subagent-th'>Phone</th>
            <th width="20" className='subagent-th' >Email</th>
            <th width="15" className='subagent-th' >Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((user,index) => (
            <SubagentRow key={user.id} index={index} user={user} handleDelete={handleDelete} handleEdit={handleEdit} isConfirmationOpen={isConfirmationOpen} setIsConfirmationOpen={setIsConfirmationOpen} setCurrentUser={setCurrentUser}   />
          ))}
          {!data?.length && <tr style={{}}><td className='text-center' colSpan={6}>No record found</td></tr>}
        </tbody>
      
        <Modal
            isOpen={isConfirmationOpen }
            className="planModal"
            onRequestClose={()=>{setIsConfirmationOpen(false)}}
          >
            <ConfirmationBox isConfirmationOpen={isConfirmationOpen} setIsConfirmationOpen={setIsConfirmationOpen}  currentUser={currentUser} handleDelete={handleDelete}  />
          </Modal>
      </table>
      <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
     
      <Modal
            isOpen={isModalOpen }
            className="planModal"
            onRequestClose={closeModal}
          >
            <Addsubagent  closeModal={closeModal} mode={mode} currentUser={currentUser} users={users} setUsers={setUsers} setCurrentUser={setCurrentUser} />
          </Modal>
        </div>
    </div>
</>
    
  );
}

export default ManageSubagents;
