import { getItemValue } from "../../CommonElements/commonFunctions";
import { commonConstant } from "../../CommonElements/constants";

let products1 = commonConstant.PRODUCT['tt'];
let products2 = commonConstant.PRODUCT['fc'];
let products3 = commonConstant.PRODUCT['cn'];
export default function ProductDropdown({
    onFilterTextChange,
}) {
    // let all_products = [...products1, ...products2, ...products3];
    let products1_options = products1.map(function (item, i) {
        let item_val = getItemValue(item);
        return (
            <option key={item} value={item}>
                {item_val}
            </option>
        );
    });
    let products2_options = products2.map(function (item, i) {
        let item_val = getItemValue(item);
        return (
            <option key={item} value={item}>
                {item_val}
            </option>
        );
    });
    let products3_options = products3.map(function (item, i) {
        let item_val = getItemValue(item);
        return (
            <option key={item} value={item}>
                {item_val}
            </option>
        );
    });
    return (
        <>
            <div className="col-md-6">
                <div className="mb-3">
                    <label htmlFor="product" className="form-label">
                        Product<span>*</span>
                    </label>
                    <select
                        className="form-select"
                        id="productId"
                        aria-label="Default select example"
                        onChange={(e) => onFilterTextChange(e.target.value)}
                    >
                        <option value="">Select Type</option>
                        <optgroup label="Send Money Aborad">
                            {products1_options}
                        </optgroup>
                        <optgroup label="Multicurrency Card">
                            {products2_options}
                        </optgroup>
                        <optgroup label="Currency Notes">
                            {products3_options}
                        </optgroup>
                    </select>
                </div>
            </div>
        </>
    );
}
