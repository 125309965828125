

const ConfirmationBox=({setIsConfirmationOpen,currentUser,handleDelete})=>{

    return (
        <>
            <div className="modal-heading">
                <h3>Are You Sure ?<br></br>Do You want to remove this user?</h3>
                <h3></h3>
                <h2></h2>
                <p></p>
                <button
                                type="button"
                                className="subagent-btn"
                                onClick={() => {

                                   handleDelete(currentUser)
                                    setIsConfirmationOpen(false)
                                    
                                }}
                            >
                               Yes
                            </button>
                            <button
                                type="button"
                                className="subagent-btn"
                                onClick={() => {
                                    // buttonClick();
                                   
                                    setIsConfirmationOpen(false)
                                    
                                }}
                            >
                               No
                            </button>

                
                <button
                    type="button"
                    className="btn-close close"
                    aria-label="Close"

                    onClick={()=>{setIsConfirmationOpen(false)}}
                ></button>
            </div>
            
        </>
    );

}

export default ConfirmationBox