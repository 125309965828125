import { useEffect, useState } from "react";
import { ShowRate, setFloat, setNumeric } from "../../CommonElements/commonFunctions";
let rateRange = {
    minInputRate: 0,
    maxInputRate: 0,
};
/**
 * 
 * currencyText : For currency,
  *  rateRange: Rate Range is for reference,
  *  setLiveRate: html for showing rate range,
 *   setLiveRateInput: For setting the live rate,
  *  setRate,
  *  setError,
 */
export default function RateField({
    currencyText,
    setLiveRateInput,
    setRate,
    setError,
    rate,
    error,
    product
}) {
    const [liveRate, setLiveRate] = useState("");
    useEffect(() => {
        ShowRate({
            currencyText,
            rateRange,
            setLiveRate,
            setLiveRateInput,
            setRate,
            setError,
            product
        });
    }, [currencyText,product]);
    const handleRateChange = (event) => {
        console.log("rate changed");

        let val=event.target.value

        // Check if rate is valid or not (it should have 1 decimal or 0 decimal)
        let countDecimal=val.split(".").length - 1

        if(countDecimal!=0 && countDecimal!=1)
        return;
        setRate(event.target.value);
        if (
            event.target.value < rateRange.minInputRate ||
            event.target.value > rateRange.maxInputRate
        ) {
            console.log(
                "Rate value should be between " +
                    rateRange.minInputRate +
                    " and " +
                    rateRange.maxInputRate
            );
            setError(
                "Rate value should be between " +
                    rateRange.minInputRate +
                    " and " +
                    rateRange.maxInputRate
            );
            // event.preventDefault();
            return false;
        } else {
            setError(false);
        }
        // setLiveRateInput(event.target.value);
    };
    return (
        <div className="col-md-6 pr">
            <div className="mb-3">
                <label htmlFor="rate" className="form-label">
                    Rate<span>*</span>
                </label>
                <input
                    onKeyDown={setFloat}
                    style={{ color: error ? "red" : "black" }}
                    value={rate}
                    className="form-control"
                    placeholder="Please Enter"
                    onChange={handleRateChange}
                    name="rate"
                />
            </div>
            {error && (
                <span style={{ "font-size": "10px", color: "red" }}>
                    Invalid Rate
                </span>
            )}
            {liveRate}
        </div>
    );
}
