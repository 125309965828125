import React from 'react';

const SubagentRow = ({ user,index,handleEdit,handleDelete,isConfirmationOpen,setIsConfirmationOpen,setCurrentUser}) => {
 
  return (
    <tr className='subagent-row'>
       <td className='subagent-td'>{index+1}</td>
     
      <td className='subagent-td'>{user.name}</td>
      <td className='subagent-td'>{user.code}</td>
     
      <td className='subagent-td'>{user.mobile}</td>
      <td className='subagent-td'>{user.email}</td>
      <td style={{textAlign: 'left'}}>
        <button className='subagent-btn' onClick={() => handleEdit(user)}>Edit</button>
        <button  className='subagent-btn' onClick={() =>{setCurrentUser(user);  setIsConfirmationOpen(true)}}>Delete</button>
      </td>
    </tr>
  );
};

export default SubagentRow;
