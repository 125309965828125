import '../css/pdf.css';
const GstInvoicing = () => {
    return (
        <html>
            <head>
            <meta charset="utf8" />
            <title>Commercial Vehicle Package</title>
            </head>
            <body>
            <div class="container">
                <div class="page">
                    <div class="header">
                        <table>
                            <tr>
                                <td width="33%"  style={{'font-weight': 'bold'}}>
                                    <img src="https://c2t8y6e6.rocketcdn.me/wp-content/uploads/2022/01/Cp_logo_blue.png" width="190px" />
                                </td>
                                <td width="33%" style={{'text-align': 'center'}}>
                                    <div class="text-center">Tax Invoice</div>
                                </td>
                                <td width="33%" style={{'text-align': 'center'}}>
                                    
                                </td>
                            </tr>
                        </table>
                        <table>
                            <tr>
                                <td width="10%" style={{'font-weight': 'bold'}}>
                                    <div>IRN</div>
                                    <div>Ack No</div>
                                    <div>Ack Date</div>
                                </td>
                                <td width="57%"  style={{'font-weight': 'bold'}}>
                                    <div>: 2a8b88f209a8f6f17553ca8c27381858d6a8947b10579e3d203705a2b5142</div>
                                    <div>: 173212178561910</div>
                                    <div>: 6-Jan-23</div>
                                </td>
                                <td width="33%"  style={{'text-align': 'center'}}>
                                    <div>e-invoice</div>
                                    <img src="https://c2t8y6e6.rocketcdn.me/wp-content/uploads/2022/01/Cp_logo_blue.png" width="120px" />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="main">
                            <table>
                                <tbody>
                                        <tr>
                                        <td rowspan="4" colspan="3">
                                            <div>Jamboree Education Private Limited</div>
                                            <div>7B/3, 4th Floor, Taimoor Nagar</div>
                                            <div>Above HDFC Bank</div>
                                            <div>Maharani Bagh</div>
                                            <div>Delhi -110065</div>
                                            <div>GSTIN/UIN : 07AABCJ064D2Z5</div>
                                            <div>State Name : Delhi, Code : 07</div>
                                            <div>CIN : U74899DL2001PTC109413</div>
                                            <div>E-mail : accounts@jamboreeindia.com</div>
                                        </td>
                                        <td>
                                            <div>Invoice No.</div>
                                            <div>DL/2022-23/0613</div>
                                        </td>
                                        <td>
                                            <div>Dated</div>
                                            <div>31-Dec-22</div>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td>
                                            <div>Delivery Note</div>
                                            <div>31-Dec-22</div>
                                        </td>
                                        <td>
        
                                        </td>
                                        </tr>
                                        <tr>
                                        <td>
                                            <div>Reference No. & Date</div>
                                            <div>31-Dec-22</div>
                                        </td>
                                        <td>
                                            Other References
                                        </td>
                                        </tr>
                                        <tr>
                                        <td>
                                            <div>Buyer’s Order No.</div>
                                            <div></div>
                                        </td>
                                        <td>
                                            Dated
                                        </td>
                                        </tr>
                                        <tr>
                                        <td rowspan="2"  colspan="3">
                                            <div>Consignee (Ship to)</div>
                                            <div><b>Capital India Finance Limited</b></div>
                                            <div>1st Floor, A-27, 8-A Kashi House</div>
                                            <div>Connaught Place, New Delhi</div>
                                            <div>Delhi -110001</div>
                                            <div>GSTIN/UIN : 07AABCJ064D2Z5</div>
                                        </td>
                                        <td>
                                            <div>Invoice No.</div>
                                            <div>DL/2022-23/0613</div>
                                        </td>
                                        <td>
                                            <div>Dated</div>
                                            <div>31-Dec-22</div>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td>
                                            <div>Delivery Note</div>
                                            <div>31-Dec-22</div>
                                        </td>
                                        <td>
        
                                        </td>
                                        </tr>
                                        <tr>
                                        <td  colspan="3">
                                            <div>Consignee (Ship to)</div>
                                            <div><b>Capital India Finance Limited</b></div>
                                            <div>1st Floor, A-27, 8-A Kashi House</div>
                                            <div>Connaught Place, New Delhi</div>
                                            <div>Delhi -110001</div>
                                            <div>GSTIN/UIN : 07AABCJ064D2Z5</div>
                                        </td>
                                        <td colspan="2">
                                            
                                        </td>
                                        </tr>
                                </tbody>
                            </table>
                            <table>
                                <tr>
                                    <td width="5%">S.No</td>
                                    <td width="65%">Particulars</td>
                                    <td width="10%">HSA/SAC</td>
                                    <td width="10%">GST Rate</td>
                                    <td width="10%">Amount</td>
                                    </tr>
                                    <tr>
                                    <td width="5%">1</td>
                                    <td width="65%">
                                        <div>Commission Income Delhi</div>
                                        <div>Commission for the month of Dec, 2022</div>
                                        <div style={{'text-align':'right'}}>CGST Delhi</div>
                                        <div style={{'text-align':'right'}}>SGST Delhi</div><br />
                                        <div>Less : Tax Deducted at Source - TDS
                                            Short & Excess</div>
                                    </td>
                                    <td width="10%">996211</td>
                                    <td width="10%">18%</td>
                                    <td width="10%" style={{'text-align':'right'}}>
                                        <b>2,104.00</b><br /><br />
                                        <div style={{'text-align':'right'}}><b>189.36</b></div>
                                        <div style={{'text-align':'right'}}><b>189.36</b></div><br />
                                        <div style={{'text-align':'right'}}><b>(-)105.00</b></div>
                                        <div style={{'text-align':'right'}}><b>0.28</b></div>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td width="5%">&nbsp;</td>
                                    <td width="65%" style={{'text-align':'right'}}>Total</td>
                                    <td width="10%">&nbsp;</td>
                                    <td width="10%">&nbsp;</td>
                                    <td width="10%" style={{'text-align':'right'}}><b>2,378.00</b></td>
                                    </tr>
                            </table>
                            <table>
                                <tr>
                                    <td width="85%">
                                        <div>Amount Chargeable (in words)</div>
                                        <div><b>INR Two Thousand Three Hundred Seventy Eight Only</b></div>
                                    </td>
                                    <td width="15%" style={{'text-align':'right'}}>E. &O.E.</td>
                                    </tr>
                            </table>
                            <table>
                                <tr>
                                    <td rowspan="2" width="65%">HSN/SAC</td>
                                    <td rowspan="2" width="10%">Taxable
                                        Value</td>
                                    <td colspan="2" width="10%">Central Tax</td>
                                    <td colspan="2" width="10%">State Tax</td>
                                    <td rowspan="2" width="10%">Taxable
                                        Value</td>
                                    </tr>
                                    <tr>
                                    <td>Rate</td>
                                    <td>Amount</td>
                                    <td>Rate</td>
                                    <td>Amount</td>
                                    </tr>
                                    <tr>
                                    <td>996211</td>
                                    <td>2,104.00</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                    <td style={{'text-align':'right', 'font-weight': '600'}}>Total</td>
                                    <td style={{'font-weight':'600'}}>2,104.00</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    </tr>
                            </table>
                            <table>
                                <tr>
                                    <td width="100%">
                                        <div>Tax Amount (in words) : <b>INR Three Hundred Seventy Eight and Seventy Two paise Only</b></div>
                                        <table border="0">
                                            <tr>
                                                <td width="50%" style={{'border':'0px'}}>
                                                    <div>Remarks:</div>
                                                    <div>Being invoice raised for Commission for the month of Dec - 22 Declaration</div>
                                                    <div>Declaration for Invoicing as Follows:</div>
                                                    <div>1. This invoice shows the actual price of the goods/services described</div>
                                                    <div>2. Payment is due after the invoice is received</div>
                                                    <div>3. Interest @ 18% per annum charged for delayed payment</div>
                                                    <div>4. Subject to “Delhi” Jurisdition</div>
                                                    <div>Thanking you for Business with us!</div>
                                                </td>
                                                <td width="23%" style={{'border':'0px','text-align':'left'}}>
                                                    <div>Company's Bank Details</div>
                                                    <div>A/c Holder's Name</div>
                                                    <div>Bank Name</div>
                                                    <div>A/c No</div>
                                                    <div>Branch & IFS Code</div>
                                                    <div>SWIFT Code</div>
                                                </td>
                                                <td width="27%" style={{'border':'0px','text-align':'left','font-weight':'600'}}>
                                                    <div>Jamboree Education Pvt Ltd</div>
                                                    <div>HDFC Bank Ltd</div>
                                                    <div>00892000003884</div>
                                                    <div>New Friends Colony & HDFC0000089</div>
                                                    <div>HDFCINBB</div>
                                                </td>
                                            </tr>
                                            </table>
                                            <table border="0">
                                            <tr>
                                                <td width="20%" style={{'border' :'0px'}}>
                                                    &nbsp;
                                                </td>
                                                <td width="20%" style={{'border' :'0px','text-align': 'left'}}>
                                                    &nbsp;
                                                </td>
                                                <td width="60%" style={{'font-weight' :'600','text-align': 'left'}}>
                                                    <table>
                                                    <tr>
                                                        <td style={{'border' :'0px'}}> <div>AKRITA KALRA</div></td>
                                                        <td style={{'border' :'0px'}}> <div>
                                                            <div>Digitally signed</div>
                                                        <div>D by AKRITA KALRA</div>
                                                        <div>DDated 2023,01,06</div>
                                                        <div>D16:46:57 +05:30</div>
                                                        </div></td>
                                                        <td style={{'border' :'0px','font-weight':'600'}}><div>for Jamboree Education Private Limited</div>
                                                            <br />
                                                            <br />
                                                            <div>Authorised Signatory</div></td>
                                                    </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            </table>
                                    </td>
                                    </tr>
                            </table>
                            
                        </div>
                </div>
            </div>    
        </body>
        </html>
    );
}
export default GstInvoicing;