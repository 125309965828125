import { message, notification } from "antd";
import moment from "moment";
import Cookies from "universal-cookie";
import { getLiveCurrencyRate, share } from "../services/Service";
import { commonConstant } from "./constants";
import messages from "./messages";
notification.config({
    placement: "topRight",
    duration: 3,
    maxCount: 3,
});
export function getItemValue(item) {
    if (!item) {
        return item;
    }
    return item
        .split("_")
        .join(" ")
        .replace(/\b[a-z]/g, function (letter) {
            return letter.toUpperCase();
        });
}

export const fetchCookie = () => {
    const cookies = new Cookies();
    return "Bearer " + cookies.get("access_token");
};

export const getItemLocal = (keyName) => localStorage.getItem(keyName);

export const tokenList = () => {
    let values = {};
    commonConstant["TOKENS"].map((item) => {
        let returnObj = {};
        let data = getItemLocal(item);
        if (data) {
            returnObj[item] = data;
        }
        values = { ...values, ...returnObj };
        return null;
    });
    return values;
};

export const headers = () => ({
    "Content-Type": "application/json",
    "x-jwt": fetchCookie(),
    ...tokenList(),
});

export const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};
export const getFormattedDate = (date, seprator = "-") => {
    // console.log("data is", date);
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;
    return year + seprator + month + seprator + day;
};

export const getDate=()=>{
    const currentDate = new Date();

const day = currentDate.getDate().toString().padStart(2, '0');
const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
const year = currentDate.getFullYear();

const formattedDate = `${year}-${month}-${day}`;

 return formattedDate

}

export const getLocalDate=(date,format)=>{

    const timeOption = {
        timeZone: 'Asia/Kolkata',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    };
    const d = new Date(date);
    if(format==='YYYY-MM-DD')
    return  d.toLocaleString('fr-CA',timeOption);
    if(format==='DD-MM-YYYY')
    return d.toLocaleString('en-GB',timeOption).split(',')[0].replaceAll('/','-')
    
}

export const getRadioSelectedValue = (radios) => {
    let selected = false;
    for (var i = 0; i < radios.length; i++) {
        if (radios[i].checked) {
            selected = radios[i].value;
            break;
        }
    }
    return selected;
};

export const showSuccessNotification = (successMessage) => {
    notification.success({
        message: "Success",
        description: successMessage,
    });
};

export const showErrorNotification = (errorMessage, placement) => {
    notification.error({
        message: "Error",
        description: errorMessage,
        placement: placement,
    });
};
export const showInfoNotification = (infoMessage, placement) => {
    notification.info({
        message: "Info",
        description: infoMessage,
        placement: placement,
    });
};

export const createCartTableRow = (obj) => {
    let rateObject = obj.charges;
    let cartDataObject = obj.cartItem;
    let tcs_content = "";
    let bank_charges_content = "";
    let transaction_fee_content = "";
    if (rateObject?.tcs_value) {
        tcs_content = `<p class="margin0"><span>TCS</span> : <span>${
            rateObject.tcs_value * 1
        }</span></p>`;
    }
    if (rateObject?.correspondent_bank_fee) {
        bank_charges_content = `<p class="margin0"><span>Bank Charges</span> : <span>${
            rateObject.correspondent_bank_fee * 1
        }</span></p>`;
        bank_charges_content += `<p class="margin0"><span>GST on Bank Charges</span> : <span>${
            rateObject.gst_correspondent_bank_fee * 1
        }</span></p>`;
    }
    if (rateObject?.transaction_fee) {
        transaction_fee_content = `<p class="margin0"><span>Bank Charges</span> : <span>${
            rateObject.transaction_fee * 1
        }</span></p>`;
        transaction_fee_content += `<p class="margin0"><span>GST Bank Charges</span> : <span>${
            rateObject.gst_transaction_fee * 1
        }</span></p>`;
    }

    let innerContent = `<tr><td><input type="hidden" value="" id="cart_lead_info_${
        cartDataObject.id
    }" />
                      <input type="radio" name="cart_quotation" value=${
                          cartDataObject.id
                      } checked='checked'
      /></td>
          <td>${getItemValue(cartDataObject.product)}</td>
          <td>${cartDataObject.currency} ${cartDataObject.fx_amount}</td>
          <td>${cartDataObject.rate}</td>
          <td>${ (rateObject?.final_bill_amount-rateObject.tcs_value * 1).toFixed(2)}</td>
          <td>
          <p class="margin0"><span title="GST on Total Amount">GST</span> : <span>${
              rateObject?.gst_fx_value * 1
          }</span></p>
          ${bank_charges_content}
          ${transaction_fee_content}
          </td>
          ${ (obj.cartItem.product==='buy_currency'|| obj.cartItem.product==='buy_card') ?`<td class="text-left">Not Applicable</td>`:`<td class="text-left">${capitalizeFirstLetter(cartDataObject.nestro_payer)}</td>`}
          </tr>`;
    var z = document.createElement("tr"); // is a node
    z.innerHTML = innerContent;
    return z;
};
export const createQuotationsTableRow = (obj, lead_info_id) => {
    let rateObject = obj.charges;
    let cartItem = obj.cartItem;
    let innerContent = `
        <td><input type="radio" name="confirmed_quotation" value=${JSON.stringify(
            { quotaion_info_id: cartItem.id, lead_info_id: lead_info_id }
        )} checked='checked'
            /></td>
              <td>${cartItem.currency} ${cartItem.fx_amount}</td>
              <td>${cartItem.rate}</td>
              <td>${rateObject.final_bill_amount}</td>
              <td>${cartItem.commission || 0}</td>
              <td>${rateObject.tcs_value || 0}</td>
              <td class="quotation-state">Quotation Pending</td>
              <td>${moment(cartItem.created).format("YYYY-MM-DD")}</td>
                `;
    var z = document.createElement("tr"); // is a node
    z.className = "quotation-" + cartItem.id;
    z.innerHTML = innerContent;
    return z;
};
export const RateRoundof = (rate) => {
    const x1 = rate * 100 + 0.0001;
    const y = Math.floor(x1);
    const decimal_point = x1 - y;
    if (decimal_point >= 0.5) {
        return (y + 1) / 100;
    } else {
        const roundto3 = (rate) => Math.floor(rate * 1000 + 0.00001) / 1000;
        return roundto3(rate);
    }
};

export const CalcCommission = (
    settlement_rate,
    sale_rate,
    fx_amount,
    commission
) => {
    if (!fx_amount || !commission || !settlement_rate) {
        return false;
    }
    try {
        let settlementRate = settlement_rate;
        let forexAmount = fx_amount;

        let commissionAmount = 0;

        commissionAmount = commission.commissionValue;

        if (commission.commissionType === "paisa") {
            commissionAmount *= forexAmount;
        }
        //commission if percentage
        else if (commission.commissionType === "percentage") {
            //INR amount X Commission in %
            let inrAmount = forexAmount * sale_rate;
            commissionAmount *= inrAmount;
        }
        //commission if ratio
        else if (commission.commissionType === "ratio") {
            //net profit X Commission Ratio %
            let netProfit = (sale_rate - settlementRate) * forexAmount;
            commissionAmount *= netProfit;
        }

        commissionAmount = RateRoundof(commissionAmount);

        return commissionAmount;
    } catch (e) {
        console.log(e);
        return false;
    }
};

const LetterMap = {
    a: "A",
    b: "B",
    c: "C",
    d: "D",
    e: "E",
    f: "F",
    g: "G",
    h: "H",
    i: "I",
    j: "J",
    k: "K",
    l: "L",
    m: "M",
    n: "N",
    o: "O",
    p: "P",
    q: "Q",
    r: "R",
    s: "S",
    t: "T",
    u: "U",
    v: "V",
    w: "W",
    x: "X",
    y: "Y",
    z: "Z",
};

export const CapL = (item) => {
    let capL = LetterMap[item[0]];
    return capL + item.slice(1);
};

export const toCamelCase = (name) => {
    let data = name.split("-").map((item1) => CapL(item1));
    data[0] = data[0].toLowerCase();
    return data.join("");
};

export const mobileValidate = (mobile) =>
    mobile.match(
        /^[\+]?[(]?[6-9]{1}[0-9]{2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    );

export const emailValidate = (email) => {
    const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return re.test(email);
};

export const setAlphaNumeric = (event) => {
    if (!/[a-zA-Z0-9 ]/.test(event.key)) {
        event.preventDefault();
    }
};
export const setEmailValidation = (event) => {
    if (!/[a-zA-Z0-9@. ]/.test(event.key)) {
        event.preventDefault();
    }
};
export const setAlpha = (event) => {
    if (!/[a-zA-Z ]/.test(event.key)) {
        event.preventDefault();
    }
};
// export const setNumeric = (event) => { console.log(event.key);if (!/[0-9 ]/.test(event.key) && event.key !== 'Backspace') { event.preventDefault(); }}
export const setNumeric = (e) => {
    let ctrlDown = false;
    let ctrlKey = 17,
        vKey = 86,
        cKey = 67,
        rKey = 82;
    const specialKeys = [];
    specialKeys.push(8, 9, 46, 27, 13); // Allow: backspace, delete, tab, escape, enter
    const keyCode = e.which ? e.which : e.keyCode;
    if (e.ctrlKey) {
        ctrlDown = true;
    }
    const ret =
        /[0-9 ]/.test(e.key) ||
        specialKeys.indexOf(keyCode) != -1 ||
        // Allow: Ctrl
        keyCode === ctrlKey ||
        // Allow: Ctrl+A
        (keyCode === 65 && ctrlDown) ||
        // Allow: Ctrl+v
        (keyCode === vKey && ctrlDown) ||
        // Allow: Ctrl+c
        (keyCode === cKey && ctrlDown) ||
        // Allow: Ctrl+r
        (keyCode === rKey && ctrlDown) ||
        // Allow: home, end, left, right, down, up
        (keyCode >= 35 && keyCode <= 40);
    if (!ret) {
        e.preventDefault();
    }
    // document.getElementById("error").style.display = ret ? "none" : "inline";
    // return ret;
};

export const setFloat = (e) => {
    let ctrlDown = false;
    let ctrlKey = 17,
        vKey = 86,
        cKey = 67,
        rKey = 82;
    const specialKeys = [];
    specialKeys.push(8, 9, 46, 27, 13,190); // Allow: backspace, delete, tab, escape, enter, Dot
    const keyCode = e.which ? e.which : e.keyCode;

    
    if (e.ctrlKey) {
        ctrlDown = true;
    }
    const ret =
        /[0-9 ]/.test(e.key) ||
        specialKeys.indexOf(keyCode) != -1 ||
        // Allow: Ctrl
        keyCode === ctrlKey ||
        // Allow: Ctrl+A
        (keyCode === 65 && ctrlDown) ||
        // Allow: Ctrl+v
        (keyCode === vKey && ctrlDown) ||
        // Allow: Ctrl+c
        (keyCode === cKey && ctrlDown) ||
        // Allow: Ctrl+r
        (keyCode === rKey && ctrlDown) ||
        // Allow: home, end, left, right, down, up
        (keyCode >= 35 && keyCode <= 40);
    if (!ret) {
        e.preventDefault();
    }
    // document.getElementById("error").style.display = ret ? "none" : "inline";
    // return ret;
};




export const sortByOrderDate = (data, order = 1) => {
    const sortedData = data.slice().sort((a, b) => {
        const dateA = a.OrderDate
            ? convertToDate(a.OrderDate)
            : new Date(a.Date);
        const dateB = b.OrderDate
            ? convertToDate(b.OrderDate)
            : new Date(b.Date);
        return order ? dateA - dateB : dateB - dateA;
    });
    return sortedData;
};

export const convertToDate = (dateString) => {
    const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    const trimmedDateString = dateString.replace(/\s+/g, " ").trim(); // Remove multiple spaces and trim
    const parts = trimmedDateString.split(" ");
    const month = monthNames.indexOf(parts[0]);

    const day = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);
    const timeParts = parts[3].split(":");
    const hour =
        (parseInt(timeParts[0], 10) % 12) + (parts[4] === "AM" ? 0 : 12);
    const minutes = parseInt(timeParts[1], 10);

    return new Date(year, month, day, hour, minutes);
};

export const ShowRate = ({
    currencyText,
    rateRange,
    setLiveRate,
    setLiveRateInput,
    setRate,
    setError,
    product
}) => {
    //console.log("currencyText ==== >>",currencyText);
    if (!currencyText) {
        setLiveRate("");
        return;
    }
    getLiveCurrencyRate(currencyText,product).then((res) => {
        let startRange = RateRoundof(res.responseData.settlementRate)
        let endRange = RateRoundof(startRange + 4);
        setLiveRate(
            <div className="rate-show rate-show-ui">
                Range {startRange} - {endRange}
            </div>
        );
        rateRange.minInputRate = startRange;
        rateRange.maxInputRate = endRange;
        setLiveRateInput(startRange);
        setRate(startRange);
        setError(false);
    });
};

export function CurrencyAmountBox({
    fxAmount,
    filterText,
    currencyText,
    setAmountBox,
    rate,
    rateInput,
    commission,
}) {
    // console.log("fxAmount", fxAmount, filterText, rate, "............");
    // let countrySelectElement = document.getElementById('country');

    if (!fxAmount || !filterText || !currencyText) {
        return;
    }
    // let product = getProductByValue(filterText);
    let inr_amount = rate * 1 * (fxAmount * 1);
    inr_amount = RateRoundof(inr_amount);
    console.log(rate, fxAmount, "...........", inr_amount);

    let commission_amount = CalcCommission(
        rateInput,
        rate,
        fxAmount,
        commission
    );

    setAmountBox(
        <>
            <div className="col-md-12 mrg-t30">
                <div className="inramount">
                    INR Amount{" "}
                    <span>
                        <span>&#8377;</span>
                        {inr_amount}
                    </span>
                </div>
                <div className="inramount">
                    Your Revenue{" "}
                    <span>
                        <span>&#8377;</span>
                        {commission_amount}
                    </span>
                </div>
            </div>
        </>
    );
}
// Accept the array of values and return number of missing values
const CountMissingFields=(fields)=>{
    let count=0;
    fields.map((field)=>{if(!field)count++})
    return count;
}

export const shareWithRemitx = async (props,allowEmpty=false) => {
    try {
        const {
            name,
            email,
            mobile,
            product,
            purpose,
            remittanceType,
            currencyText,
            rate,
            fxAmount,
            remarks,
            closeModal,
        } = props;

        let fields=[name,email,mobile,product,currencyText,rate]
        let missingFields=CountMissingFields(fields)
        let errorMessage = "";
        if(missingFields>1)
        errorMessage=messages.FillAllFileds;

        else if (!name) errorMessage = messages.EnterName;

        else if (!mobile) errorMessage = messages.ValidMobile;

        else if (!email && !allowEmpty) errorMessage = messages.ValidEmail;

        else if (!product) errorMessage = "Please fill the product.";
        else if (!currencyText) errorMessage = "Please fill the currency.";
        else if (!rate) errorMessage = "Please fill the rate.";
        else if (
            commonConstant.PRODUCT["tt"].includes(product) &&
            !remittanceType
        ) {
            errorMessage = "Please fill the remittance type.";
        } else if (
            !commonConstant.PRODUCT["tt"].includes(product) &&
            !purpose
        ) {
            errorMessage = "Please fill the purpose.";
        }else if(mobile.length<10) errorMessage=messages.ValidMobile
        if (errorMessage) {
            showErrorNotification(errorMessage, "top");
            return;
        }
        const { firstName, lastName } = getFirstLastName(name);
        let data = {
            type: "remitx",
            name,
            first_name:firstName,
            last_name:lastName,
            mobile,
            email,
            product,
            purpose,
            remittance_type: remittanceType,
            fx_amount: fxAmount,
            rate,
            currency: currencyText,
            remarks,
        };
        // showSuccessNotification("Sharing...");
        let res = await share(data, "remitx");
        // .then((res) => {
        // console.log(res.responseCode);
        if (res.responseCode == 200) {
            showSuccessNotification("Details has been shared successfully.");
            closeModal();
            return false;
        } else {
            showErrorNotification("Please try again.");
            console.log(res);
        }
    } catch (e) {
        showErrorNotification(e.message);
        return false;
    }
};

export const userShare = async (props, allowEmpty=false) => {
    try {
        const {
            name,
            email,
            mobile,
            product,
            purpose,
            remittanceType,
            currencyText,
            fxAmount,
            leadId,
            areInterested,
            remarks,
        } = props;
        let errorMessage = "";
        if (!leadId)
            throw new Error("Apologies for inconvenience.");
        // console.log("props", JSON.stringify(props));
        if (!name && !mobile && !email && !product && !currencyText)
            errorMessage = messages.FillAllFileds;
        else if(!mobile&&!email)   errorMessage = messages.FillAllFileds;
        else if (!name) errorMessage = messages.EnterName;
        else if (!mobile) errorMessage = messages.ValidMobile;
        else if (!email && !allowEmpty) errorMessage = messages.ValidEmail;
        else if (!product) errorMessage = "Please fill the product.";
        else if (!currencyText) errorMessage = "Please fill the currency.";
        else if (
            commonConstant.PRODUCT["tt"].includes(product) &&
            !remittanceType
        ) {
            errorMessage = "Please fill the remittance type.";
        } else if (
            !commonConstant.PRODUCT["tt"].includes(product) &&
            !purpose
        ) {
            errorMessage = "Please fill the purpose.";
        }
        if (errorMessage) {
            showErrorNotification(errorMessage, "top");
            return;
        }
        const { firstName, lastName } = getFirstLastName(name);
        let data = {
            type: "userinfo",
            leadId,
            name,
            first_name:firstName,
            last_name:lastName,
            mobile,
            email,
            product,
            purpose,
            remittance_type: remittanceType,
            fx_amount: fxAmount,
            areInterested,
            currency: currencyText,
            remarks,
        };
        let res = await share(data, "usershare");
        if (res.responseCode == 200) {
            showSuccessNotification("We've received your request and we will contact you shortly.");
            return false;
        } else {
            if (res?.responseMessage?.toLowerCase()?.includes("details")) {
                showInfoNotification(res.responseMessage);
            } else {
                showErrorNotification("Please try again.");
            }
            return false;
        }
    } catch (e) {
        showErrorNotification(e.message);
        return false;
    }
};

export const shareWithUser = async (props) => {
    const { name, email, mobile, closeModal } = props;
    let fields=[name,email,mobile];
    let missingFields=CountMissingFields(fields);
    try {
        if(missingFields>1)
        throw new Error(messages.FillAllFileds)
        if (!name) throw new Error(messages.EnterName);
        if (!mobile) throw new Error(messages.ValidMobile);
        if (!email) throw new Error(messages.ValidEmail);

        if (!mobileValidate(mobile)) {
            throw new Error(messages.ValidMobile);
        }
        if (!emailValidate(email)) {
            throw new Error(messages.ValidEmail);
        }
        // showSuccessNotification("Sharing...", "");
        const { firstName, lastName } = getFirstLastName(name);
        let res = await share({
            type: "user",
            mobile: mobile,
            email: email,
            first_name:firstName,
            last_name:lastName,
            name,
        });
        // .then((res) => {
        // console.log(res.responseCode);
        if (res.responseCode == 200) {
            showSuccessNotification("Details has been shared with the user.");
            closeModal();
            return false;
        } else {
            throw new Error("Please try again.");
        }
        // })
    } catch (e) {
        showErrorNotification(e.message, "top");
        console.log(e.message);
        // closeModal();
        return false;
    }
};

export const sortTheData = (data, sortBy) => {
    const ascSort = (columnName) => {
        if (data?.length) {
            data.sort((item1, item2) =>
                item1?.[columnName] > item2?.[columnName] ? 1 : -1 // -1 to update the sequence
            );
            // return data;
        }
    };
    const descSort = (columnName) => {
        if (data?.length) {
            data.sort((item1, item2) =>
                item1?.[columnName] > item2?.[columnName] ? -1 : 1  // -1 to update the sequence
            );
            // return data;
        }
    };

    let arrSortBy = sortBy?.split(" "); //0 ->  method, 1 -> columnname
    if (!arrSortBy?.length) return data;
    else if (arrSortBy[0] === "up") {
        ascSort(arrSortBy[1]);
    } else if (arrSortBy[0] === "down") {
        descSort(arrSortBy[1]);
    }
    return data;
};

export const itemPerPage = 10;

export const slicedData = (data, currentPage) =>{
    let idx = (currentPage-1 )* itemPerPage;
    return data?.length ? data?.slice(idx, idx + itemPerPage) : [];
}

export const panValidate = (pan) => pan.match(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/);

export const  getFirstLastName=(fullName)=> {
    const words = fullName.split(' ');
    const firstName = words[0];
    const lastName = words.slice(1).join(' ');
    return { firstName, lastName };
}