import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import {
    setAlpha,
    setEmailValidation,
    setNumeric,
    shareWithRemitx
} from "../../CommonElements/commonFunctions";
import CurrencyDropdown from "../Leads/CurrencyDropdown";
import FxAmountInput from "../Leads/FxAmountInput";
import ProductDropdown from "../Leads/ProductDropdown";
import PurposeDropdown from "../Leads/PurposeDropdown";
import RateField from "../Leads/RateField";
import RemittanceTypeDropdown from "../Leads/RemittanceTypeDropdown";
/**
 * Share button
 * @returns
 */
export default function ShareWithRemitx({ allowEmpty,closeModal }) {
    const [currencyText, setCurrencyText] = useState("");
    const [fxAmount, setFxAmount] = useState("");
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [product, setProduct] = useState("");
    const [purpose, setPurpose] = useState("");
    const [rate, setRate] = useState("");
    const [liveRateInput, setLiveRateInput] = useState("");
    const [error, setError] = useState("");
    const [remarks, setRemarks] = useState("");
    const [remittanceType, setRemittanceType] = useState("");
    const [showloader, setShowLoader] = useState(false);
    
    const buttonClick = async () => {
        let res = await shareWithRemitx({
            currencyText,
            fxAmount,
            name,
            mobile,
            email,
            product,
            purpose,
            rate,
            liveRateInput,
            remarks,
            remittanceType,
            closeModal
        },allowEmpty);
        setShowLoader(res);
    };
    if (!closeModal) return <></>;
    return (
        <>
            <div className="modal-heading">
                <h3>Share Details</h3>
                <button
                    type="button"
                    className="btn-close close"
                    aria-label="Close"
                    onClick={closeModal}
                ></button>
            </div>
            <div className="col-md-12">
                <div className="row" id="share_container_remitx">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="product" className="form-label">
                                Name <span>*</span>
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Enter Name"
                                name="name"
                                onKeyDown={setAlpha}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                                value={name}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="mobile" className="form-label">
                                Mobile Number <span>*</span>
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                maxLength={10}
                                placeholder="Enter Mobile"
                                name="mobile"
                                onKeyDown={setNumeric}
                                onChange={(e) => {
                                    setMobile(e.target.value);
                                }}
                                value={mobile}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">
                                Email {!allowEmpty&&<span>*</span>}
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                onKeyDown={setEmailValidation}
                                placeholder="Enter Email"
                                name="email"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                value={email}
                            />
                        </div>
                    </div>
                    <ProductDropdown onFilterTextChange={setProduct} />
                    <RemittanceTypeDropdown
                        filterText={product}
                        remittanceType={remittanceType}
                        setRemittanceType={setRemittanceType}
                    />
                    <PurposeDropdown
                        filterText={product}
                        setPurpose={setPurpose}
                    />
                    <CurrencyDropdown
                        filterText={product}
                        onCurrencyChange={setCurrencyText}
                    />
                    <RateField
                        setLiveRateInput={setLiveRateInput}
                        setRate={setRate}
                        setError={setError}
                        error={error}
                        rate={rate}
                        currencyText={currencyText}
                    />
                    <div className="col-md-6">
                        <FxAmountInput
                            allowedEmpty={allowEmpty}
                            fxAmount={fxAmount}
                            onFxAmountChange={setFxAmount}
                        />
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="remarks" className="form-label">
                                Remarks
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Enter Remarks"
                                name="remarks"
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mrg-t10">
                    {!showloader && (
                        <button
                            type="button"
                            className="btn btn-primary mrg-r20"
                            onClick={() => {
                                buttonClick();
                                setShowLoader(true);
                            }}
                        >
                            Share
                        </button>
                    )}
                    {showloader && (
                        <button
                            type="button"
                            className="btn btn-primary mrg-r20"
                            disabled
                        >
                            <div className="text-center">
                                <Spinner animation="border" variant="light" />
                            </div>
                        </button>
                    )}
                    <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={closeModal}
                    >
                        Close
                    </button>
                </div>
            </div>
        </>
    );
}
