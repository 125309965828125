import axios from "axios";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Navbar from 'react-bootstrap/Navbar';
import { useParams } from "react-router-dom";
import Footer from "../../CommonElements/Footer";
import {
    setAlpha,
    setEmailValidation,
    setNumeric,
    showErrorNotification,
    userShare
} from "../../CommonElements/commonFunctions";
import { commonConstant } from "../../CommonElements/constants";
import AreYouInterested from "../../Components/Leads/AreyouInteresed";
import CurrencyDropdown from "../../Components/Leads/CurrencyDropdown";
import FxAmountInput from "../../Components/Leads/FxAmountInput";
import ProductDropdown from "../../Components/Leads/ProductDropdown";
import PurposeDropdown from "../../Components/Leads/PurposeDropdown";
import RemittanceTypeDropdown from "../../Components/Leads/RemittanceTypeDropdown";
/**
 * Share button
 * @returns
 */
export default function UserShare({ allowEmpty }) {
    const [currencyText, setCurrencyText] = useState("");
    const [fxAmount, setFxAmount] = useState("");
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [product, setProduct] = useState("");
    const [agentLogo,setAgentLogo]=useState("")
    let { id } = useParams();
    // State to manage the selected options
    const [areInterested, setAreInterested] = useState({
        travelservices: false,
        educationloan: false,
    });
    const [purpose, setPurpose] = useState("");
    const [remarks, setRemarks] = useState("");
    const [remittanceType, setRemittanceType] = useState("");
    const [showloader, setShowLoader] = useState(false);

    const getData = async () => {
        try {
            let url = `${commonConstant.BACKEND_API_URL}partners/share/userData?leadId=${id}`;
            let dataConfig = {
                method: 'GET'
            };
            let response = await axios.get(url, dataConfig); // Replace with your API endpoint
            if (response.data.responseCode !== 200) 
                throw new Error("Please refresh and retry.");
            response = response.data.responseData;
            setName(response.name);
            setMobile(response.mobile);
            setEmail(response.email);
            setProduct(response.product);
            setCurrencyText(response.currency);
            setAgentLogo(response.logo_url)
            setFxAmount(response.fx_amount);
            setAreInterested(response?.are_interested || {
                travelservices: false,
                educationloan: false,
            });
            setRemarks(response.remarks);
        } catch (e) {
            showErrorNotification("Id Invalid.");
        }
    };
    const buttonClick = async () => {
        await userShare({
            name,
            email,
            mobile,
            product,
            purpose,
            remittanceType,
            currencyText,
            fxAmount,
            leadId: id,
            areInterested,
            remarks
        }, allowEmpty);
        setShowLoader(false);
    };
    useEffect(() => {
        getData();
    }, []);
    return (
        <>
        <Navbar expand="lg" className="bg-body-tertiary">
      <div className="container column">
        <div className="col-md-12">
        <div className="row align-middle" xs={2}>
            { agentLogo&& <div className="col"><Navbar.Brand href="/"><img className="client_logo" src={agentLogo}  /></Navbar.Brand></div>}
           { !agentLogo&& <div className="col text-right"><Navbar.Brand href="https:www.remitx.com/"><img src="https://www.remitx.com/remitx-images/remitx.svg"/></Navbar.Brand></div>}
        </div>
        </div>
      </div>
    </Navbar>
            <div className="container">
                <div className="col-12 boxmain_user">
                    <div className="row">
                        <div className="col-md-12 heading__user">
                            <h1>Hello!</h1>
                            <p>Complete the form for personlized forex solutions and ignite your journey to success!</p>
                        </div>

                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="product" className="form-label">
                                    Name <span>*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Name"
                                    name="name"
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                    onKeyDown={setAlpha}
                                    value={name}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="mobile" className="form-label">
                                    Mobile Number <span>*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Mobile"
                                    maxLength={10}
                                    name="mobile"
                                    onChange={(e) => {
                                        setMobile(e.target.value);
                                    }}
                                    onKeyDown={setNumeric}
                                    value={mobile}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">
                                    Email {!allowEmpty&&<span>*</span>}
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    onKeyDown={setEmailValidation}
                                    placeholder="Enter Email"
                                    name="email"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    value={email}
                                />
                            </div>
                        </div>
                        <ProductDropdown onFilterTextChange={setProduct} />
                        <RemittanceTypeDropdown
                            filterText={product}
                            remittanceType={remittanceType}
                            setRemittanceType={setRemittanceType}
                        />
                        <PurposeDropdown filterText={product} setPurpose={setPurpose} />
                        <CurrencyDropdown
                            filterText={product}
                            onCurrencyChange={setCurrencyText}
                        />
                        <div className="col-md-6">
                            <FxAmountInput
                                allowedEmpty={allowEmpty}
                                fxAmount={fxAmount}
                                onFxAmountChange={setFxAmount}
                            />
                        </div>
                        <div className="col-md-6">
                            <AreYouInterested
                                checkedOptions={areInterested}
                                setCheckedOptions={setAreInterested}
                            />
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label htmlFor="remarks" className="form-label">
                                    Remarks
                                </label>
                                <textarea
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Remarks"
                                    name="remarks"
                                    value={remarks}
                                    onChange={(e) => setRemarks(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-12 mrg-t10">
                            {!showloader && (
                                <button
                                    type="button"
                                    className="btn btn-primary mrg-r20"
                                    onClick={() => {
                                        buttonClick();
                                        setShowLoader(true);
                                    }}
                                >
                                    Submit
                                </button>
                            )}
                            {showloader && (
                                <button
                                    type="button"
                                    className="btn btn-primary mrg-r20"
                                    disabled
                                >
                                    <div className="text-center">
                                        <Spinner animation="border" variant="light" />
                                    </div>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
