export const Pagination = ({ totalPages, currentPage, setCurrentPage }) => {
    // console.log(totalPages);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const handlePageClick = (newPage) => {
        setCurrentPage(newPage);
    };
    return (
        <div className="pagination mb-3">
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                Previous
            </button>
            {Array.from({ length: 3 }, (_, index) => (
                <>
                    {currentPage + index-1 > 0 &&
                        currentPage + index-1 <= totalPages && (
                            <button
                                key={currentPage + index-1}
                                onClick={() =>
                                    handlePageClick(currentPage + index-1)
                                }
                                disabled={
                                    currentPage === currentPage + index-1
                                }
                            >
                                {currentPage + index-1}
                            </button>
                        )}
                </>
            ))}
            <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
            >
                Next
            </button>
        </div>
    );
};
