import { useEffect, useState } from "react";
import { getFormattedDate } from "../CommonElements/commonFunctions";
import { getLeadListing } from "../services/Service";

const searchColumns = [
    "product",
    "first_name",
    "last_name",
    "email",
    "mobile",
    "status"
];

export default function usePortalLeads(
    dateRange,
    filterQuery = "",
    triggerButton,
) {
    const [data, setData] = useState([]); //created for filtering of the data
    const [listingData, setListingData] = useState(null);
    const [prevDateRange, setPrevDateRange] = useState("");
    // console.log("render 0")
    useEffect(() => {
        // console.log("clicked", dateRange, filterQuery);
        let list = [];
        async function call() {
            if ((!dateRange && prevDateRange) || (dateRange && !prevDateRange) || (dateRange?.[0] !== prevDateRange?.[0]) || (dateRange?.[1] !== prevDateRange?.[1]) || !listingData) {
                let startDate = null;
                let endDate = null;
                if (dateRange?.[0]) {
                    startDate = getFormattedDate(dateRange[0]);
                }
                if (dateRange?.[1]) {
                    endDate = getFormattedDate(dateRange[1]);
                }
                let requestData = {
                    type: "1",
                    startDate: startDate,
                    endDate: endDate,
                };
                document.getElementById("middleLoader").classList.remove("hide");
                let reports = await getLeadListing(requestData);
                document.getElementById("middleLoader").classList.add("hide");
                setPrevDateRange(dateRange);
                setListingData(reports.responseData);
                // setData(reports.responseData);
                list = reports.responseData;
            }
            if (!list?.length && listingData?.length) {
                list = listingData;
            }
            if (filterQuery) {
                let filteredData = list?.filter((item) => {
                    let updatedVal = searchColumns
                        .map((cols) => {
                            return item?.[cols]?.toLowerCase();
                        })
                        .join(" ");
                    return updatedVal?.includes(filterQuery?.toLowerCase());
                });
                list = filteredData;
            }
            // console.log(list);
            setData(list);
        }
        // console.log("render 1");
        call();
    }, [triggerButton]);
    // console.log(currentPage, data);
    
    return [data];
}
