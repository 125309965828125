import React, { useRef, useState, useEffect } from 'react';
import jsPDF from "jspdf";
//import ReportTemplate from "../ReportTemplate"
import GstInvoicing from "../GstInvoicing"
import ReactDOMServer from "react-dom/server";

import { commonConstant } from '../../CommonElements/constants';
import { getFormattedDate, getItemValue, sortByOrderDate } from '../../CommonElements/commonFunctions';

import { DateRangePicker } from 'rsuite';
import { getAgentPayoutDetails } from '../../services/Service';
import './Payouts.scss';
import moment from 'moment';


function Payouts() {
  const [dateRange,setDateRange] = useState('');
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [revenueClaimed, setRevenueClaimed] = useState('');
  const reportTemplateRef = useRef(null);

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      format: "a4",
      unit: "px"
    });
    // Adding the fonts
    doc.setFont("Inter-Regular", "normal");
    //const htmlContent =  ReactDOMServer.renderToString(<ReportTemplate />);
    const htmlContent = ReactDOMServer.renderToString(<GstInvoicing />);
    doc.html(htmlContent, {
      async callback(doc) {
        doc.save("document");
      }
    });
  };

  const getAgentPaymentDetails = () => {
    let data = {};
    if (dateRange) {
      data.FromDate = getFormattedDate(dateRange[0]);
      data.TillDate = getFormattedDate(dateRange[1]);
    }
    if (!!document.getElementById('ClaimNo').value) {
      data.ClaimNo = document.getElementById('ClaimNo').value;
    }
    getAgentPayoutDetails(data).then((res) => {
      if (res.responseCode == 200) {
        if (!!res.responseData.earningStats.RevenueToBeClaimed) {
          setRevenueClaimed(res.responseData.earningStats.RevenueToBeClaimed);
        }
        if (!!res.responseData.paymentDetails[0] && !!res.responseData.paymentDetails[0].ClaimNo) {
          setPaymentDetails(sortByOrderDate(res.responseData.paymentDetails, 0));
        }else{
          console.log("asdasdasd");
          setPaymentDetails([]);
        }
      } else {
        setPaymentDetails([]);
      }
    }).catch((error) => {
      console.log(error);
    });
  }
  const handleChange = (e) => {
    setDateRange(e);
  }
  const Search = () => {
    console.log("Search");
    getAgentPaymentDetails();
  }
  useEffect(()=> {getAgentPaymentDetails()}, []);
  return (
    <>
    <div className="loadenv hide" id="middleLoader">
    <div className="loader text-center">
      <img src={commonConstant.REMITX_URL + "remitx-images/loader.gif"} />
    </div>
  </div>
      <div className="page-body-fx container mrg-t30">
        <div className='row'>
          <div className='col-md-12' id='payouts'>
            <h1>Payouts</h1>
            <div className='row'>
              <div className='col-md-4'>
              <div className='payoutamount'>
              {revenueClaimed ? ('INR '+ revenueClaimed) : (<div className='no__transaction'><p className='no__tran'>No transactions found.</p><p> Keep trying, success is just around the corner!</p></div>)}
            </div>
              </div>
              <div className='col-md-3'>
                <button className='btn btn-outline-primary hide' onClick={handleGeneratePdf}>Download Invoice</button>
              </div>
            </div>
            <h3>Search By</h3>
            <div className='row'>
              <div className='col-md-2'>
                <div className="mb-3">
                  <label className="form-label">Date</label>
                  <DateRangePicker format="yyyy-MM-dd" onChange = {handleChange}/>
                </div>
              </div>
              <div className='col-md-2'>
                <div className="mb-3">
                  <label htmlFor='' className="form-label">Claim No.</label>
                  <input
                    className='form-control'
                    type="text" id='ClaimNo'
                    placeholder='Please enter'
                  />
                </div>
              </div>
              <div className='col-md-2 hide'>
                <div className="mb-3">
                  <label htmlFor='' className="form-label">Statue</label>
                  <select
                    className='form-select'
                    type="text"
                    placeholder='Please enter'
                  >
                    <option>Please select</option>
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <button type="button" className="btn btn-primary mrg-t30" onClick={Search}>Search</button>
              </div>
            </div>
          </div>
          <div className='col-md-12 mrg-t30'>
            <h3>Payout Listing</h3>
            <div className="mb-3 table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th width="10%">#</th>
                    <th scope="col" width="20%">Date</th>
                    <th width="20%">Claim No</th>
                    <th scope="col" width="20%">Amount</th>
                    {/* <th scope="col" width="20%">Invoice</th> */}
                    {/* <th scope="col" width="20%">Status</th> */}
                  </tr>
                </thead>
                <tbody>
                {paymentDetails.length > 0 ? paymentDetails.map((item, i)=>(
                <tr key={item.ClaimNo}>
                  <td>{i+1}</td>
                 <td>
                  {moment(item.Date).format("DD MMM YYYY")}
                 </td>
                <td><a href='javasrcript:void(0);'>#{item.ClaimNo}</a></td>
                <td>{item.Amount}</td>
                {/* <td><button className='btn-sm btn-outline-primary'>Download Invoice</button></td> */}
                {/* <td><div className='inprogress'>In Progress</div></td> */}
              </tr>
               )):<tr id='noRecordFound'><td colSpan='7'>No Listing available</td></tr>}
                </tbody>
              </table>
            </div>
          </div>
          <div className='col-md-12 mb-3 hide'>
            <h3 >Upload Signed Copy </h3>
            <div className='row'>
              <div className='col-md-4'>
                <div className="mb-3">
                  <label htmlFor='' className="form-label">Upload Document<span>*</span></label>
                  <input className="form-control" type="file" placeholder='Enter name' />
                </div>
              </div>
              <div className="col-md-3">
                <button type="button" className="btn btn-primary mrg-t30">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const InvoiceHtml = () => {
  return (<div>My cool content here!</div>);
}

export default Payouts;
