import axios from "axios";
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { DateRangePicker } from "rsuite";
import {
    getFormattedDate,
    getItemValue,
    headers,
    setNumeric,
    sortTheData,
} from "../../CommonElements/commonFunctions";
import { commonConstant } from "../../CommonElements/constants";
import SortArrow from "../../Components/SortArrow/SortArrow";
import { Website } from "./Website";

function OrderReport({ agentType, allowedModules }) {
    const [dateRange, setDateRange] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentData, setCurrentData] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [triggerButton, setTriggerButton] = useState(false);
    const [orderNo, setOrderNo] = useState("");
    const [orders, setOrders] = useState([]);
    const [sortData, setSortData] = useState("");
    const handleChange = (e) => {
        setDateRange(e);
    };
    const handleTriggerButton = () => {
        setTriggerButton((item) => !item);
    };

    

    const itemsPerPage = 10;

    const getPageItems = (data, page) => {
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data.slice(startIndex, endIndex);
    };
    const handlePreviousPage = () => {
        setCurrentData(getPageItems(orders, currentPage - 1));
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleNextPage = () => {
        setCurrentData(getPageItems(orders, currentPage + 1));
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
        setCurrentData(getPageItems(orders, page));
    };
    const getOrders = () => {
        document.getElementById("middleLoader").classList.remove("hide");
        let start_date = null;
        let end_date = null;
        if (dateRange) {
            start_date = getFormattedDate(dateRange[0]);
            end_date = getFormattedDate(dateRange[1]);
        }
        let data = { startDate: start_date, endDate: end_date };
        if (!!document.getElementById("orderNo").value) {
            data.orderNo = orderNo
                ?.replace("#000", "")
                ?.replace("#", "")
                ?.replace("000", "");
        }
        if (searchKeyword) data.searchKeyword = searchKeyword;
        try {
            const config = {
                headers: headers(),
                params: data,
            };
            // console.log(config);
            axios
                .get(
                    commonConstant.BACKEND_API_URL + "partners/order/get",
                    config
                )
                .then((response) => {
                    document
                        .getElementById("middleLoader")
                        .classList.add("hide");
                    if (response.status === 200) {
                        if (!!response.data.responseData) {
                            setOrders(response.data.responseData);
                            const totalItems =
                                response.data.responseData.length;
                            setTotalPages(Math.ceil(totalItems / itemsPerPage));
                            setCurrentData(
                                getPageItems(
                                    response.data.responseData,
                                    currentPage
                                )
                            );
                        } else {
                            setOrders([]);
                        }
                    }
                });
        } catch (e) {
            document.getElementById("middleLoader").classList.add("hide");
            console.log(e);
        }
    };
    useEffect(() => {
        getOrders();
    }, [triggerButton]);
    let dataToRender = sortTheData(currentData, sortData);
    return (
        <>
            <div className="loadenv hide" id="middleLoader">
                <div className="loader text-center">
                    <img
                        src={
                            commonConstant.REMITX_URL +
                            "remitx-images/loader.gif"
                        }
                    />
                </div>
            </div>
            <div className="page-body-fx container mrg-t30">
                <div className="row">
                    <div className="col-md-12">
                        <h3>Search By</h3>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="mb-3">
                                    <label
                                        htmlFor="product"
                                        className="form-label"
                                    >
                                        Date
                                    </label>
                                    <DateRangePicker
                                        placeholder={"Select a date range"}
                                        format="yyyy-MM-dd"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="mb-3">
                                    <label
                                        htmlFor="product"
                                        className="form-label"
                                    >
                                        Order No.
                                    </label>
                                    <input
                                        id="orderNo"
                                        className="form-control"
                                        type="text"
                                        placeholder="Please enter"
                                        onKeyDown={setNumeric}
                                        value={orderNo}
                                        onChange={(e) =>
                                            setOrderNo(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="mb-3">
                                    <label
                                        htmlFor="product"
                                        className="form-label"
                                    >
                                        Order Info
                                    </label>
                                    <input
                                        id="searchKeyword"
                                        className="form-control"
                                        type="text"
                                        placeholder="Please enter"
                                        onChange={(e) => {
                                            setSearchKeyword(e.target.value);
                                        }}
                                        value={searchKeyword}
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <button
                                    type="button"
                                    className="btn btn-primary mrg-t30"
                                    onClick={handleTriggerButton}
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mrg-t30">
                        <h3>Order Listing</h3>
                      
                    
                        {allowedModules?.b2corders && (
                            <Tabs
                                defaultActiveKey="b2c"
                                id="uncontrolled-tab-example"
                                className=""
                            >
                                <Tab eventKey="b2c" title="B2C">
                                    <div className="mb-3 table-responsive">
                                        <Website
                                            dateRange={dateRange}
                                            searchKeyword={searchKeyword}
                                            orderNo={orderNo}
                                            triggerButton={triggerButton}
                                        />
                                    </div>
                                </Tab>
                                <Tab eventKey="b2b" title="B2B">
                                    <div className="mb-3 table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                   
                                                    <th scope="col" width="10%">
                                                        Date 
                                                        <SortArrow
                                                            sortData={sortData}
                                                            columnName="created"
                                                            setSortData={
                                                                setSortData
                                                            }
                                                        />
                                                    </th>
                                                    <th width="10%">Order No</th>
                                                    <th width="10%" scope="col">
                                                        Order Info 
                                                    </th>
                                                    <th width="10%" scope="col">
                                                        Product and Purpose{" "}
                                                    </th>
                                                    <th width="10%" scope="col">
                                                        FX Amount
                                                    </th>
                                                    <th width="10%" scope="col">
                                                        Quotation Rate
                                                    </th>
                                                    {/* <th scope="col">RemitX Rate</th> */}
                                                    <th width="10%" scope="col">
                                                        Currency
                                                    </th>
                                                    <th width="10%" scope="col">
                                                        Total Revenue
                                                    </th>
                                                    {agentType === "agent" && (
                                                        <th width="10%" scope="col">
                                                            Created By
                                                        </th>
                                                    )}
                                                    <th width="10%" scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* {JSON.stringify(orders)} */}
                                                {dataToRender.length > 0 ? (
                                                    dataToRender.map(
                                                        (item, i) => (
                                                            <tr key={i}>
                                                                <td>
                                                                    {
                                                                        item.created.split(
                                                                            "T"
                                                                        )[0]
                                                                    }
                                                                </td>
                                                                <td>
                                                                    #000
                                                                    {item.id}
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        {
                                                                            item.first_name
                                                                        }{" "}
                                                                        {
                                                                            item.last_name
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            item.email
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            item.mobile
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {getItemValue(
                                                                        item.product
                                                                    )}{" "}
                                                                    {getItemValue(
                                                                        item.purpose
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.fx_amount
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {item.rate}
                                                                </td>
                                                                {/* <td>{item.remitx_rate}</td> */}
                                                                <td>
                                                                    {
                                                                        item.currency
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.commission
                                                                    }
                                                                </td>
                                                                {agentType ===
                                                                    "agent" && (
                                                                    <td>
                                                                        {
                                                                            item.code
                                                                        }{" "}
                                                                        -{" "}
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </td>
                                                                )}
                                                                <td>
                                                                    <div className="inprogress">
                                                                        {item.status ||
                                                                            "In Progress"}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                ) : (
                                                    <tr id="noRecordFound">
                                                        <td colSpan="10">
                                                            No Listing available
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        {/* Render pagination controls here */}
                                        <div className="pagination">
                                            <button
                                                onClick={handlePreviousPage}
                                                disabled={currentPage === 1}
                                            >
                                                Previous
                                            </button>
                                            {Array.from(
                                                { length: 3 },
                                                (_, index) => (
                                                    <>
                                                        {currentPage +
                                                            index -
                                                            1 >
                                                            0 &&
                                                            currentPage +
                                                                index -
                                                                1 <=
                                                                totalPages && (
                                                                <button
                                                                    key={
                                                                        currentPage +
                                                                        index -
                                                                        1
                                                                    }
                                                                    onClick={() =>
                                                                        handlePageClick(
                                                                            currentPage +
                                                                                index -
                                                                                1
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        currentPage ===
                                                                        currentPage +
                                                                            index -
                                                                            1
                                                                    }
                                                                >
                                                                    {currentPage +
                                                                        index -
                                                                        1}
                                                                </button>
                                                            )}
                                                    </>
                                                )
                                            )}
                                            <button
                                                onClick={handleNextPage}
                                                disabled={
                                                    currentPage === totalPages
                                                }
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        )}
                        {!allowedModules?.b2corders && (
                            <div id="OrderList" className="mb-3 table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th width="10%" scope="col">
                                                Date
                                                <SortArrow
                                                    sortData={sortData}
                                                    columnName="created"
                                                    setSortData={setSortData}
                                                />
                                            </th>
                                            <th width="7%">Order No</th>
                                            <th width="10%" scope="col">Order Info </th>
                                            <th width="12%" scope="col">
                                                Product & Purpose{" "}
                                            </th>
                                            <th width="8%" scope="col">FX Amount</th>
                                            <th width="10%" scope="col">Quotation Rate</th>
                                            {/* <th scope="col">RemitX Rate</th> */}
                                            <th width="6%" scope="col">Currency</th>
                                            <th width="10%" scope="col">Total Revenue</th>
                                            {agentType === "agent" && (
                                                <th width="10%" scope="col">Created By</th>
                                            )}
                                            <th width="12%" scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {JSON.stringify(orders)} */}
                                        {dataToRender.length > 0 ? (
                                            dataToRender.map((item, i) => (
                                                <tr key={i}>
                                                    <td>
                                                        {
                                                            item.created.split(
                                                                "T"
                                                            )[0]
                                                        }
                                                    </td>
                                                    <td>
                                                        #000
                                                        {item.id}
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {item.first_name}{" "}
                                                            {item.last_name}
                                                        </div>
                                                        <div>{item.email}</div>
                                                        <div>{item.mobile}</div>
                                                    </td>
                                                    <td>
                                                        {getItemValue(
                                                            item.product
                                                        )}{" "}
                                                        {getItemValue(
                                                            item.purpose
                                                        )}
                                                    </td>
                                                    <td>{item.fx_amount}</td>
                                                    <td>{item.rate}</td>
                                                    {/* <td>{item.remitx_rate}</td> */}
                                                    <td>{item.currency}</td>
                                                    <td>{item.commission||"0"}</td>
                                                    {agentType === "agent" && (
                                                        <td>
                                                            {item.code} -{" "}
                                                            {item.name}
                                                        </td>
                                                    )}
                                                    <td>
                                                        <div className="inprogress">
                                                            {item.status ||
                                                                "In Progress"}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr id="noRecordFound">
                                                <td colSpan="10">
                                                    No Listing available
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                {/* Render pagination controls here */}
                                <div className="pagination">
                                    <button
                                        onClick={handlePreviousPage}
                                        disabled={currentPage === 1}
                                    >
                                        Previous
                                    </button>
                                    {Array.from({ length: 3 }, (_, index) => (
                                        <>
                                            {currentPage + index - 1 > 0 &&
                                                currentPage + index - 1 <=
                                                    totalPages && (
                                                    <button
                                                        key={
                                                            currentPage +
                                                            index -
                                                            1
                                                        }
                                                        onClick={() =>
                                                            handlePageClick(
                                                                currentPage +
                                                                    index -
                                                                    1
                                                            )
                                                        }
                                                        disabled={
                                                            currentPage ===
                                                            currentPage +
                                                                index -
                                                                1
                                                        }
                                                    >
                                                        {currentPage +
                                                            index -
                                                            1}
                                                    </button>
                                                )}
                                        </>
                                    ))}
                                    <button
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default OrderReport;
