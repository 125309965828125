import React, { useEffect, useState } from 'react';
import { FiRefreshCw } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import Modal from "react-modal";
import { CurrencyAmountBox, createQuotationsTableRow, emailValidate, getRadioSelectedValue, headers, setAlpha, setAlphaNumeric, setEmailValidation, setNumeric, showErrorNotification, showInfoNotification, showSuccessNotification } from '../CommonElements/commonFunctions';
import { commonConstant } from '../CommonElements/constants';
import { getBranchData, getConfirmedQuotations, refreshQuotationsStatus, sendQuote, verifyOrder } from '../services/Service';
import './../css/CreateLead.scss';


import { useNavigate, useParams } from 'react-router-dom';
import AddToCartButton from '../Components/Leads/AddToCartButton';
import CountryDropdown from '../Components/Leads/CountryDropdown';
import CurrencyDropdown from '../Components/Leads/CurrencyDropdown';
import FxAmountInput from '../Components/Leads/FxAmountInput';
import NestroChargesPayer from '../Components/Leads/NestroChargesPayer';
import ProductDropdown from '../Components/Leads/ProductDropdown';
import PurposeDropdown from '../Components/Leads/PurposeDropdown';
import RateField from '../Components/Leads/RateField';
import RemittanceTypeDropdown from '../Components/Leads/RemittanceTypeDropdown';
import SourceOfFund from '../Components/Leads/SourceOfFund';
import messages from '../CommonElements/messages';

Modal.setAppElement('#root');
let itemLeadInfoId = null;
const panRegex =/^[A-Za-z]{3}P[A-Za-z]{1}[0-9]{4}[A-Za-z]$/;


function CreateLead({ commission }) {
  let navigate=useNavigate();
  let urlParams = useParams();
  console.log("calling create lead function");

  const [spinner, setSpinner] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [currencyText, setCurrrenyText] = useState('');
  const [country, setCountry] = useState('');
  const [liveRate, setLiveRate] = useState('');
  const [rateInput, setLiveRateInput] = useState('');
  const [fxAmount, setFxAmount] = useState('');
  const [amountBox, setAmountBox] = useState('');
  const [nestroChargesPayerOption, setNestroChargesPayerOption] = useState(false);
  const [sourceOfFundOption, setSourceOfFundOption] = useState(false);
  const [tempLeadHtml, setTempLeadHtml] = useState('');
  const [sendQuoteButton, setSendQuoteButton] = useState('');
  const [leadDetailId, setLeadDetailId] = useState('');
  const [quotationsHtml, setQuotationsHtml] = useState('');
  const [remittanceType, setRemittanceType] = useState('');
  const [rate, setRate] = useState('');
  const [purpose, setPurpose] = useState('');
  const [refreshIcon, setRefreshIcon] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPlaceOrderModalOpen, setIsPlaceOrderModalOpen] = useState(false);
  const [remittanceRate, setRemittanceRate] = useState([]);

  const [quotationId, setQuotationId] = useState(false);

  const [studentData, setStudentData] = useState({ first_name: '', last_name: '', full_name: '', email: '', mobile: '' });
  const [parentData, setParentData] = useState({ first_name: '', last_name: '', full_name: '', email: '', mobile: '' });
  const [payerData, setPayerData] = useState({ pan_number: '', preferred_bank: '' });

  const [error, setError] = useState(false);

  const [branchData, setBranchData] = useState([]);

  const openQuotationModal = () => {
    getQuotations();
    setIsModalOpen(true);
  }

  const handleBeforeUnload = (event) => {
    let radios = document.getElementsByName('cart_quotation');
    let addListner = false;
    if (radios.length) addListner = true;
    if (addListner) {
      let details = JSON.parse(
        getRadioSelectedValue(document.getElementsByName('confirmed_quotation'))
      );
      if (details) {
        let id = details.quotaion_info_id;
        let statusValue = document.querySelector(`.quotation-${id} .quotation-state`).innerHTML;
        if (statusValue === "Quotation Approved") {
          addListner = false;
        }
      }
    }
    if (addListner) {
      event.preventDefault(); // Cancel the event to prevent the browser refresh
      event.returnValue = ''; // Required for Chrome
    }
  };
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const checkPlaceOrder = () => {
    console.log(getRadioSelectedValue(document.getElementsByName('confirmed_quotation')));
    verifyOrder(JSON.parse(
      getRadioSelectedValue(document.getElementsByName('confirmed_quotation'))
    )).then((res) => {
      getQuotations();
      console.log(res);
      if (res.responseCode == 200) {
        setIsPlaceOrderModalOpen(true);
      }
      else {
        let extraMessage = (res.responseMessage == 'Quotation Rejected') ? ' Please resend it for approval.' : ' Kindly reach out to the student for their approval.';
        if(res.responseMessage==='Order Placed')
        {
          showInfoNotification("We have received payment from the customer, and the order has been successfully placed.")
          navigate("/OrderReport")
        }
        else
          showInfoNotification("Quotation is in " + res.responseMessage.replace("Quotation","") + " state." + extraMessage);
      }
    })
  }

  function resetButton() {
    setFilterText('');
    setCurrrenyText('');
    document.getElementById("currencyId").value = '';
    document.getElementById("productId").value = '';
    document.getElementById("country").value = '';
    setLiveRate('');
    setLiveRateInput('');
    setFxAmount('');
    setNestroChargesPayerOption(false);
    setSourceOfFundOption(false);
  }

  const sendQuoteClick = (e) => {

    const s_full_name = document.querySelector('#student_details input[name="full_name"]').value;
    const s_f_name = s_full_name.split(" ")[0] || " ";
    const s_l_name = s_full_name.split(" ").slice(1).join(" ") || " "
    const s_mob_no = document.querySelector('#student_details input[name="mobile_number"]').value;
    const s_email = document.querySelector('#student_details input[name="email"]').value;
    const p_full_name = document.querySelector('#parent_details input[name="full_name"]').value;
    const p_f_name = p_full_name.split(" ")[0] ||" ";

    const p_l_name = p_full_name.split(" ").slice(1).join(" ") || " "
    const p_mob_no = document.querySelector('#parent_details input[name="mobile_number"]').value;
    const p_email = document.querySelector('#parent_details input[name="email"]').value;

    const pan_number = document.querySelector('#payer_details input[name="pan_number"]').value;

    const preferred_bank = document.querySelector('#payer_details select[name="preferred_bank"]').value;
    if (!s_mob_no || !s_email || !s_full_name) {
      showErrorNotification('Please fill the student details.');
      return
    }
    if (!p_mob_no || !p_email || !p_full_name) {
      showErrorNotification('Please fill the parent details.');
      return
    }


    if(!pan_number&&!preferred_bank){
      showErrorNotification("Please fill payer's details")
      return;
    }

    if(!pan_number){
      showErrorNotification('Please fill PAN number');
      return
    }

    if( !panRegex.test(pan_number))
    {
      showErrorNotification(messages.ValidPan)
      return;
    }
    if (!emailValidate(s_email)) return showErrorNotification("Student email validation failed.");
    if (!emailValidate(p_email)) return showErrorNotification("Parent email validation failed.");

    if (!preferred_bank) {
      showErrorNotification('Please select preferred bank');
      return;
    }

    // console.log("lead info 1 ", { s_name: s_full_name, mobile: s_mob_no, email: s_email });
    setStudentData({ first_name: s_f_name, last_name: s_l_name, full_name: s_full_name, mobile: s_mob_no, email: s_email });
    setParentData({ first_name: p_f_name, last_name: p_l_name, full_name: p_full_name, mobile: p_mob_no, email: p_email });
    setPayerData({ pan_number: pan_number, preferred_bank: preferred_bank })
    let itemSelectedValue = getRadioSelectedValue(document.getElementsByName('cart_quotation'));
    console.log(itemSelectedValue);
    sendQuote(
      {
        temp_quot_detail_id: (itemSelectedValue), lead_info_id: urlParams.id || itemLeadInfoId, first_name: s_f_name, last_name: s_l_name, mobile: s_mob_no, email: s_email, parent_first_name: p_f_name, parent_last_name: p_l_name, parent_mobile: p_mob_no, parent_email: p_email, payer_pan_number: pan_number, payer_preferred_bank: preferred_bank
      })
      .then((res) => {
        //console.log("sendQuote ", res);
        if (res.responseCode == 200) {
          showSuccessNotification("Quotation has been successfully sent.");
          console.log(res.responseData, "------->>>>>");
          itemLeadInfoId = res.responseData.lead_info_id;
          let rootElementQuotaionList = document.getElementById('rootElementQuotaionList');
          let z = createQuotationsTableRow(res.responseData[0], itemLeadInfoId);
          rootElementQuotaionList.appendChild(z);
          document.getElementById("quotation_container").classList.remove("hide");
          setIsModalOpen(false);
        } else {
          showErrorNotification("Problem while update the info.");
        }
      })
  }
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const closePlaceOrderModal = () => {
    setIsPlaceOrderModalOpen(false);
  }

  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const changeDocumentHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const createPlaceOrder = () => {
    if (!document.getElementById('branch_id').value) {
      showErrorNotification("Please select the RemitX Branch.");
      return;
    }

    if(!document.getElementById('payment_mode').value){
      showErrorNotification(messages.SelectPaymentMode);
      return;
    }
    if(!document.getElementById('payment_ref_num').value){
      showErrorNotification(messages.EnterPaymentRefNum);
      return;
    }
    if (!document.querySelector('#PlaceOrderModal input[name="first_name"]').value && !document.querySelector('#PlaceOrderModal input[name="last_name"]').value && !document.querySelector('#PlaceOrderModal input[name="email"]').value || !document.querySelector('#PlaceOrderModal input[name="mobile"]').value) {
      showErrorNotification("Please fill the student details.");
      return;
    }

    let details = JSON.parse(
      getRadioSelectedValue(document.getElementsByName('confirmed_quotation'))
    );
    const formData = new FormData();
    formData.append('lead_info_id', urlParams.id || details.lead_info_id);
    formData.append('quotaion_info_id', details.quotaion_info_id);
    formData.append('document', selectedFile);
    formData.append('first_name', document.querySelector('#PlaceOrderModal input[name="first_name"]').value);
    formData.append('last_name', document.querySelector('#PlaceOrderModal input[name="last_name"]').value);
    formData.append('mobile', document.querySelector('#PlaceOrderModal input[name="mobile"]').value);
    formData.append('email', document.querySelector('#PlaceOrderModal input[name="email"]').value);
    formData.append('branch_id', document.getElementById('branch_id').value);
    formData.append('document_type', document.querySelector('#PlaceOrderModal select[name="document_type"]').value);
    formData.append('payment_mode',document.getElementById('payment_mode').value)
    formData.append('payment_ref_num',document.getElementById('payment_ref_num').value)

    fetch(
      commonConstant.BACKEND_API_URL + 'partners/order/create',
      {
        method: 'POST',
        body: formData,
        headers: { 'x-jwt': headers()['x-jwt'] }
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result);
        if (result.responseCode == 200) {
          setTimeout(() => { window.location.href = "/OrderReport"; }, 2000);
          showSuccessNotification("Order has been successfully created.");
        } else {
          showErrorNotification("Quotation approval is pending. Ask the student to approve.");
        }
      })
      .catch((error) => {
        showErrorNotification("Problem while creating the order.");
        console.error('Error:', error);
      });
  };

  const getQuotations = async () => {
    let radios = document.getElementsByName('confirmed_quotation');
    let ids = [];
    for (let i = 0; i < radios.length; i++) {
      let value = JSON.parse(radios[i].value).quotaion_info_id;
      ids.push(value);
    }
    setRefreshIcon(false);
    const getData = await refreshQuotationsStatus(ids);
    for (let i = 0; i < getData.responseData.length; i++) {
      const { id, status } = getData.responseData[i];
      // console.log(`quotation-${id} quotation-state`, document.querySelector(`.quotation-${id} .quotation-state`).value);
      document.querySelector(`.quotation-${id} .quotation-state`).innerHTML = status;
    }
    setRefreshIcon(true);
  }

  useEffect(() => {
    setError(false);
    //setting up the branches data
    getBranchData()
      .then((res) => {
        console.log("setting branch data ", res.data.recordset);
        setBranchData(res.data.recordset);
      })
      .catch((e) => {
        console.log(e.message)
      })

    if (!!urlParams.id) {
      getConfirmedQuotations({ lead_info_id: urlParams.id }).then((res) => {
        console.log("getConfirmedQuotations list ", res);
        if (res.responseCode == 200) {
          console.log("getConfirmedQuotations =======>. ", res.responseData);
          let rootElementQuotaionList = document.getElementById('rootElementQuotaionList');
          rootElementQuotaionList.innerHTML = "";
          Object.keys(res.responseData).map(obj => {
            if (obj !== 'leadInfo') {
              rootElementQuotaionList.appendChild(createQuotationsTableRow(res.responseData[obj], urlParams.id));
            }
            return;
          });
          document.getElementById("quotation_container").classList.remove("hide");
          let leadInfo = res.responseData['leadInfo'];
          let { first_name, last_name, mobile, email } = leadInfo;
          setStudentData({ first_name, last_name, mobile, email });
          setParentData({
            first_name: leadInfo.parent_first_name,
            last_name: leadInfo.parent_last_name,
            mobile: leadInfo.parent_mobile,
            email: leadInfo.parent_email
          });
        }
        else {

        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [])

  useEffect(() => {
    CurrencyAmountBox({ rate, fxAmount, filterText, currencyText, setAmountBox, setRemittanceRate, rateInput, commission })
  }, [currencyText, fxAmount, rate, filterText, nestroChargesPayerOption, sourceOfFundOption]);

  const preferredBanks = ['ICICI Bank Ltd.', 'HDFC Bank Ltd.', 'Yes Bank Ltd.']

  let preferredBankOptions = preferredBanks?.map(bank => {
    return <option value={bank}>{bank}</option>;
  });

  let branchDataOptions = branchData?.map(obj => {
    return <option key={obj.BranchID} value={obj.BranchID}>{obj.BranchName}</option>;
  });
  return (
    <>
      <div className="loadenv hide" id="middleLoader">
        <div className="loader text-center">
          <img src={commonConstant.REMITX_URL + "remitx-images/loader.gif"} />
        </div>
      </div>
      <input type="hidden" id="leadDetailId" value={leadDetailId} />
      <input type="hidden" id="quotationId" value={quotationId} />
      <input type="hidden" id="itemSelectedValue" value="" />
      {spinner && (
        <div className="loadenv">
          <div className="loader text-center">
            <img src={commonConstant.REMITX_URL + "remitx-images/loader.gif"} />
          </div>
        </div>
      )}
      <div className="page-body-fx container mrg-t30">
        <div className='row'>
          <div className='col-md-12'><h3>Create Lead</h3></div>
          <div className='col-md-6  timerout hide'>Complete your order before rate expires in <span>05 Minutes 30 Seconds</span></div>
          <div className='col-md-5 left-side mrgb-4'>
            <div className='row'>
              <ProductDropdown filterText={filterText} onFilterTextChange={setFilterText} />
              <RemittanceTypeDropdown filterText={filterText} remittanceType={remittanceType} setRemittanceType={setRemittanceType} />
              <PurposeDropdown filterText={filterText} onFilterTextChange={setFilterText} setPurpose={setPurpose} />
              <CurrencyDropdown filterText={filterText} onCurrencyChange={setCurrrenyText} />
              <CountryDropdown onCountryChange={setCountry} product={filterText} />
              <RateField setLiveRateInput={setLiveRateInput} setRate={setRate} setError={setError} currencyText={currencyText} error={error} rate={rate} product={filterText} />
              <div className='col-md-6'>
                <FxAmountInput fxAmount={fxAmount} onFxAmountChange={setFxAmount} />
              </div>
              <div className='col-md-12 mrg-t30'>
                <div className='row'>
                  {(filterText !== 'buy_currency' && filterText !== 'buy_card') && <NestroChargesPayer nestroChargesPayerOption={nestroChargesPayerOption} setNestroChargesPayerOption={setNestroChargesPayerOption} />}
                  {(filterText !== 'buy_currency' && filterText !== 'buy_card') && <SourceOfFund sourceOfFundOption={sourceOfFundOption} setSourceOfFundOption={setSourceOfFundOption} />}
                </div>
              </div>
              {amountBox}
              <div className='col-md-12 mrg-t30'>
                <AddToCartButton rateInput={rateInput} error={error} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setTempLeadHtml={setTempLeadHtml} sourceOfFundOption={sourceOfFundOption} nestroChargesPayerOption={nestroChargesPayerOption} fxAmount={fxAmount} filterText={filterText} currencyText={currencyText} country={country} sendQuoteButton={sendQuoteButton} setSendQuoteButton={setSendQuoteButton} setQuotationsHtml={setQuotationsHtml} purpose={purpose} setSpinner={setSpinner} remittanceRate={remittanceRate} setIsPlaceOrderModalOpen={setIsPlaceOrderModalOpen} leadDetailId={leadDetailId} remittanceType={remittanceType} />
                <button type="button" className="btn btn-outline-primary" onClick={resetButton}>Reset</button>
              </div>
            </div>
          </div>
          <div className='col-md-7 right-side mrgb-4'>
            <div className='row'>
              <div className='col-md-12 mb-3'>
                <h4>Cart Items</h4>
                <div className="mb-3 table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Product</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Rate</th>
                        <th scope="col">Total INR Amount</th>
                        <th scope="col">
                          Charges
                        </th>
                        <th scope="col">Nostro Charges</th>
                      </tr>
                    </thead>
                    <tbody id='rootElementCartList'>
                    </tbody>
                    <tr id='noRecordFound'><td className='text-center' colSpan='7'>No Record Found</td></tr>

                  </table>
                  <p className=" remitx-color mb-3"   > Note: TCS may be applicable </p>
                </div>
                <button id="sendQuoteButton" type="button" className="hide sendq btn btn-outline-primary mrg-r20" onClick={openQuotationModal}>Send Quote</button>
              </div>

              <div className='col-md-12 quotation hide' id='quotation_container'>
                <h4>Quotations</h4>
                <div className="table-responsive">
                  <table className="table table-bordered mrgb-1">
                    <thead>
                      <tr>
                        <th></th>
                        <th scope="col">FX Amount</th>
                        <th scope="col">Rate</th>
                        <th scope="col">Amount Payable</th>
                        <th scope="col">Your Revenue</th>
                        <th scope="col">TCS</th>
                        <th scope="col"><span style={{ "margin-right": '10px' }}>Status</span>
                          {
                            refreshIcon &&
                            <FiRefreshCw onClick={getQuotations} />
                          }
                          {
                            !refreshIcon &&
                            <RxCross2 />
                          }
                        </th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody id='rootElementQuotaionList'>
                      <tr id='noRecordFound' className='hide'><td className='text-center' colSpan='8'>No Record Found</td></tr>
                    </tbody>
                  </table>
                </div>
                {/* <div className="color-remitx mb-3">Note : TCS may be applicable</div> */}

                <div className='user__details'>
                  <h3>User Details</h3>
                  <p>{studentData.first_name} {studentData.last_name}</p>
                  <p>{studentData.email}</p>
                  <p>{studentData.mobile}</p>
                </div>
                <div className="col-md-12 mrg-t30">
                  <button type="button" className="btn btn-primary" id="placeOrderButton" onClick={checkPlaceOrder}>Place Order</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
        <div className='modal-heading'>
          <h3>Student Details</h3>
          <button type="button" className='btn-close close' aria-label="Close" onClick={closeModal}></button></div>
        <div className='col-md-12 modal__custom'>
          <div className='row' id='student_details'>
            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label">Full Name<span>*</span></label>
                <input className="form-control" type="text" defaultValue={studentData.full_name} onKeyDown={setAlpha} placeholder='Enter full name' name='full_name' />
              </div>
            </div>
            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label">Email<span>*</span></label>
                <input className="form-control" type="text" defaultValue={studentData.email} onKeyDown={setEmailValidation} placeholder='Enter Email' name='email' />
              </div>
            </div>
            {/* <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label">Last Name<span>*</span></label>
                <input className="form-control" type="text" defaultValue={studentData.last_name} onKeyDown={setAlpha} placeholder='Enter last name' name='last_name'
                />
              </div>
            </div> */}
            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label">Mobile Number<span>*</span></label>
                <input maxLength="10" className="form-control" type="text" defaultValue={studentData.mobile} placeholder='Enter Mobile' name='mobile_number'
                  onKeyDown={setNumeric}
                />
                {/* <input className="form-control" type="number" defaultValue={studentData.mobile} placeholder='Enter Mobile' name='mobile_number' /> */}
              </div>
            </div>
            {/* <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label">Email<span>*</span></label>
                <input className="form-control" type="text" defaultValue={studentData.email} onKeyDown={setEmailValidation} placeholder='Enter Email' name='email' />
              </div>
            </div> */}
          </div>
          <h3>Parent Details</h3>
          <div className='row' id='parent_details'>
            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label">Full Name<span>*</span></label>
                <input className="form-control" defaultValue={parentData.full_name} type="text" onKeyDown={setAlpha} placeholder='Enter full name' name='full_name' />
              </div>
            </div>
            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label">Email<span>*</span></label>
                <input className="form-control" defaultValue={parentData.email} type="email" onKeyDown={setEmailValidation} placeholder='Enter Email' name='email' />
              </div>
            </div>
            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label">Mobile Number<span>*</span></label>
                <input maxLength="10" className="form-control" type="text" defaultValue={parentData.mobile} placeholder='Enter Mobile' name='mobile_number'
                  onKeyDown={setNumeric}
                />
              </div>
            </div>
          </div>
          <h3>Payer Details</h3>
          <div className='row' id='payer_details'>
            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label">Pan No.<span>*</span></label>
                <input className="form-control" defaultValue={payerData.pan_number} type="text"
                  maxLength={10}
                  onKeyDown={setAlphaNumeric}
                  placeholder='Enter Pan. No.'
                  onInput={(e) => e.target.value = e.target.value.toUpperCase()}
                  name='pan_number'
                  spellCheck={false}
                />
              </div>
            </div>

            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label">Payer Preferred Bank Name<span>*</span></label>
                <select className="form-select" name="preferred_bank">
                  <option value="">Please select</option>
                  {preferredBankOptions}
                </select>

              </div>
            </div>
          </div>
          {/*  */}
          <div className='col-md-12 mrg-t10'>
            <button type="button" data-quot_detail_id="" data-lead_info_id="" className="btn btn-primary mrg-r20" onClick={sendQuoteClick}>Send Quotation</button>
            <button type="button" className="btn btn-outline-primary" onClick={closeModal}>Close</button>
          </div>
        </div>
      </Modal>
      <Modal isOpen={isPlaceOrderModalOpen} onRequestClose={closePlaceOrderModal}>
        <div id="PlaceOrderModal" className="page-body-fx container mrg-t30">
          <div className='row'>
            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label text-bold">First Name<span>*</span></label>
                <input className="form-control" name="first_name" type="text" defaultValue={studentData.first_name} onKeyDown={setAlpha} placeholder='Enter name' />
              </div>
            </div>
            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label text-bold">Last Name<span>*</span></label>
                <input className="form-control" name="last_name" type="text" defaultValue={studentData.last_name} onKeyDown={setAlpha} placeholder='Enter name' />
              </div>
            </div>
            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label text-bold">Mobile Number<span>*</span></label>
                <input maxLength="10" className="form-control" name="mobile" type="text" defaultValue={studentData.mobile} onKeyDown={setNumeric} placeholder='Enter Mobile' />
              </div>
            </div>
            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label text-bold">Email<span>*</span></label>
                <input className="form-control" name="email" type="text" defaultValue={studentData.email} onKeyDown={setEmailValidation} placeholder='Enter Email' />
              </div>
            </div>
          </div>
          <div className='row mrg-t20'>
            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label text-bold">RemitX Branch<span>*</span></label>
                <select className="form-select" id='branch_id'>
                  <option value="">Please Select</option>
                  {branchDataOptions}
                </select>
              </div>
            </div>
          </div>
          <h3 className='mrg-t20'>Documents</h3>
          <div className='row'>
            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label text-bold">Document Type</label>
                <select className="form-select" name="document_type">
                  <option value="">Please select</option>
                  <option value="adhaar_car">Adhaar Card</option>
                  <option value="passport">Passport</option>
                  <option value="voter_card">Voter Card</option>
                </select>
              </div>
            </div>

            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label text-bold">Document</label>
                <input className="form-control" type="file" placeholder='Enter name' onChange={changeDocumentHandler} />
              </div>
            </div>

          </div>
          <div className='row'>
          <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label text-bold">Payment Mode<span>*</span></label>
                <select  className="form-select" id='payment_mode'>
                  <option value="">Please Select</option>
                  <option value="cash">Cash</option>
                  <option value="dd">DD</option>
                  <option value="cheque">Cheque</option>
                  <option value="netbanking">Netbanking</option>
                </select>
              </div>
            </div>

            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label text-bold">Payment Reference Number<span>*</span></label>
                <input id='payment_ref_num'  className="form-control" name="mobile" type="text"  onKeyDown={setAlphaNumeric} placeholder='Enter Mobile' />
              </div>
            </div>
          </div>
          <div className='col-md-12 mrg-t30'>
            <button type="button" className="btn btn-primary mrg-r20" onClick={createPlaceOrder}>Place Order</button>
            <button type="button" className="btn btn-outline-primary" onClick={closePlaceOrderModal}>Close</button>
          </div>
        </div>
      </Modal >

    </>
  );
}

export default CreateLead;