//import Table from 'react-bootstrap/Table';
import { useEffect, useRef, useState } from "react";
import { commonConstant } from "../../CommonElements/constants";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from "axios";

import { setFloat, setNumeric, showErrorNotification, showSuccessNotification, slicedData } from "../../CommonElements/commonFunctions";
import { Pagination } from "../../Components/Pagination/Pagination";
import { DateRangePicker } from "rsuite";
import { BiDownArrow, BiSolidUpArrow, BiUpArrow } from "react-icons/bi";
import { countryData, getBranchData } from "../../services/Service";

let url = process.env.REACT_APP_BACKEND_API_URL

const changeDateFormat = (datestr) => {
    const dateTimeString = datestr;
    const dateTime = new Date(dateTimeString);
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1; // Month is zero-based, so we add 1
    const day = dateTime.getDate();
    // Create a new date string in the desired format (YYYY-MM-DD)
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    return formattedDate;
}

function LoanLeadsReport() {

    const [description, setDesc] = useState("");
    const [dis_loan_amt, setDis_loan_amt] = useState("");
    const [dis_st, setDis_st] = useState("");
    
    const [searchName, setSearchName] = useState("");
    const [searchFirstName,setSearchFirstname]=useState("");
    const [searchLastName,setSearchLastName]=useState("");
    const [searchId,setSearchId]=useState("");
    const [fullscreen, setFullscreen] = useState(true);
    const values = [true];
    const [show, setShow] = useState(false);
    const [leads, setLeads] = useState([])
    const [showIndex, setShowIndex] = useState(-1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    // const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [map, setMap] = useState(null)
    const [showLoader, setShowLoader] = useState(false)
    const [countryItems, setCountryitems] = useState([]);
    let branchMap = new Map()

    const [countryMap,setCountryMap]=useState({});
    const makeCountryMap=(countries)=>{
        let map={};
        countries.map((country)=>{
            map[country.country_id]=country.country_name
        })
        setCountryMap({...map})
    }
    // fetch Countries --to map country code And name
    useEffect(() => {
        countryData()
            .then((res) => {
                setCountryitems(res.data);
                makeCountryMap(res.data)
            })
            .catch((e) => {
                console.log(e.message);
            });
    }, []);

    const fetchLeads = async () => {
        try {
            setShowLoader(true)
            let data = await axios.get(`${url}partners/loan?page_number=${currentPage}&loan_reference_no=${searchId}&name=${searchName}&startDate=${startDate}&endDate=${endDate}&firstName=${searchFirstName}&lastName=${searchLastName}`)
            let lds = data.data?.responseData?.leads
            setTotalPages(data.data.responseData.totalPages)
            setLeads(lds)

        } catch (e) {
            console.log("Error is", e)
        } finally {
            setShowLoader(false)
        }

    }

    const handleSaveClick = async (e) => {
        e.preventDefault();
        try {
            setShowLoader(true);
            await axios.patch(`${url}partners/loan`, {
                id: leads[showIndex].id,
                attributes: {
                    description: description,
                    disbursement_loan_amount: dis_loan_amt,
                    disbursement_status: dis_st,
                }
            })

            // update this data in leads
            setLeads((pre) => {
                pre.map((lead) => {
                    if (lead.id === leads[showIndex].id) {
                        lead.attributes.description = description
                        lead.attributes.disbursement_loan_amount = dis_loan_amt
                        lead.attributes.disbursement_status = dis_st
                    }
                })
                return [...pre]
            })
            setShow(false)
            showSuccessNotification("Details Updated Successfully.")

        } catch (e) {
            showErrorNotification("Server Error ,Please try after some time")
        } finally {
            setShowLoader(false)
        }
    }
    // Branch Map to map name with Id
    const makeBranchMap = (branches) => {
        branches.map((branch) => {
            branchMap.set(branch.BranchID + "", branch.BranchName)
        })
        setMap(branchMap)
    }
    useEffect(() => {
        fetchLeads();
    }, [currentPage])

    const fetchBranchData = () => {
        getBranchData().then((data) => {
            makeBranchMap(data.data.recordset)
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        fetchBranchData()
    }, [])

    useEffect(() => {
        fetchLeads()
    }, [])



    useEffect(() => {
        setDesc(leads[showIndex]?.attributes.description)
        setDis_loan_amt(leads[showIndex]?.attributes.disbursement_loan_amount)
        setDis_st(leads[showIndex]?.attributes.disbursement_status)
    }, [showIndex + currentPage + "", show])

    function handleShow(index) {
        setFullscreen();
        setShow(true);
        setShowIndex(index)
    }

    const sortByDateAsc = () => {
        let sortedLeads = leads.sort((lead1, lead2) => new Date(lead1.attributes.date_entered + "") - new Date(lead2.attributes.date_entered + ""))
        setLeads(() => {
            return [...sortedLeads]
        })
    }

    const sortByDateDesc = () => {
        let sortedLeads = leads.sort((lead1, lead2) => new Date(lead2.attributes.date_entered + "") - new Date(lead1.attributes.date_entered + ""))
        setLeads(() => {
            return [...sortedLeads]
        })
    }
    const handleDateRange = (e) => {
        if (e == null) {
            setStartDate(null);
            setEndDate(null)
        } else {
            setStartDate(changeDateFormat(e[0]))
            setEndDate(changeDateFormat(e[1]))
        }
    }
    useEffect(()=>{
        if(startDate==null && searchId===""&&searchName===""&&searchFirstName===""&&searchLastName==="")
        fetchLeads();
    },[startDate,searchId,searchName,searchFirstName,searchLastName])

    const handleReset=()=>{
        setStartDate(null);
        setEndDate(null);
        setSearchId("");
        setSearchFirstname("");
        setSearchLastName("");
        setSearchName("")
        
    }

    const handleSearch = () => {
        fetchLeads();
    }
    const handleSearchName=(e)=>{

        const fullName = e.target.value;
        const nameParts = fullName.split(' ');
        const firstName = nameParts.length > 1 ? nameParts.slice(0, -1).join(' ') : fullName;
const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';
        setSearchFirstname(firstName);
        setSearchLastName(lastName);
        setSearchName(fullName)

    }

    return (
        <>
            {showLoader &&
                <div className="loadenv" id="middleLoader">
                    <div className="loader text-center">
                        <img
                            src={
                                commonConstant.REMITX_URL +
                                "remitx-images/loader.gif"
                            }
                        />
                    </div>
                </div>}
            <div className="page-body-fx container mrg-t30">
                <div className="row">
                    <div className="col-md-12">
                        <h3>Search By</h3>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="mb-3">
                                    <label
                                        htmlFor="product"
                                        className="form-label"
                                    >
                                        Date
                                    </label>
                                    <DateRangePicker
                                        placeholder={"Select a date range"}
                                        format="yyyy-MM-dd"
                                        onChange={handleDateRange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="mb-3">
                                    <label
                                        htmlFor="product"
                                        className="form-label"
                                    >
                                        Application Id 
                                    </label>
                                    <input
                                        id="searchKeyword"
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Application Id"
                                        value={searchId}
                                        onChange={(e) => { setSearchId(e.target.value) }}

                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="mb-3">
                                    <label
                                        htmlFor="product"
                                        className="form-label"
                                    >
                                        Lead Info.
                                    </label>
                                    <input
                                        id="searchKeyword"
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Lead Info"
                                        value={searchName}
                                        onChange={(e)=>handleSearchName(e) }

                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <button
                                    type="button"
                                    className="btn btn-primary mrg-t30"
                                onClick={()=>{setCurrentPage(1);handleSearch()}}
                                >
                                    Search
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary mrg-t30"
                                    style={{marginLeft:"15px"}}
                                onClick={handleReset}
                                >
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mrg-t30">
                        <h3>Loan Lead Listing</h3>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        Date
                                        <span onClick={sortByDateDesc}>  <BiUpArrow /></span>
                                        <span onClick={sortByDateAsc}><BiDownArrow /></span>
                                    </th>

                                    <th scope="col">Application Id</th>
                                    <th scope="col">Lead Info</th>
                                    <th scope="col">Requested Loan Amount</th>
                                    <th scope="col">Approved Loan Amount</th>
                                    <th scope="col">Disbursement Status</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody id="LoanleadListingTbody">

                                {leads?.map((lead, index) => {

                                    return (
                                        <tr key={lead.id}>
                                            <td>{changeDateFormat(lead.attributes.date_entered)}</td>
                                            <td>{lead.attributes?.loan_reference_no}</td>
                                            <td>
                                                <div>
                                                    <div>{lead.attributes?.name}</div>
                                                    <div>{lead.attributes?.customer_email_id}</div>
                                                    <div>{lead.attributes?.phone_mobile}</div>
                                                </div>
                                            </td>
                                            <td>{lead.attributes?.loan_amount}</td>
                                            <td>{lead.attributes?.disbursement_loan_amount}</td>
                                            <td>{lead.attributes?.disbursement_status.replace("_", " ")}</td>
                                            <td>{lead.attributes?.description}</td>
                                            <td>
                                                {values.map((v, idx) => (
                                                    <a key={idx} className="me-2 mb-2 hov" onClick={() => handleShow(index)}>View</a>
                                                )
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                                {
                                    leads.length==0&& <tr><td colspan="8" className="text-center">No Data Found</td></tr>
                                }
                            </tbody>
                        </table>
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </div>

            <Modal className="fullscreen" show={show} fullscreen={fullscreen} onHide={() => { setShow(false); console.log("dis-loan"); console.log(dis_loan_amt) }}>
                <Modal.Header closeButton>
                    <Modal.Title>{`Update Lead Status(${leads[showIndex]?.attributes?.loan_reference_no})`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="row">
                            <div className="col-md-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control disabled={true} type="text" placeholder="Please Enter" value={leads[showIndex]?.attributes.name} />
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control disabled={true} type="text" placeholder="Please Enter" value={leads[showIndex]?.attributes.phone_mobile} />
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Parent Name</Form.Label>
                                    <Form.Control disabled={true} type="text" placeholder="Please Enter" value={leads[showIndex]?.attributes.parent_name_c} />
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Parent Email</Form.Label>
                                    <Form.Control disabled={true} type="email" placeholder="Please Enter" value={leads[showIndex]?.attributes.parent_email} />
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Parent Mobile Number</Form.Label>
                                    <Form.Control disabled={true} type="text" placeholder="Please Enter" value={leads[showIndex]?.attributes.parent_mobile_number} />
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Course details</Form.Label>
                                    <Form.Control disabled={true} type="text" placeholder="Please Enter" value={leads[showIndex]?.attributes.course_details} />
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Abroad institute name</Form.Label>
                                    <Form.Control disabled={true} type="text" placeholder="Please Enter" value={leads[showIndex]?.attributes.abroad_institution_name} />
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Study Country</Form.Label>
                                    <Form.Control disabled={true} type="text" placeholder="Please Enter" value={countryMap?.[leads[showIndex]?.attributes.country*1]} />
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Branch Name</Form.Label>
                                    <Form.Control disabled={true} type="text" placeholder="Please Enter" value={map?.get(leads[showIndex]?.attributes.branch) || ""} />
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Branch Executive Name</Form.Label>
                                    <Form.Control disabled={true} type="text" placeholder="Please Enter" value={leads[showIndex]?.attributes.branch_executive_name} />
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Branch Executive Email ID</Form.Label>
                                    <Form.Control disabled={true} type="text" placeholder="Please Enter" value={leads[showIndex]?.attributes.branch_executive_email_id} />
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Branch Executive Number</Form.Label>
                                    <Form.Control disabled={true} type="text" placeholder="Please Enter" value={leads[showIndex]?.attributes.branch_executive_number} />
                                </Form.Group>
                            </div>
                            {/* <div className="col-md-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Lead Status</Form.Label>
                                    <Form.Control disabled={true} type="text" placeholder="Please Enter" value={data[showIndex]?.attributes.status} />
                                </Form.Group>
                            </div> */}
                            {/* <div className="col-md-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Lead Status Description</Form.Label>
                                    <Form.Control disabled={true} type="text" placeholder="Please Enter" value={data[showIndex]?.attributes.status_description} />
                                </Form.Group>
                            </div> */}
                            <div className="col-md-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Requested Loan Amount</Form.Label>
                                    <Form.Control disabled={true} type="text" placeholder="Please Enter" value={leads[showIndex]?.attributes.loan_amount} />
                                </Form.Group>
                            </div>
                           
                            <div className="col-md-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Lead Status </Form.Label>
                                    <Form.Select name="disbursement_status" onChange={(e) => { setDis_st(e.target.value) }} aria-label="Default select example" value={dis_st}  >
                                        <option value="">Select</option>
                                        <option value="File_login">File login</option>
                                        <option value="Sanction">Sanction</option>
                                        <option value="Disbursement_Successfull">Disbursement Successfull</option>
                                        <option value="Credit_Decline">Credit Decline</option>
                                        <option value="Customer_Decline">Customer Decline</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Approved Loan Amount </Form.Label>
                                    <Form.Control onKeyDown={setFloat} onChange={(e) => setDis_loan_amt(e.target.value)} name="disbursement_loan_amount" type="text" placeholder="Please Enter" value={dis_loan_amt} />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3">
                                    <Form.Label>Description by Partner </Form.Label>
                                    <Form.Control name="description" as="textarea" rows={3} onChange={(e) => { setDesc(e.target.value) }} value={description} />
                                </Form.Group>
                            </div>

                            <div className="col-md-12 text-right">
                                <button className="btn btn-primary" onClick={handleSaveClick}>Save</button>
                            </div>

                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default LoanLeadsReport;