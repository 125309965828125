import { commonConstant } from "../../CommonElements/constants";

export default function RemittanceTypeDropdown({ filterText, remittanceType, setRemittanceType }) {
    if (!filterText) {
        return;
    }
    //check value is in array
    if (!commonConstant.PRODUCT["tt"].includes(filterText)) {
        return;
    }
    return (
        <>
            <div className="col-md-6">
                <div className="mb-3">
                    <label htmlFor="remittance_type" className="form-label">
                        Remittance Type<span>*</span>
                    </label>
                    <select
                        className="form-select"
                        id="remittance_type"
                        aria-label="Default select example"
                        value={remittanceType}
                        onChange={(e) => setRemittanceType(e.target.value)}
                    >
                        <option value="">Select Remittance Type</option>
                        <option value="tt">TT (Telegraphic Transfer)</option>
                        <option value="dd">DD (Demand Draft)</option>
                        <option value="sd">SD (Signed Draft)</option>
                    </select>
                </div>
            </div>
        </>
    );
}