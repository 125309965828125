import {
    createCartTableRow,
    showErrorNotification,
    showSuccessNotification,
} from "../../CommonElements/commonFunctions";
import { commonConstant } from "../../CommonElements/constants";
import { addToCart } from "../../services/Service";

export default function AddToCartButton({
    error,
    filterText,
    fxAmount,
    currencyText,
    country,
    rateInput,
    sourceOfFundOption,
    nestroChargesPayerOption,
    setIsModalOpen,
    purpose,
    setIsPlaceOrderModalOpen,
    remittanceType,
}) {
    const addToCartClick = (e) => {
        e.preventDefault();
        console.log("rateInput = ", rateInput);
        // let remittance_type = "";
        if (!fxAmount || !filterText || !currencyText || !country) {
            showErrorNotification("Please fill all the fields.", "topLeft");
            return;
        }
        if (commonConstant.PRODUCT["tt"].includes(filterText)) {
            // remittance_type = document.getElementById("remittance_type").value;
            if (!remittanceType) {
                showErrorNotification(
                    "Please select the Remittance Type.",
                    "topLeft"
                );
                return;
            }
        }
        if (!rateInput) {
            showErrorNotification("Please enter the Rate.", "topLeft");
            return;
        }
        if ( filterText!=='buy_currency' && filterText!=='buy_card' && !nestroChargesPayerOption) {
            showErrorNotification(
                "Please check Nestro Charges Payer.",
                "topLeft"
            );
            return;
        }
        if ( filterText!=='buy_currency' && filterText!=='buy_card' &&!sourceOfFundOption) {
            showErrorNotification("Please check Source of fund.", "topLeft");
            return;
        }
        if (error) {
            showErrorNotification(error, "topLeft");
            return;
        }
        let dataObj = {
            remittance_type: remittanceType,
            currency: currencyText,
            country,
            fx_amount: fxAmount,
            product: filterText,
            purpose: purpose,
            rate: document.querySelector('input[name="rate"]').value,
            fund_source: sourceOfFundOption,
            nestro_payer: nestroChargesPayerOption,
        };

        console.log(
            "The link was clicked.",
            remittanceType,
            filterText,
            fxAmount,
            currencyText,
            country,
            sourceOfFundOption,
            nestroChargesPayerOption,
            rateInput
        );
        //setSpinner(true);
        addToCart(dataObj).then((res) => {
            console.log(res, " response addToCart ");
            if (res.responseCode !== 200) {
                showErrorNotification(res.responseMessage);
            } else {
                showSuccessNotification("Product has been added to the cart.");
                if (document.getElementById("noRecordFound")) {
                    document.getElementById("noRecordFound").remove();
                }
                let z = createCartTableRow(res.responseData[0]);
                let rootElementCartList = document.getElementById(
                    "rootElementCartList"
                );
                rootElementCartList.appendChild(z);
                document
                    .getElementById("sendQuoteButton")
                    .classList.remove("hide");
            }
        });

        const openPlaceOrderModal = () => {
            setIsPlaceOrderModalOpen(true);
        };
    };
    const openQuotationModal = () => {
        setIsModalOpen(true);
    };
    return (
        <button
            type="button"
            className="btn btn-primary mrg-r20"
            onClick={addToCartClick}
        >
            Add to Cart
        </button>
    );
}
