import { setNumeric } from "../../CommonElements/commonFunctions";

export default function FxAmountInput({ allowedEmpty,fxAmount, onFxAmountChange }) {
    return (
        <>
            <div className="mb-3">
                <label htmlFor="product" className="form-label">
                    FX Amount {!allowedEmpty&&<span>*</span>}
                </label>
                <input
                    type="number"
                    min="1"
                    value={fxAmount}
                    className="form-control"
                    placeholder="Please Enter"
                    onKeyDown={setNumeric}
                    onChange={(e) => onFxAmountChange(e.target.value)}
                />
            </div>
        </>
    );
}
