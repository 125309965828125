import { headers } from "../CommonElements/commonFunctions";
import { commonConstant } from "../CommonElements/constants";

export const getLeads = async (data) => {
  document.getElementById("middleLoader").classList.remove("hide");
    let response = await fetch(commonConstant.BACKEND_API_URL + 'partners/order/getstats?type=0&fromDate='+data.startDate+'&tillDate='+data.endDate,{
      method:'get',
      headers:headers()
    });
    document.getElementById("middleLoader").classList.add("hide");
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
      // return {responseCode:200,responseData:[
      //         {lead:{ "total": 10, "open": 20, "inprogress": 349, "closed": 123},
      //         order: { "total": 10, "inprogress": 20, "processed": 349, "cancelled": 123}}]};
      return response.json()
    }
}

export const getEarningsNumbers = async (data) => {
    let condition_string = '';
    if(data.startDate && data.endDate){
      condition_string = '&FromDate='+data.startDate+'&TillDate='+data.endDate;
    }
    let response = await fetch(commonConstant.BACKEND_API_URL + 'partners/order/getearningtotal?type=0'+condition_string,{
      method:'get',
      headers:headers()
    });
    // document.getElementById("middleLoader").classList.add("hide");
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
      // return {responseCode:200,responseData:[
      //         {lead:{ "total": 10, "open": 20, "inprogress": 349, "closed": 123},
      //         order: { "total": 10, "inprogress": 20, "processed": 349, "cancelled": 123}}]};
      return response.json()
    }
}
// export const getOrders = async() => {
//   let response = await fetch(commonConstant.BACKEND_API_URL + 'hcheck');
//     if (!response.ok) {
//       throw new Error('Data coud not be fetched!')
//     } else {
//       return { "total": 140, "open": 320, "processed": 39, "closed": 12 };
//       return response.json()
//     }
// }
export const fetchData = async () => {
  const data = new URLSearchParams();
  data.append("apiKey", commonConstant.API_KEY);
  data.append("productName", "TT");
  
  const response = await fetch(commonConstant.BACKEND_API_URL + 'partners/rate/currencies', {
  //const response = await fetch(commonConstant.REMITX_NODE_API_URL + 'api/getCurrencyList', {
    method: 'GET',
    headers: {}
  })
  if (!response.ok) {
    throw new Error('Data coud not be fetched!')
  } else {
    return response.json()
  }
}


export const refreshQuotations = async () => {
  let response = await fetch(commonConstant.BACKEND_API_URL + 'partners/order/getstats?type=0',{
    method:'get',
    headers:headers()
  });
  if (!response.ok) {
    throw new Error('Data coud not be fetched!')
  } else {
    return response.json()
  }
}

export const refreshQuotationsStatus = async (ids) => {
  let response = await fetch(commonConstant.BACKEND_API_URL + 'partners/quotations/status?ids='+ids,{
    method:'get',
    headers:headers()
  });
  if (!response.ok) {
    throw new Error('Data coud not be fetched!')
  } else {
    return response.json()
  }
}

export const countryData = async () => {
  // const data = new URLSearchParams();
  const response = await fetch(commonConstant.REMITX_NODE_API_URL + 'api/countries', {
    method: 'get'
  })
  if (!response.ok) {
    throw new Error('Data coud not be fetched!')
  } else {
    return response.json()
  }
}

export const checkToken = async () => {
  const response = await fetch (commonConstant.BACKEND_API_URL + 'partners/user/data', {
    method: 'get',
    headers: headers()
  });
  if (!response.ok) {
    throw new Error('Data coud not be fetched!')
  } else {
    return response.json()
  }
}

export const getLiveCurrencyRate = async (currency,product='overseas_education') => {
  console.log("currency is ", currency);
  const data = new URLSearchParams();
  //data.append("apiKey", commonConstant.API_KEY);
  data.append("product", product);
  data.append("currency", currency);
  const response = await fetch(commonConstant.BACKEND_API_URL + 'partners/rate/settlement', {
    method: 'post',
    headers: headers(),
    body: JSON.stringify({product:product,currency:currency,isBuyCurrency:product==='buy_currency'})
  })
  if (!response.ok) {
    throw new Error('Data coud not be fetched!')
  } else {
    console.log(response)
    return response.json()
  }
}

export const fetchRemmitanceRate = async (obj) => {
  console.log("obj is ", obj.currency, obj.product, obj.fx_amount);
  const myObject = {
    type: 'sale_under_lrs',
    apiKey: commonConstant.API_KEY,
    product: obj.product,
    currency: obj.currency,
    fx_amount: obj.fx_amount,
    transaction_fee: 0
  };
  // const queryString = qs.stringify(data, { indices: false });
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(myObject)
  };
  const response = await fetch(commonConstant.REMITX_NODE_API_URL + 'rapi/rc/remittance_rate', requestOptions)
  if (!response.ok) {
    throw new Error('Data coud not be fetched!')
  } else {
    return response.json()
  }
}

export const addToCart = async (obj) => {
  document.getElementById("middleLoader").classList.remove("hide");
  const requestOptions = {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(obj)
  };
  console.log(requestOptions, '========>>>');
  const response = await fetch(commonConstant.BACKEND_API_URL + 'partners/quotations/create', requestOptions)
  document.getElementById("middleLoader").classList.add("hide");
  if (!response.ok) {
    throw new Error('Data coud not be fetched!');
  } else {
    return response.json()
  }
}

export const sendQuote = async (obj) => {
  document.getElementById("middleLoader").classList.remove("hide");
  console.log("sendQuote", obj);
  const requestOptions = {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(obj)
  };
  const response = await fetch(commonConstant.BACKEND_API_URL + 'partners/lead/send', requestOptions)
  document.getElementById("middleLoader").classList.add("hide");
  if (!response.ok) {
    throw new Error('Problem while creating the lead.')
  } else {
    return response.json()
  }
}

export const verifyOrder = async (obj) => {
  console.log("placeOrder", obj);
  const requestOptions = {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(obj)
  };
  const response = await fetch(commonConstant.BACKEND_API_URL+'partners/order/verify', requestOptions)
  if (!response.ok) {
    throw new Error('Data coud not be fetched!')
  } else {
    return response.json()
  }
}

export const placeOrder = async (obj) => {
  console.log("placeOrder", obj);
  const requestOptions = {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(obj)
  };
  const response = await fetch(commonConstant.REMITX_NODE_API_URL + 'rapi/rc/remittance_rate', requestOptions)
  if (!response.ok) {
    throw new Error('Data coud not be fetched!')
  } else {
    return response.json()
  }
}


export const getBranchData = async (obj) => {
  const response = await fetch(commonConstant.REMITX_NODE_API_URL + 'api/getBranchData', {})
  if (!response.ok) {
    throw new Error('Data coud not be fetched!')
  } else {
    return response.json()
  }
}

export const share = async (obj, type = "user") => {
  const requestOptions = {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(obj)
  };
  let url = commonConstant.BACKEND_API_URL + 'partners/share/index';
  if (type === "remitx") {
    url = commonConstant.BACKEND_API_URL + 'partners/share/remitx';
  }
  if (type === "usershare") {
    requestOptions.headers = {
      "Content-Type": "application/json"
    }
    requestOptions.method = 'PUT';
    url = commonConstant.BACKEND_API_URL + 'partners/share/usershare';
  }
  const response = await fetch(url, requestOptions);
  if (!response.ok) {
    throw new Error('Data coud not be fetched!')
  } else {
    return response.json()
  }
}


export const getQuotations = async (obj) => {
  const requestOptions = {
    method:'POST',
    headers:headers()
  }
  const response = await fetch(commonConstant.BACKEND_API_URL+'partners/quotations/cart',requestOptions);
  console.log("response" ,response);
  if(!response.ok){
    throw new Error('Data could not be fetched!')
  }else{
    return response.json()
  }
}
export const getConfirmedQuotations = async (obj) => {
  const requestOptions = {
    method:'POST',
    headers:headers(),
    body: JSON.stringify(obj)
  }
  console.log("requestOptions",requestOptions);
  const response = await fetch(commonConstant.BACKEND_API_URL+'partners/quotations/confirmed',requestOptions);
  if(!response.ok){
    throw new Error('Data could not be fetched!')
  }else{
    return response.json()
  }
}
export const getLeadListing = async (obj) => {
  const requestOptions = {
    method:'POST',
    headers:headers(),
    body: JSON.stringify(obj)
  }
  console.log("requestOptions",requestOptions);
  const response = await fetch(commonConstant.BACKEND_API_URL+'partners/lead/list',requestOptions);
  if(!response.ok){
    throw new Error('Data could not be fetched!')
  }else{
    return response.json()
  }
}

export const getWebsiteLeadListing = async (obj) => {
  const requestOptions = {
    method:'POST',
    headers:headers(),
    body: JSON.stringify(obj)
  }
  let url = commonConstant.BACKEND_API_URL+'partners/lead/weblist';
  if (obj?.type === 'orders') {
    url = commonConstant.BACKEND_API_URL+'partners/order/weblist';
  }
  console.log("requestOptions",url, requestOptions);
  const response = await fetch(url,requestOptions);
  if(!response.ok){
    throw new Error('Data could not be fetched!')
  }else{
    return response.json()
  }
}

export const getEarningListing = async (obj) => {
  document.getElementById("middleLoader").classList.remove("hide");
  const requestOptions = {
    method:'POST',
    headers:headers(),
    body: JSON.stringify(obj)
  }
  console.log("requestOptions",requestOptions);
  const response = await fetch(commonConstant.BACKEND_API_URL+'partners/eforex/earning-listing',requestOptions);
  document.getElementById("middleLoader").classList.add("hide");
  if(!response.ok){
    throw new Error('Data could not be fetched!')
  }else{
    return response.json()
  }
}
//API To be used later
export const getOrderListing = async (obj) => {
  document.getElementById("middleLoader").classList.remove("hide");
  const requestOptions = {
    method:'POST',
    headers:headers(),
    body: JSON.stringify(obj)
  }
  console.log("requestOptions",requestOptions);
  const response = await fetch(commonConstant.BACKEND_API_URL+'partners/eforex/order-listing',requestOptions);
  document.getElementById("middleLoader").classList.add("hide");
  if(!response.ok){
    throw new Error('Data could not be fetched!')
  }else{
    return response.json()
  }
}

export const getAgentPayoutDetails = async (obj) => {
  document.getElementById("middleLoader").classList.remove("hide");
  const requestOptions = {
    method:'POST',
    headers:headers(),
    body: JSON.stringify(obj)
  }
  console.log("requestOptions",requestOptions);
  const response = await fetch(commonConstant.BACKEND_API_URL+'partners/eforex/agent-payment-details',requestOptions);
  document.getElementById("middleLoader").classList.add("hide");
  if(!response.ok){
    throw new Error('Data could not be fetched!')
  }else{
    return response.json()
  }
}


export const getUserDetails = async (data) => {
    let response = await fetch(commonConstant.BACKEND_API_URL+'partners/permission/single?code='+data.code, {
      method: 'GET',
      headers: headers()
  })
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
      return response.json()
    }
}

export const saveUserModules = async (obj) => {
  let response = await fetch(commonConstant.BACKEND_API_URL+'partners/permission/single', {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(obj)
})
  if (!response.ok) {
    throw new Error('Data coud not be fetched!')
  } else {
    return response.json()
  }
}