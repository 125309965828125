import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/main-style.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
//import CreateLead from './Pages/CreateLead';
//import CreateLead from './Pages/LeadReport';
//import OrderReport from './Pages/OrderReport';
import Router from './Router/Router';
// import Router from './Router/Router';
//import Payouts from './Pages/Payouts';
//import CustomerDetails from './Pages/CustomerDetails';
//import PlaceOrder from './Pages/PlaceOrder';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
    <Router />
  </div>
);
reportWebVitals();
