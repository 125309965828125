import { Select } from "antd";
import { useEffect, useState } from "react";
import { countryData } from "../../services/Service";

export default function CountryDropdown({ onCountryChange, product }) {
    const [countryItems, setCountryitems] = useState([]);
    const [countryId, setCountryId] = useState("");
    useEffect(() => {
        countryData()
            .then((res) => {
                console.log(res.data, "initCountryitems");
                setCountryitems(res.data);
            })
            .catch((e) => {
                console.log(e.message);
            });
    }, []);

    let countrylistCanada = (
        <option value="39" defaultValue={"39"}>
            CAN (CANADA)
        </option>
    );

    useEffect(() => {
        if (product === "guaranteed_investment_certificate") {
            onCountryChange("39");
        } else {
            onCountryChange(countryId);
        }
    }, [product]);
    if (product === "guaranteed_investment_certificate") {
        return (
            <div className="col-md-6" id="canadaCountry">
                <div className="mb-3">
                    <label htmlFor="product" className="form-label">
                        Country<span>*</span>
                    </label>
                    <select
                        id="country"
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => onCountryChange(e.target.value)}
                    >
                        {/* <option value="" style={{textColor: ""}}>Please select</option> */}
                        {countrylistCanada}
                    </select>
                </div>
            </div>
        );
    }

    let countrylistOptions = countryItems
        .sort(function (a, b) {
            if (a.country_name.toLowerCase() < b.country_name.toLowerCase())
                return -1;
            if (a.country_name.toLowerCase() > b.country_name.toLowerCase())
                return 1;
            return 0;
        })
        .map((obj) => {
            return {
                label: obj.country_code + " (" + obj.country_name + ")",
                value: obj.country_id,
            };
        });
    countrylistOptions = [
        { label: "Please select", value: "" },
        ...countrylistOptions,
    ];
    return (
        <>
            <div className="col-md-6" id="allCountries">
                <div className="mb-3">
                    <label htmlFor="country" className="form-label">
                        Country<span>*</span>
                    </label>
                    <Select
                        showSearch
                        placeholder="Please select"
                        optionFilterProp="children"
                        onChange={(value) => {
                            onCountryChange(value);
                            setCountryId(value);
                        }}
                        defaultValue={countryId}
                        filterOption={(input, option) =>
                            (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        name="country"
                        // defaultValue={['a10', 'c12']}
                        options={countrylistOptions}
                    />
                </div>
            </div>
        </>
    );
}
