import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'universal-cookie';

//import { Form } from 'react-router-dom';
import axios from 'axios';
import { commonConstant } from '../CommonElements/constants';
import { setAlphaNumeric, showErrorNotification, showInfoNotification, showSuccessNotification, toCamelCase } from '../CommonElements/commonFunctions';
let error = '';
// let show =  false;
//const [errorMessage, setErrorMessage] = useState('');

const Login = (props) => {
  let [show, setShow] = useState(false);
  let [showResend, setShowResend] = useState(false);
  let [agentCode, setAgentCode] = useState('');
  let [otp, setOtp] = useState('');
  let [timer, setTimer] = useState('10:00s');
  const ref = useRef(null);

  const sendOtp = (e) => {
    e.preventDefault();
    setShowResend(false);
    if (!agentCode) {
      error = `Agent Code cannot be empty.`;
      showErrorNotification(error);
      return false;
    }
    ref.current.disabled = true;
    try {
      axios
        .post(commonConstant.BACKEND_API_URL + 'partners/login/request', {
          agentCode: agentCode
        })
        .then((response) => {
          console.log("response", response.data);
          ref.current.disabled = false;
          if (response.data.responseCode === 200) {
            setShow(true);
            showInfoNotification('The OTP has been sent successfully to both your registered email and mobile number');
            let timer = "10";
            let interval = setInterval(function () {
              //by parsing integer, I avoid all extra string processing
              let seconds = parseInt(timer);
              --seconds;
              if (seconds < 0) {
                clearInterval(interval)
              };
              if (seconds !== -1) {
                setTimer(`00:0${seconds}s`)
              } else {
                setShowResend(true)
                setTimer('10:00s')
              }
              timer = seconds;
            }, 1000);
          } else {
            showErrorNotification(response.data.responseMessage);
          }
        });
    } catch (e) {
      ref.current.disabled = false;
      console.log(e);
    }
  }

  const setCookie = (value, keyName) => {
    const cookies = new Cookies();
    // console.log("values", value, keyName);
    cookies.set(keyName, value, { path: '/' });
    // console.log(keyName, cookies.get(keyName));
  }
  const setLocalStorage = (keyname, value) => {
    localStorage.setItem(keyname, value);
    // console.log(keyname, localStorage.getItem(keyname));
  }

  const getStarted = (e) => {
    e.preventDefault();
    if (!agentCode || !otp) {
      error = `Agent Code & OTP cannot be empty.`;
      showErrorNotification(error);
      return false;
    }
    ref.current.disabled = true;
    try {
      axios
        .post(commonConstant.BACKEND_API_URL + 'partners/login/verify', {
          agentCode: agentCode,
          otp
        })
        .then((response) => {
          ref.current.disabled = false;
          // console.log("response", JSON.stringify(response));
          if (response.data.responseCode === 200) {
            // console.log("responsedata", response.data.responseData.countryListToken);
            //alert("Your token = " +  response.data.responseData.token);
            showSuccessNotification('Verified!!')
            setCookie(response.data.responseData.token, 'access_token');
            commonConstant['TOKENS'].map(item => {
              if (response.data.responseData[toCamelCase(item)]) {
                setLocalStorage(item, response.data.responseData[toCamelCase(item)]);
              }else {
                localStorage.removeItem(item);
              }
            })
            //redirected to dashboard
            window.location.href = "/";
          } else {
            showErrorNotification(response.data.responseMessage);
            setOtp('')
          }
        });
    } catch (e) {
      ref.current.disabled = false;
      console.log(e);
    }
  }
  return (
    <div className='container-fluid'>
      <div className="row login-page">
        <div className="col-md-6 leftimage">
          <a href={commonConstant.REMITX_URL}><img alt="" className="logologin" src="https://www.remitx.com/remitx-images/logologin.png" /></a>
          <img alt="" className="img-fluid" src="https://remitxb2c.s3.ap-south-1.amazonaws.com/1682406891882-bg-login.png" />
        </div>
        <div className="col-md-6">
          <div className="boxlogin">
            <a className="mobilelogin" href="index">
            {
                  commonConstant.AGENT_CODES_FOR_LOGOS.includes(agentCode) ? (
                    <img
                      src={`/${agentCode}.png`}
                      alt="Logo"
                      title=""
                      style={{ width : 'auto'}}
                    />
              ) : <img src="https://www.remitx.com/remitx-images/remitx.svg" alt="Logo" title="" />
            }
            </a>
            <h1 className="login_heading">Login to Partner Portal</h1>
            <h1 className="signup_heading hide">SignUp to RemitX</h1>
            <h1 className="forgot_password_heading hide">Recover Password</h1>
            <div>
              <div className="login">
                <form onSubmit={(!show) ? sendOtp : getStarted}>
                  <div className="posre email_box">
                    <label htmlFor="AgentID" className="form-label">Partner Code</label>
                    <span className=" icon email"></span>
                    <input type="text" value={agentCode} className="form-control" onChange={(event) => { setAgentCode(event.target.value); }}  placeholder="Please Enter the Partner Code" />
                    {/* <label for="AgentID" className="form-label">Enter Otp</label> */}
                    {/* <input value={otp} type="text" className="otp form-control" id="OTP" onChange={(event) => { setOtp(event.target.value); }} placeholder="Enter OTP" /> */}
                  </div>
                  {
                    show &&
                    <div className='posre mrg-t15'>
                      <label htmlFor="OTP" className="form-label">OTP</label>
                      <span className=" icon password"></span>
                      <input value={otp} type="text" className="otp form-control" id="OTP" onChange={(event) => { setOtp(event.target.value); }} placeholder="Enter OTP" />
                      {
                        !showResend &&
                        <p style={{ 'float': 'right', 'marginBottom': '0px' }}>{timer}</p>
                      }
                      {
                        showResend &&
                        <div className="resend_otp float-end">
                          <span style={{ color: 'green', 'fontSize': '12px' }} className="hide" id="successOtp">OTP sent successfully </span>
                          <a href='javascript:void(0)' onClick={sendOtp}>Resend OTP</a>
                        </div>
                      }
                    </div>
                  }
                  <button ref={ref} type="submit" className="mt-3 btn btn-primary send_otp">{(!show) ? 'Send OTP' : 'Get Started'}</button>
                </form>
                <div className="mb-3 posre enter_otp_box hide">
                  <label className="form-label">Enter OTP</label>
                  <span className="icon password"></span>
                  <input className="password form-control" placeholder="Enter OTP" />
                  <div className="resend_otp float-end">
                    <span style={{ color: 'green', 'fontSize': '12px' }} className="hide" id="successOtp">OTP sent successfully </span>
                    <a href='javascript:void(0)'>Resend OTP</a>
                  </div>
                </div>
                <button type="button" className="btn btn-primary enter_login hide" id="login">Login</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Login;