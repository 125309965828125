import React from 'react';

const AdminProducts = () => {
  return (
    <div>
      <h2>Product Management</h2>
      {/* Add your product management content here */}
    </div>
  );
};

export default AdminProducts;
