import React, { useEffect, useRef } from "react";
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Tab, Tabs } from "react-bootstrap";
import { DateRangePicker } from "rsuite";
import { commonConstant } from "../../CommonElements/constants";
import axios from "axios";
import * as XLSX from 'xlsx';
import { getDate, getFormattedDate, getLocalDate, headers, setAlpha, setAlphaNumeric, setEmailValidation, setNumeric, showErrorNotification, showSuccessNotification, sortTheData } from "../../CommonElements/commonFunctions";
import { Pagination } from "../../Components/Pagination/Pagination";
import SortArrow from "../../Components/SortArrow/SortArrow";


const statusDesignMap = {
    'Open': 'openlead',
    'Followup': 'followuplead',
    'Assigned': 'assignedlead',
    'In progress': 'inprogress',
    'Converted': 'convertedlead',
    'Closed': 'closedlead'
}

const timeOption = {
    timeZone: 'Asia/Kolkata',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
};

const alphaReg = /^[a-zA-Z ]+$/
const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const mobileNumberReg = /^[0-9]{10}$/;
const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;

const leadStatus = ['Open', 'Followup', 'In progress', 'Assigned', 'Converted', 'Closed']

function LeadJunctionList({ AgentCode, AgentId, agentType, allowedModules }) {

    const [show, setShow] = useState(false);
    const handleClose = () => { setIsEditMode(false); setSelectedLead(null); setShow(false) };
    const handleShow = () => setShow(true);
    const [smShow, setSmShow] = useState(false);
    const handleCloseSm = () => setSmShow(false);
    const handleShowSm = () => setSmShow(true);
    const [subagents, setSubagents] = useState([]);
    const [totalLeads, setTotalLeads] = useState(0);
    const [leads, setLeads] = useState([]);
    const [selectedLead, setSelectedLead] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedTab, setSelectedTab] = useState('todayWork');
    const [leadFile, setLeadFile] = useState(null);

    const leadInfoRef = useRef(null);
    const statusRef = useRef(null);
    const createdByRef = useRef(null);
    const dateRangeRef = useRef(null)

    // Filter variables
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [leadInfo, setLeadInfo] = useState("");
    const [status, setStatus] = useState("");
    const [createdby, setCreatedBy] = useState("");
    const [dateRangeValue, setDateRangeValue] = useState("")
    // Pagination Stuff
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0)
    //loader
    const [showloader, setShowLoader] = useState(false);
    //Sorting
    const [sortData, setSortData] = useState('up created');

    const fetchSubAgents = async () => {

        try {
            let data = await axios.get(`${commonConstant.BACKEND_API_URL}partners/user/subagent`, { headers: headers() })
            setSubagents(data.data.responseData.rows);
        } catch (e) {
            console.log("Error in fetching subagents")
            showErrorNotification(commonConstant.SERVER_ERROR)
        }

    }

    const fetchLeads = async (returnData = false) => {
        try {

            let reqBody = {
                agentCode: AgentCode,
                agentId: AgentId,
                lead_info: leadInfo,
                startDate: startDate,
                endDate: endDate,
                created_by: createdby,
                status: status,
                sortOrder: sortData === 'up created' ? 'desc' : 'asc'
            }

            setShowLoader(true);
            // Tab is My TOday Work
            if (selectedTab === 'todayWork')
                reqBody.reminder_date = getDate();

            let data = await axios.post(`${commonConstant.BACKEND_API_URL}partners/lead-junction/get` + (!returnData ? `?page=${currentPage}` : ``), reqBody)

            let total = data.data?.responseData?.total
            let leads = data.data?.responseData?.leads

            total = total * 1;

            if (!returnData) {
                setTotalPages(Math.floor(total / 11) + 1);
                setLeads(leads);
                setTotalLeads(total)
            } else
                return leads;

        } catch (e) {
            showErrorNotification(commonConstant.SERVER_ERROR)
        } finally {
            setShowLoader(false)
        }

    }

    useEffect(() => {
        fetchSubAgents();
    }, [])
    useEffect(() => {
        setCurrentPage(1)
    }, [selectedTab])

    useEffect(() => {
        fetchLeads();
    }, [AgentCode, AgentId, selectedTab, currentPage, sortData])


    const handleDateFilter = async (e) => {
        e ? setStartDate(getFormattedDate(e[0])) : setStartDate("");
        e ? setEndDate(getFormattedDate(e[1])) : setEndDate("");

        setDateRangeValue(e)
    }

    const handleFilterChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        // console.log(name, value)
        switch (name) {

            case 'status':
                setStatus(value);
                break;

            case 'leadInfo':
                setLeadInfo(value);
                break;

            case 'createdby':
                setCreatedBy(value);
                break;

        }
    }


    const handleSearch = async () => {
        setCurrentPage(1)
        fetchLeads();
    }


    const handleShowEdit = async (lead) => {
        handleShow()
        setIsEditMode(true)
        setSelectedLead({
            ...lead,
            reminder_date: getLocalDate(lead.reminder_date, 'YYYY-MM-DD')
        })
    }

    const handleInputChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'reminder_date')
            value = getLocalDate(value, 'YYYY-MM-DD')
        // console.log(name, value)
        setSelectedLead(() => {
            return {

                ...selectedLead,
                [name]: value
            }
        })
    }

    const validateLeadData = () => {

        let errMessage = "";
        let count = 0;

        const dateToCheck = new Date(selectedLead.reminder_date);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        dateToCheck.setHours(0, 0, 0, 0);

        if (!selectedLead.name) {
            errMessage = "Please enter the Name.";
            count++;
        }

        if (dateToCheck < currentDate) {
            errMessage = "Kindly check the date again, you have set a past date."
            count++;
        }

        if (!selectedLead.mobile) {
            errMessage = "Please share the mobile number.";
            count++
        }

        if (selectedLead.mobile.length < 10) {
            errMessage = "Please enter the correct mobile number";
            count++
        }

        if (!selectedLead.status) {
            errMessage = "Please select the status.";
            count++;
        }
        if (!selectedLead.reminder_date) {
            errMessage = "Please share the Reminder Date.";
            count++;
        }
        if (!selectedLead.remark) {

            errMessage = "Please share the required remarks";
            count++
        }

        if (errMessage) {
            if (count == 1)
                showErrorNotification(errMessage);
            else showErrorNotification('Please share the mandatory details')
            return false;
        }
        return true;
    }

    const handleEditCreate = async () => {
        selectedLead.created_by = AgentCode;
        if (!validateLeadData())
            return;

        try {

            // If Create
            if (!isEditMode) {
               
                let result = await axios.post(`${commonConstant.BACKEND_API_URL}partners/lead-junction`, selectedLead)
                if (result.data.responseCode != 200) {
                    showErrorNotification(commonConstant.SERVER_ERROR);
                    return;
                }
                showSuccessNotification("Lead Created Successfully");

                fetchLeads();
                handleClose();


            } else {
                 //Edit Mode
                let result = await axios.put(`${commonConstant.BACKEND_API_URL}partners/lead-junction/${selectedLead.id}`, selectedLead)
                if (result.data.responseCode != 200) {
                    showErrorNotification(commonConstant.SERVER_ERROR);
                    return;
                }
                showSuccessNotification('Lead Updated Successfully');

                fetchLeads();
                handleClose();
            }
        } catch (e) {
            showErrorNotification(commonConstant.SERVER_ERROR)
        }

    }

    const handleTabChange = async (tabName) => {
        handleReset();
        setSelectedTab(tabName)
    }

    const convertToLocalTime = (date) => {
        let datelocalstr = new Date(date).toLocaleString('en-IN', timeOption)
        return datelocalstr.replaceAll('/', '-')
    }

    const handleDownloadSample = () => {
        try {
            // let data = [];
            // let colheader = ['Name', 'Email', 'Mobile No.', 'Parent Name', 'Parent Mobile', 'Status', 'Reminder Date(DD-MM-YYYY or DD/MM/YYYY)', 'Remark'];
            // data.push(colheader);
            // data.push(['Sam', 'arpitgoyal442@gmail.com', '9996261211', 'John lee', '9996545211', 'Converted', '03-06-2024', 'This lead is converted']);
            // const workbook = XLSX.utils.book_new();

            // const worksheet = XLSX.utils.aoa_to_sheet(data);

            // XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            // XLSX.writeFile(workbook, `Sample.xlsx`);

            let url = process.env.REACT_APP_LEADJUNCTION_SAMPLE;
            // console.log("URL ",url)
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `Sample.xlsx`,
            );
            // Append to html link element page
            document.body.appendChild(link); // Move this line to the correct position
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);


            showSuccessNotification("Sample Excel downloaded!")
        } catch (e) {
            console.log(e);
            showErrorNotification("Some error occured.Please try again later.")
        }
    }
    const handleDownloadExcel = async () => {

        let leads = await fetchLeads(true);
        let data = [];
        let colheader;
        colheader = ['Name', 'Email', 'Mobile No.', 'Parent Name', 'Parent Mobile', 'Status', 'Reminder Date', 'Remark'];
        data.push(colheader);
        leads.map((lead) => {
            let row = [];
            row.push(lead.name);
            row.push(lead.email);
            row.push(lead.mobile);
            row.push(lead.parent_name);
            row.push(lead.parent_mobile);
            row.push(lead.status);
            row.push(getLocalDate(lead.reminder_date, 'DD-MM-YYYY'));
            row.push(lead.remark);
            data.push(row)
        })
        const workbook = XLSX.utils.book_new();
        // Create a new worksheet and add the data
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        // Generate a blob from the workbook
        XLSX.writeFile(workbook, `leads.xlsx`);
    }


    const handleFileUpload = async (e) => {
        setLeadFile(e.target.files[0])
    }

    const validateExcelData = (data, actData) => {
        data[0].push('created_by')
        actData.push([...data[0]])
        // ['Name', 'Email', 'Mobile No.', 'Parent Name', 'Parent Mobile', 'Status', 'Reminder Date', 'Remark']
        for (let i = 1; i < data.length; i++) {     
            let name = data[i][0] || "";
            let email = data[i][1] || "";
            let mobile = data[i][2] || "";
            let parent_name = data[i][3] || "";
            let parent_mobile = data[i][4] || "";
            let status = data[i][5] || "";
            let reminder_date = data[i][6] || "";
            let remark = data[i][7] || "";
            if (!name && !email && !mobile && !parent_name && !parent_mobile && !status && !reminder_date && !remark)
                break;

            let parts = reminder_date.split("-");
            // Changing the Data Format to YYYY-MM-DD
            reminder_date = parts[2] + "-" + parts[1] + "-" + parts[0];
            data[i].push(AgentCode)
            

            data[i][6] = reminder_date
            if (!alphaReg.test(name) || !mobileNumberReg.test(mobile) || !alphaReg.test(status) ||
                !leadStatus.includes(status) || (email && !emailReg.test(email)) || (parent_name && !alphaReg.test(parent_name))
                || (parent_mobile && !mobileNumberReg.test(parent_mobile))
                || !dateRegex.test(reminder_date) || !alphaReg.test(remark)) {
                showErrorNotification('Please share the mandatory details required for the upload. Please download a Sample and reuse it');
                return false;
            }
            
            
            actData.push([...data[i]])
        }
        return true
    }

    const handleUploadExcel = async () => {

        try {
            let file = leadFile
            if (file) {

                setShowLoader(true);
                const reader = new FileReader();
                reader.onload = async (e) => {
                    const data = e.target.result

                    const workbook = XLSX.read(data, { type: 'binary' });
                    // Assuming you are working with a single sheet, you can get the data like this:
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false });
                    let actData = [];
                    // Do something with the imported data (jsonData)
                    if (!validateExcelData(jsonData, actData)) {
                        return;
                    }

                    else {

                        let result = await axios.post(`${commonConstant.BACKEND_API_URL}partners/lead-junction/multiple`, actData)
                        showSuccessNotification('Successfully uploaded the Excel file.');
                        fetchLeads();
                        handleCloseSm();

                    }

                };
                reader.readAsArrayBuffer(file);

            }
        } catch (e) {
            console.log(e)
            showErrorNotification(commonConstant.SERVER_ERROR)
        } finally {
            setShowLoader(false)
        }
    }

    const handleReset = async () => {
        leadInfoRef.current.value = ''
        createdByRef.current.value = ''
        statusRef.current.value = ''

        setDateRangeValue('')
        setLeadInfo('');
        setStatus('');
        setCreatedBy('');

    }

    useEffect(() => {
        if (!leadInfo && !status && !createdby)
            fetchLeads();
    }, [leadInfo, status, createdby])



    return (
        <>
            {showloader && <div className="loadenv" id="middleLoader">
                <div className="loader text-center">
                    <img
                        src={
                            commonConstant.REMITX_URL +
                            "remitx-images/loader.gif"
                        }
                    />
                </div>
            </div>}
            <div className="page-body-fx container mrg-t30" id="Leadjunction">
                <div className="row">
                    <div className="col">
                        <h3>Search By</h3>
                        <div className="row">
                            {selectedTab !== 'todayWork' && <div className="col-lg-2 col-md-3 col-sm-6">
                                <div className="mb-3">
                                    <label
                                        className="form-label"
                                    >
                                        Date

                                    </label>
                                    <DateRangePicker
                                        ref={dateRangeRef}

                                        value={dateRangeValue}

                                        onChange={handleDateFilter}
                                        placeholder={"Select a date range"}
                                        format="yyyy-MM-dd"
                                    />
                                </div>
                            </div>}
                            <div className="col-lg-2 col-md-3 col-sm-6">
                                <div className="mb-3">
                                    <label
                                        htmlFor="product"
                                        className="form-label"
                                    >
                                        Lead Info
                                    </label>
                                    <input
                                        ref={leadInfoRef}
                                        name="leadInfo"
                                        onChange={(e) => handleFilterChange(e)}
                                        id="searchKeyword"
                                        className="form-control"
                                        type="text"
                                        placeholder="Please enter"
                                    />
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-2 col-sm-6">
                                <div className="mb-3">
                                    <label
                                        htmlFor="product"
                                        className="form-label"
                                    >
                                        Status
                                    </label>
                                    <select
                                        ref={statusRef}
                                        name="status"
                                        className="form-select"
                                        onChange={(e) => handleFilterChange(e)}
                                    >

                                        <option value="">Select Status</option>
                                        <option value="Open">Open</option>
                                        <option value="Followup">Followup</option>
                                        <option value="In progress">In progress</option>
                                        <option value="Assigned">Assigned</option>
                                        <option value="Converted">Converted</option>
                                        <option value="Closed">Closed</option>

                                    </select>
                                </div>
                            </div>
                            {agentType !== 'subagent' && <div className="col-lg-2 col-md-3 col-sm-6">
                                <div className="mb-3">
                                    <label
                                        htmlFor="product"
                                        className="form-label"
                                    >
                                        Created by
                                    </label>
                                    <select
                                        ref={createdByRef}
                                        name="createdby"
                                        onChange={(e) => handleFilterChange(e)}
                                        className="form-select"
                                    >
                                        <option value="">Select Subagent</option>
                                        {subagents.map((subagent) => {

                                            return <option value={subagent.code}>{subagent.name}({subagent.code})</option>


                                        })}


                                    </select>
                                </div>
                            </div>}

                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <button
                                    onClick={handleSearch}
                                    type="button"
                                    className="btn btn-primary mrg-t30 mrg-R10"
                                >
                                    Search
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary mrg-t30"
                                    onClick={handleReset}
                                >
                                    Reset
                                </button>

                            </div>

                            {selectedTab === 'leadJunction' && <div className="col-lg-2 col-md-3 col-sm-6 text-right lj-creat">
                                <button
                                    type="button"
                                    className="btn btn-primary mrg-t30"
                                    onClick={handleShow}
                                >
                                    Create Lead
                                </button>
                            </div>}
                        </div>
                    </div>
                    <div className="col-md-12 mrg-t30">
                        <div className="download-buttons">
                            <a className="dashed-buttons" onClick={handleDownloadExcel} ><img src="https://remitxb2c.s3.ap-south-1.amazonaws.com/1709123391609-download.png" /> Download Report</a>
                            {selectedTab === 'leadJunction' && <a onClick={handleShowSm} className="dashed-buttons"><img src="https://remitxb2c.s3.ap-south-1.amazonaws.com/1709124106578-upload.png" /> Upload Leads</a>}
                        </div>
                        <Tabs
                            defaultActiveKey="todayWork"
                            id="uncontrolled-tab-example"
                            onSelect={(e) => handleTabChange(e)}

                        >
                            <Tab eventKey="todayWork" title="My Today's Work">
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th width="5%" scope="col">#</th>
                                                <th width="20%" scope="col">Lead Info</th>
                                                <th width="10%" scope="col">Lead Status</th>
                                                <th width="15%" scope="col">Create by</th>
                                                <th width="20%" scope="col">Remarks</th>
                                                <th width="15%" scope="col">Reminder Date</th>
                                                <th width="10%" scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody id="leadListingTbody">
                                            {leads?.map((lead, index) => {

                                                return <tr>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <div className="text-bold">{lead.name}</div>
                                                        <div>{lead.email}</div>
                                                        <div>{lead.mobile}</div>
                                                    </td>
                                                    <td><div className={statusDesignMap[lead.status]}>{lead.status}</div></td>
                                                    <td>{AgentCode == lead.created_by ? `Self` : lead.agentname - lead.created_by}</td>
                                                    <td>{lead.remark}</td>
                                                    <td>{getLocalDate(lead.reminder_date, 'DD-MM-YYYY')}</td>
                                                    <td><a onClick={() => handleShowEdit(lead)} ><img width="32" height="32" src="https://remitxb2c.s3.ap-south-1.amazonaws.com/1709113817738-edit-button.png" /></a></td>
                                                </tr>

                                            })}

                                            {
                                                leads.length == 0 && <tr><td colspan="8" className="text-center">No Data Found</td></tr>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </Tab>
                            <Tab eventKey="leadJunction" title="Lead Junction">
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th width="5%" scope="col">#</th>
                                                <th width="10%" scope="col"> Date
                                                    <SortArrow
                                                        sortData={sortData}
                                                        columnName="created"
                                                        setSortData={setSortData}
                                                    />
                                                </th>
                                                <th width="20%" scope="col">Lead Info</th>
                                                <th width="10%" scope="col">Lead Status</th>
                                                <th width="10%" scope="col">Create by</th>
                                                <th width="25%" scope="col">Remarks</th>
                                                <th width="15%" scope="col">Reminder Date</th>
                                                <th width="5%" scope="col">Action</th>                                           </tr>
                                        </thead>
                                        <tbody id="leadListingTbody">

                                            {leads?.map((lead, index) => {

                                                return <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{convertToLocalTime(lead.created)}</td>
                                                    <td>
                                                        <div className="text-bold">{lead.name}</div>
                                                        <div>{lead.email}</div>
                                                        <div>{lead.mobile}</div>
                                                    </td>
                                                    <td><div className={statusDesignMap[lead.status]}>{lead.status}</div></td>
                                                    <td> {AgentCode == lead.created_by ? `Self` : `${lead.agentname}-${lead.created_by}`} </td>
                                                    <td>{lead.remark}</td>
                                                    <td>{getLocalDate(lead.reminder_date, 'DD-MM-YYYY')}</td>
                                                    <td><a onClick={() => handleShowEdit(lead)}><img width="32" height="32" src="https://remitxb2c.s3.ap-south-1.amazonaws.com/1709113817738-edit-button.png" /></a></td>
                                                </tr>

                                            })}

                                            {
                                                leads.length == 0 && <tr><td colspan="8" className="text-center">No Data Found</td></tr>
                                            }


                                        </tbody>
                                    </table>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
            <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                className="modal-custom"
            >
                <Modal.Header>
                    <Modal.Title>{isEditMode ? 'Update Lead' : 'Create Lead'}</Modal.Title>
                    <div className="modalclose">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleClose} aria-label="Close"></button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label>Name<span className="red">*</span></label>
                                <input onKeyDown={setAlpha} onChange={(e) => handleInputChange(e)} name="name" value={selectedLead ? selectedLead.name : ''} className="form-control" type="text" placeholder="Please enter" />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Mobile Number<span className="red">*</span></label>
                                <input type="text" onKeyDown={setNumeric} maxLength={10} onChange={(e) => handleInputChange(e)} name="mobile" value={selectedLead ? selectedLead.mobile : ''} className="form-control" placeholder="Please enter" />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Email ID</label>
                                <input onKeyDown={setEmailValidation} onChange={(e) => handleInputChange(e)} name="email" value={selectedLead ? selectedLead.email : ''} className="form-control" type="text" placeholder="Please enter" />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Parent Name</label>
                                <input onKeyDown={setAlpha} onChange={(e) => handleInputChange(e)} name="parent_name" value={selectedLead ? selectedLead.parent_name : ''} className="form-control" placeholder="Please enter" />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Parent Mobile Number</label>
                                <input type="text" onKeyDown={setNumeric} maxLength={10} onChange={(e) => handleInputChange(e)} name="parent_mobile" value={selectedLead ? selectedLead.parent_mobile : ''} className="form-control" placeholder="Please enter" />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Status<span className="red">*</span></label>
                                <select onChange={(e) => handleInputChange(e)} name="status" value={selectedLead ? selectedLead.status : ''} className="form-select">
                                    <option value="">Select Status</option>
                                    <option value="Open">Open</option>
                                    <option value="Followup">Followup</option>
                                    <option value="In progress">In progress</option>
                                    <option value="Assigned">Assigned</option>
                                    <option value="Converted">Converted</option>
                                    <option value="Closed">Closed</option>
                                </select>
                            </div>
                            {/* getLocalDate(selectedLead?.reminder_date,'YYYY-MM-DD') */}
                            <div className="col-md-6 mb-3">
                                <label>Reminder Date <span className="red">*</span></label>
                                <input onChange={(e) => handleInputChange(e)} name="reminder_date" value={selectedLead ? getLocalDate(selectedLead?.reminder_date, 'YYYY-MM-DD') : ''} className="form-control" type="date" placeholder="Please enter" />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Remarks<span className="red">*</span></label>
                                <input maxLength={250} onChange={(e) => handleInputChange(e)} name="remark" value={selectedLead ? selectedLead.remark : ''} className="form-control" type="text" placeholder="Please enter" />
                            </div>
                            <div className="col-md-12 text-right mrg-t10">
                                <button


                                    type="button"
                                    className="btn btn-outline-primary mrg-r20"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleEditCreate}
                                    type="button"
                                    className="btn btn-primary"
                                >
                                    {isEditMode ? 'Update Lead' : 'Create Lead'}
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
            <Modal
                size="sm"
                show={smShow}
                aria-labelledby="example-modal-sizes-title-sm"
                className="modal-custom"
                onHide={handleCloseSm}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Upload Excel</Modal.Title>
                    <div className="modalclose">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleCloseSm} aria-label="Close"></button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12 mb-3">
                        <label>Upload Excel<span className="red">*</span></label>
                        <input onChange={handleFileUpload} className="form-control" type="file" placeholder="Please enter" />
                        <div onClick={handleDownloadSample} className="text-right font-14 mrg-t10"><a href="#"><img src="https://remitxb2c.s3.ap-south-1.amazonaws.com/1709123391609-download.png" /> Download Excel Sample</a></div>
                    </div>
                    <div className="col-md-12 full-wbutton mrg-t20">
                        <div className="row">
                            <div className="col-md-6 col-6">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary mrg-r20"
                                    onClick={handleCloseSm}
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className="col-sm-6 col-6">
                                <button
                                    onClick={handleUploadExcel}
                                    type="button"
                                    className="btn btn-primary"
                                >
                                    Upload Excel
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}


export default LeadJunctionList;
