import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import { commonConstant } from "../CommonElements/constants";
import CalenderBar from "../Components/Dashboard/CalenderBar";
import Loader from "../Components/Loader/Loader";
import ShareWithRemitx from "../Components/Share/ShareWithRemitx";
import ShareWithUser from "../Components/Share/ShareWithUser";
import { getEarningsNumbers, getLeads } from "../services/Service";

function Dashboard({ agentType, allowedModules }) {
  // console.log(agentType,"..........");
  const [leadsData, setLeadsData] = useState([]);
  const [ordersData, setOrderData] = useState([]);
  const [earningData, setEarningData] = useState([]);
  const [performersData, setPerformersData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showShareButton, setShowShareButton] = useState("");
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    getLeads({ startDate: null, endDate: null })
      .then((res) => {
        if (res.responseCode == 200) {
          console.log(res.responseData);
          setLeadsData(res.responseData.leadStats);
          setOrderData(res.responseData.orderStats);
          const myData = res.responseData.performerData.sort((a, b) =>
            a.amount * 1 > b.amount * 1
              ? -1
              : a.amount * 1 === b.amount * 1
              ? 0
              : 1
          );
          setPerformersData(myData);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
    getEarningsNumbers({ startDate: "", endDate: "" })
      .then((res) => {
        if (res.responseCode == 200) {
          console.log(res.responseData);
          setEarningData(res.responseData.earningStats);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, []);
  return (
    <>
      <style>
        {`.rs-anim-fade.rs-anim-in {opacity: 1;pointer-events: unset;}`}
      </style>
      <div className="page-body-fx container">
        <div className="loadenv hide" id="middleLoader">
          <div className="loader text-center">
            <img src={commonConstant.REMITX_URL + "remitx-images/loader.gif"} />
          </div>
        </div>
        <div className="row mrg-t30">
          <Container>
            <div className="row">
              <div className="col-md-4">
                <CalenderBar
                  setEarningData={setEarningData}
                  setLeadsData={setLeadsData}
                  setOrderData={setOrderData}
                  setIsModalOpen={setIsModalOpen}
                />
              </div>
              <div className="col-md-8">
                <div className="buttons__group">
                  {allowedModules.ShareUser && 
                      <button
                        className="shareButton btn btn-outline-primary mrg-R15"
                        onClick={() => {
                          openModal();
                          setShowShareButton("user");
                        }}
                      >
                        Share With Student
                      </button>
                  }
                  {allowedModules.ShareRemitx && 
                      <button
                        className="shareButton btn btn-outline-primary"
                        onClick={() => {
                          openModal();
                          setShowShareButton("remitx");
                        }}
                      >
                        Share With Remitx
                      </button>
                  }
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className="row">
          {agentType === "agent" && allowedModules.EarningReport && (
            <YourEarning earningData={earningData} />
          )}

          {allowedModules.LeadReport && <Leads leadsData={leadsData} />}
          {allowedModules.OrderReport && <YourOrders ordersData={ordersData} />}
          {agentType === "agent" && performersData.length > 0 && (
            <TopPerformers performersData={performersData} />
          )}
        </div>
        { allowedModules.ShareUser && 
          <Modal
            isOpen={isModalOpen && showShareButton === "user"}
            className="planModal"
            onRequestClose={closeModal}
          >
            <ShareWithUser closeModal={closeModal} />
          </Modal>
        }
        {
          allowedModules.ShareRemitx && 
          <Modal
            isOpen={isModalOpen && showShareButton === "remitx"}
            className="planModal modal-md"
            onRequestClose={closeModal}
          >
            {/* allowEmpty -> fx_amount and email are allowed to be empty */}
            <ShareWithRemitx  allowEmpty={true}  closeModal={closeModal} />  
          </Modal>
        }
      </div>
    </>
  );
}

function YourEarning({ earningData: earningData }) {
  return (
    <div className="col-md-12 mrgb-4">
      <h3>Your Earnings</h3>
      <div className="row">
        <div className="col-md-3 col-6">
          <a href="EarningReport">
            <div className="card dashboard-remitx">
              <div className="card-body">
                <div className="box">
                  <div className="left">
                    <div className="common-h">Total Revenue</div>
                    <div className="amount">
                      {earningData.totalRevenue || "0"}
                    </div>
                  </div>
                  <div className="right remitxcolor">
                    {!earningData.totalRevenue && <Loader />}
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-md-3 col-6">
          <a href="EarningReport">
            <div className="card dashboard-remitx">
              <div className="card-body">
                <div className="box">
                  <div className="left">
                    <div className="common-h">Revenue to be claim</div>
                    <div className="amount">
                      {earningData.RevenueToBeClaimed || ""}
                    </div>
                  </div>
                  <div className="right remitxgreen">
                    {!earningData.totalRevenue && <Loader />}
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-md-3 col-6">
          <a href="EarningReport">
            <div className="card dashboard-remitx">
              <div className="card-body">
                <div className="box">
                  <div className="left">
                    <div className="common-h">Revenue Settled</div>
                    <div className="amount">
                      {earningData.RevenueSettled || ""}
                    </div>
                  </div>
                  <div className="right remitxblue">
                    {!earningData.totalRevenue && <Loader />}
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-md-3 col-6">
          <a href="EarningReport">
            <div className="card dashboard-remitx">
              <div className="card-body">
                <div className="box">
                  <div className="left">
                    <div className="common-h">Forecast Revenue</div>
                    <div className="amount">
                      {earningData.ForecastRevenue || ""}
                    </div>
                  </div>
                  <div className="right remitxyellow">
                    {!earningData.totalRevenue && <Loader />}
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

function Leads({ leadsData: leadsData }) {
  return (
    <div className="col-md-12 mrgb-4">
      <h3>Leads</h3>
      <div className="row">
        <div className="col-md-3 col-6">
          <a href="LeadReport">
            <div className="card dashboard-remitx">
              <div className="card-body">
                <div className="box">
                  <div className="left">
                    <div className="common-h">Total Leads</div>
                  </div>
                  <div className="right remitxcolor">
                    {leadsData.totalLeads}
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-md-3 col-6">
          <a href="LeadReport">
            <div className="card dashboard-remitx">
              <div className="card-body">
                <div className="box">
                  <div className="left">
                    <div className="common-h">
                      {commonConstant.QUOTSTATUS[3]} Leads
                    </div>
                  </div>
                  <div className="right remitxgreen">{leadsData.openLeads}</div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-md-3 col-6">
          <a href="LeadReport">
            <div className="card dashboard-remitx">
              <div className="card-body">
                <div className="box">
                  <div className="left">
                    <div className="common-h">
                      {commonConstant.QUOTSTATUS[0]} Leads
                    </div>
                  </div>
                  <div className="right remitxblue">
                    {leadsData.progressLeads}
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-md-3 col-6">
          <a href="LeadReport">
            <div className="card dashboard-remitx">
              <div className="card-body">
                <div className="box">
                  <div className="left">
                    <div className="common-h">Closed Leads</div>
                  </div>
                  <div className="right remitxred">{leadsData.closedLeads}</div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

function YourOrders({ ordersData: ordersData }) {
  return (
    <div className="col-md-12 mrgb-4">
      <h3>Orders</h3>
      <div className="row">
        <div className="col-md-3 col-6">
          <a href="OrderReport">
            <div className="card dashboard-remitx">
              <div className="card-body">
                <div className="box">
                  <div className="left">
                    <div className="common-h">Total Orders</div>
                  </div>
                  <div className="right remitxcolor">{ordersData.total}</div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-md-3 col-6">
          <a href="OrderReport">
            <div className="card dashboard-remitx">
              <div className="card-body">
                <div className="box">
                  <div className="left">
                    <div className="common-h">
                      {commonConstant.ORDERSTATUS[1]} Orders
                    </div>
                  </div>
                  <div className="right remitxgreen">
                    {ordersData.orderinprogress}
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-md-3 col-6">
          <a href="OrderReport">
            <div className="card dashboard-remitx">
              <div className="card-body">
                <div className="box">
                  <div className="left">
                    <div className="common-h">
                      {commonConstant.ORDERSTATUS[2]} Orders
                    </div>
                  </div>
                  <div className="right remitxblue">
                    {ordersData.processedorder}
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-md-3 col-6">
          <a href="OrderReport">
            <div className="card dashboard-remitx">
              <div className="card-body">
                <div className="box">
                  <div className="left">
                    <div className="common-h">
                      {commonConstant.ORDERSTATUS[0]} Orders
                    </div>
                  </div>
                  <div className="right remitxyellow">
                    {ordersData.cancelledorder}
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

function TopPerformers({ performersData: performersData }) {
  return (
    <div className="col-md-12 mrgb-4 top-performers">
      <h3>Top Performers</h3>
      <div className="row">
        {performersData.map((performerData) => (
          <div className="col-md-3 mb-3">
            <div className="card dashboard-remitx">
              <div className="card-body badge-profile">
                <div className="box">
                  <div className="left">
                    <div className="common-h">{performerData.name}</div>
                    <div className="amount">{performerData.amount}</div>
                  </div>
                  <div className="right remitxcolor">
                    {performerData.totalOrders}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Dashboard;
