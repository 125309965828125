import { getItemValue } from "../../CommonElements/commonFunctions";
import { commonConstant } from "../../CommonElements/constants";

export default function PurposeDropdown({
    filterText,
    setPurpose,
}) {
    //console.log(all_products,"PurposeDropdown all_products",filterText,onFilterTextChange);
    const purposeDropdownChange = (e) => {
        if (!e.target.value) {
            setPurpose("");
            return;
        } else {
            setPurpose(e.target.value);
        }
    };
    let product_purpose = commonConstant.PRODUCT_PURPOSE[filterText];
    if (!product_purpose) {
        return;
    }
    //console.log(product_purpose,"product_purpose");
    let product_purpose_dropdown = product_purpose.map(function (item, i) {
        let item_val = getItemValue(item);
        return (
            <option key={item} value={item}>
                {item_val}
            </option>
        );
    });

    return (
        <>
            <div className="col-md-6">
                <div className="mb-3">
                    <label htmlFor="product" className="form-label">
                        Purpose<span>*</span>
                    </label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={purposeDropdownChange}
                    >
                        <option value="">Select Purpose</option>
                        {product_purpose_dropdown}
                    </select>
                </div>
            </div>
        </>
    );
}
