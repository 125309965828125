import { useEffect, useState } from "react";
import { commonConstant } from "../../CommonElements/constants";


export const Payoffline =({bankId ,remmitanceData,closeModal})=>{

    const[bankInfo,setBankInfo]=useState(null);

    useEffect(()=>{

        if(bankId==3){
            setBankInfo(commonConstant.YESBANK)
        }
        else if(bankId==2)
        setBankInfo(commonConstant.HDFCBANK)

        else setBankInfo(commonConstant.ICICIBANK)
   

    },[])


    return (
        <div className="modalpayment">
        <h6>Choosing to make an offline payment?</h6>
<p className=''>Please refer to the bank account details provided below.</p>
{ Object.keys(remmitanceData).length && (
        <div className="outerbox" >
                    <div className="mainbx" >
          <button
              type="button"
              className="btn-close close"
              aria-label="Close"
              onClick={()=>{closeModal()}}
          ></button>
            <div id="cartbreakup">
              <div className="table-responsive mrg-t20">
                <table className="table" >
                  <tbody><tr>
                    <td className="black">Total Amount</td>
                    <td className="remitx-c">{remmitanceData.total_bill_amount}</td>
                  </tr>
                    <tr>
                      <td className="black">GST on Total Amount</td>
                      <td className="remitx-c">{remmitanceData.gst_fx_value * 1}</td>
                    </tr>
                    <tr>
                      <td className="black">Bank Charges</td>
                      <td className="remitx-c">{remmitanceData.transaction_fee * 1}</td>
                    </tr>
                    <tr>
                      <td className="black">GST on Bank Charges</td>
                      <td className="remitx-c">{remmitanceData.gst_transaction_fee * 1}</td>
                    </tr>
                    <tr>
                      <td className="black">Bill Amount</td>
                      <td className="remitx-c">{remmitanceData.total_bill_amount}</td>
                    </tr>
                    <tr>
                      <td className="black">TCS</td>
                      <td className="remitx-c">{remmitanceData.tcs_value}</td>
                    </tr>
                    <tr>
                      <td className="black">Final Bill Amount</td>
                      <td className="remitx-c bold">{remmitanceData.final_bill_amount}</td>
                    </tr>
                  </tbody>
                  </table>
              </div>
             
            </div>
          </div>
        </div>
        )}



<div className="outerbox">
          <div className="mainbx">
            <div id="cartbreakup">
              <label className="title">Bank Account Details</label>
              <div className="table-responsive">
                <table className="table">
                  <tbody><tr>
                    <td width="50%" className="black">Bank Name</td>
                    <td width="50%" className="remitx-c">{bankInfo?.Name}</td>
                  </tr>
                    <tr>
                      <td className="black">Account Number</td>
                      <td className="remitx-c">{bankInfo?.AccountNumber}</td>
                    </tr>
                    <tr>
                      <td className="black">Bank IFSC</td>
                      <td className="remitx-c">{bankInfo?.IFSCCode}</td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="black text-left">
                        <div className="black mrg-b5">Address:-</div>
                        <div className="remitx-c">{bankInfo?.Address}</div></td>
                    </tr>
                 
                  </tbody>
                  </table>
              </div>
             
            </div>
          </div>
        </div>

        </div>
    )
}