import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import {
    setAlpha,
    setEmailValidation,
    setNumeric,
    shareWithUser,
} from "../../CommonElements/commonFunctions";

/**
 * Share button
 * @returns
 */
export default function ShareWithUser({ closeModal }) {
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [showloader, setShowLoader] = useState(false);
    const buttonClick = async () => {
        let res = await shareWithUser({
            name,
            email,
            mobile,
            closeModal
        });
        setShowLoader(res);
    }
    if (!closeModal) return <></>;
    return (
        <>
            <div className="modal-heading">
                <h3>Share Details</h3>
                <button
                    type="button"
                    className="btn-close close"
                    aria-label="Close"
                    onClick={closeModal}
                ></button>
            </div>
            <div className="col-md-12">
                <form>
                    <div className="row" id="share_container_user">
                        <div className="col-12">
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">
                                    Name <span>*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Name"
                                    name="name"
                                    onKeyDown={setAlpha}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                    value={name}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-3">
                                <label htmlFor="mobile" className="form-label">
                                    Enter Mobile <span>*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    maxLength={10}
                                    onKeyDown={setNumeric}

                                    placeholder="Enter Mobile"
                                    name="mobile"
                                    onChange={(e) => {
                                        setMobile(e.target.value);
                                    }}
                                    value={mobile}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">
                                    Enter Email<span>*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    onKeyDown={setEmailValidation}
                                    placeholder="Enter Email"
                                    name="email"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    value={email}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mrg-t30">
                        {!showloader && (
                            <button
                                type="button"
                                className="btn btn-primary mrg-r20"
                                onClick={() => {
                                    buttonClick();
                                    setShowLoader(true);
                                }}
                            >
                                Share
                            </button>
                        )}
                        {showloader && (
                            <button
                                type="button"
                                className="btn btn-primary mrg-r20"
                                disabled
                            >
                                <div className="text-center">
                                    <Spinner
                                        animation="border"
                                        variant="light"
                                    />
                                </div>
                            </button>
                        )}
                        <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={closeModal}
                        >
                            Close
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}
