import React from 'react';
// import { Link } from "react-router-dom";
// import Dashboard from '../Pages/Dashboard';
// import NoPage from '../Pages/NoPage';
// import OrderReport from '../Pages/OrderReport';
// import LeadReport from '../Pages/LeadReport';
// import EarningReport from '../Pages/EarningReport';

function Footer({}) {
  return (
    <div>
      <div className="bottombar">© 2022 REMITX All Rights Reserved
       <span className='hide'>| <a href="https://www.remitx.com/privacypolicy">Privacy Policy </a></span>
       </div>
    </div>
  );
}

export default Footer;
