import {
    BiDownArrow,
    BiSolidDownArrow,
    BiSolidUpArrow,
    BiUpArrow,
} from "react-icons/bi";

/**
 *  To Add the sorting component 
 * Call the component where it is required
 * by Create a state for up down sort and the column name in the parent component
 * Call the sortTheData function (passing array and sort component value) with the required param 
 * as per portal.js, website.js files
 * 
 * @param {*} param0 
 * @returns 
 */
const SortArrow = ({ sortData, setSortData, columnName }) => {
    const setUpArrow = () => {
        setSortData("up " + columnName);
    };
    const setDownArrow = () => {
        setSortData("down " + columnName);
    };
    return (
        <>
            {sortData !== `up ${columnName}` && (
                <button onClick={setUpArrow}>
                    <BiUpArrow />
                </button>
            )}
            {sortData === `up ${columnName}` && (
                <button>
                    <BiSolidUpArrow />
                </button>
            )}
            {sortData !== `down ${columnName}` && (
                <button onClick={setDownArrow}>
                    <BiDownArrow />
                </button>
            )}
            {sortData === `down ${columnName}` && (
                <button>
                    <BiSolidDownArrow />
                </button>
            )}
        </>
    );
};

export default SortArrow;
