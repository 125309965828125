import { useState } from "react";
import {
    getItemValue,
    itemPerPage,
    slicedData,
    sortTheData,
} from "../../CommonElements/commonFunctions";
import { Pagination } from "../../Components/Pagination/Pagination";
import SortArrow from "../../Components/SortArrow/SortArrow";
import useWebsiteLeads from "../../Utils/useWebsiteLeads";

export const Website = ({ dateRange, searchQuery, triggerButton }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [sortData, setSortData] = useState("");
    const [currentData] = useWebsiteLeads(
        dateRange,
        searchQuery,
        triggerButton
    );
    const totalPages = Math.ceil(currentData?.length / itemPerPage);
    let data = sortTheData(currentData, sortData);
    data = slicedData(currentData, currentPage);
    return (
        <>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">
                            Date
                            <SortArrow
                                sortData={sortData}
                                columnName="created"
                                setSortData={setSortData}
                            />
                        </th>
                        <th scope="col">Lead Info</th>
                        <th scope="col">Product</th>
                        <th scope="col">FX Amount</th>
                        <th scope="col">Rate</th>
                        <th scope="col">Currency</th>
                        {/* <th scope="col">Purpose</th> */}
                        {/* <th scope="col">Status</th> */}
                    </tr>
                </thead>
                <tbody id="leadListingTbody">
                    {data.length > 0 ? (
                        data.map((item, i) => (
                            <tr key={i}>
                                <td>{item.created.split("T")[0]}</td>
                                <td>
                                    <div>{item.name}</div>
                                    <div>{item.email}</div>
                                    <div>{item.mobile}</div>
                                </td>
                                <td>{getItemValue(item.product_type)}</td>
                                <td>{item.fx_amount}</td>
                                <td>{item.rate}</td>
                                <td>{item.currency_name}</td>
                                {/* <td>{getItemValue(item.purpose)}</td> */}
                                {/* <td>
                                        <div className="inprogress">
                                            {item.status}
                                        </div>
                                    </td> */}
                            </tr>
                        ))
                    ) : (
                        <tr id="noRecordFound">
                            <td colSpan="9">No Listing available</td>
                        </tr>
                    )}
                </tbody>
            </table>
            {/* Render pagination controls here */}
            <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </>
    );
};
