import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { DateRangePicker } from "rsuite";
import { commonConstant } from "../../CommonElements/constants";
import { Portal } from "./Portal";
import { Website } from "./Website";
function LeadReport({ allowedModules }) {
    const [dateRange, setDateRange] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [triggerButton, setTriggerButton] = useState(false);
    const handleDateRange = (v) => {
        setDateRange(v);
    };

    const handleTriggerButton = () => {
        setTriggerButton((item) => !item);
    };
    // const [selectedTab, setSelectedTab] = useState("b2c");

    // console.log("portalLeads", portalLeadData);
    return (
        <>
            <div className="loadenv hide" id="middleLoader">
                <div className="loader text-center">
                    <img
                        src={
                            commonConstant.REMITX_URL +
                            "remitx-images/loader.gif"
                        }
                    />
                </div>
            </div>
            <div className="page-body-fx container mrg-t30">
                <div className="row">
                    <div className="col-md-12">
                        <h3>Search By</h3>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="mb-3">
                                    <label
                                        htmlFor="product"
                                        className="form-label"
                                    >
                                        Date
                                    </label>
                                    <DateRangePicker
                                        placeholder={"Select a date range"}
                                        format="yyyy-MM-dd"
                                        onChange={handleDateRange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="mb-3">
                                    <label
                                        htmlFor="product"
                                        className="form-label"
                                    >
                                        Lead Info
                                    </label>
                                    <input
                                        id="searchKeyword"
                                        className="form-control"
                                        type="text"
                                        placeholder="Please enter"
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }}
                                        value={searchQuery}
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <button
                                    type="button"
                                    className="btn btn-primary mrg-t30"
                                    onClick={handleTriggerButton}
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mrg-t30">
                        <h3>Lead Listing</h3>
                        {allowedModules?.b2cleads && (
                            <Tabs
                                defaultActiveKey="b2c"
                                id="uncontrolled-tab-example"
                                className=""
                            >
                                <Tab eventKey="b2c" title="B2C">
                                    <div className="mb-3 table-responsive">
                                        <Website
                                            dateRange={dateRange}
                                            searchQuery={searchQuery}
                                            triggerButton={triggerButton}
                                        />
                                    </div>
                                </Tab>
                                <Tab eventKey="b2b" title="B2B">
                                    <div className="mb-3 table-responsive">
                                        <Portal
                                            dateRange={dateRange}
                                            searchQuery={searchQuery}
                                            triggerButton={triggerButton}
                                        />
                                    </div>
                                </Tab>
                            </Tabs>
                        )}
                        {!allowedModules?.b2cleads && (
                            <div className="mb-3 table-responsive">
                                <Portal
                                    dateRange={dateRange}
                                    searchQuery={searchQuery}
                                    triggerButton={triggerButton}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default LeadReport;
