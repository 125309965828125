import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getItemValue, itemPerPage, slicedData, sortTheData } from "../../CommonElements/commonFunctions";
import { Pagination } from "../../Components/Pagination/Pagination";
import SortArrow from "../../Components/SortArrow/SortArrow";
import usePortalLeads from "../../Utils/usePortalLeads";


function changeDateFormat(timestamp) {
    const date = new Date(timestamp);
    // Extract year, month, and day components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    // Create the formatted date string
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

export const Portal = ({
    dateRange,
    searchQuery,
    triggerButton
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [sortData, setSortData] = useState("down created");
    const navigate = useNavigate();
    const leadListingClick = (leadInfoId) => {
        // console.log("leadInfoId", leadInfoId);
        navigate("/CreateLead/" + leadInfoId);
    };

    const [currentData] = usePortalLeads(
        dateRange,
        searchQuery,
        triggerButton
    );
    const totalPages  = Math.ceil(currentData?.length / itemPerPage);
    let data = sortTheData(currentData, sortData);
    data = slicedData(currentData, currentPage);
    // console.log("----", currentData?.length, totalPages, data?.length, currentPage, ",,,");
    
    return (
        <>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">
                            Date
                            <SortArrow
                                sortData={sortData}
                                columnName="created"
                                setSortData={setSortData}
                            />
                        </th>
                        <th scope="col">Lead Info</th>
                        <th scope="col">Product</th>
                        <th scope="col">FX Amount</th>
                        <th scope="col">Rate</th>
                        <th scope="col">Currency</th>
                        <th scope="col">Country</th>
                        <th scope="col">Created By</th>


                        {/* <th scope="col">Purpose</th> */}
                        <th scope="col">Status</th>
                        <th scope="col">Description</th>
                    </tr>
                </thead>
                <tbody id="leadListingTbody">
                    {data?.length > 0 ? (
                        data?.map((item, i) => (
                            <tr
                                key={i}
                                onClick={() =>{
                                    if(item.status==='Shared(User)')
                                    return
                                    !item.shareremitx_id&&leadListingClick(item.lead_info_id)
                                }
                                }
                            >
                                {/* <td>{item?.created?.split("T")[0]}</td> */}
                                <td>{changeDateFormat(item?.created)}</td>
                                <td>
                                    <div className="text-bold">
                                        {item?.name? item.name : item?.first_name+" "+item?.last_name}
                                        {/* {item?.first_name||item.name} {item.last_name} */}
                                    </div>
                                    <div>{item.email}</div>
                                    <div>{item?.mobile||item.phone_mobile}</div>
                                </td>
                                <td>{getItemValue(item.product)||item.purpose}</td>
                                <td>{item.fx_amount}</td>
                                <td>{item.rate}</td>
                                <td>{item.currency}</td>
                                <td>{item.countryName}</td>
                                <td>
                                    {item.created_by_code?item.created_by_name:'Self'}<br></br>
                                    {item.created_by_code}
                                    </td>
                                {/* <td>{getItemValue(item.purpose)}</td> */}
                                <td>
                                    <div className="inprogress">
                                        {item.status==='New'?"Shared(Remitx)":item.status}
                                    </div>
                                </td>
                                <td>{item?.status_description}</td>
                            </tr>
                        ))
                    ) : (
                        <tr id="noRecordFound">
                            <td colSpan="9">No Listing available</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </>
    );
};
