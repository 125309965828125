import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios'; // Don't forget to import Axios

const PaymentSuccessScreen = () => {
    const location = useLocation(); 
  useEffect(() => {
   
    const { state } = location; // Access location from the top-level variable
    console.log(state);
    const formData = state ? state.formData : null;

    // Send the POST data to your Node.js backend
    if (formData) {
      axios.post('/payment-callback-endpoint', formData) // Replace with your actual backend endpoint
        .then(response => {
          console.log('Data forwarded to the backend:', response.data);
        })
        .catch(error => {
          console.error('Error forwarding data to the backend:', error);
        });
    }
  }, []); // The empty dependency array ensures this code runs once when the component mounts

  return (
    <>
      <div>Hello</div>
      {0 && (
        <div>
          <h1>Hello</h1>
          <p>Payment ID: </p> {/* Replace with the correct field name */}
          <div>
            <p>Form Data:</p>
            <pre></pre>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentSuccessScreen;
