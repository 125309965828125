import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { headers, setAlphaNumeric, setNumeric, showErrorNotification, showSuccessNotification } from "../../CommonElements/commonFunctions.js";
import { commonConstant } from "../../CommonElements/constants.js";

import {
    emailValidate,
    setEmailValidation
} from "../../CommonElements/commonFunctions.js";

import axios from "axios";

/**
 * Share button
 * @returns
 */
export default function Addsubagent({ closeModal,mode , currentUser,users,setUsers,setCurrentUser }) {
    const [name, setName] = useState(currentUser?.name||"");
    const [mobile, setMobile] = useState(currentUser?.mobile||"");
    const [email, setEmail] = useState(currentUser?.email||"");
    const [showloader, setShowLoader] = useState(false);
    const buttonClick = async () => {

        try{
        let postData={
            name:name,
            email:email,
            mobile:mobile
        }

        if(!name)
        return showErrorNotification("Enter User's Name")

        if(!mobile)
        return showErrorNotification("Enter Mobile Number")

        if(!email)
        return showErrorNotification("Enter User's Email")
    

        if((mobile+"").length!=10)
        return showErrorNotification("Incorrect Mobile Number")
        if(!emailValidate(email))
          return  showErrorNotification("Incorrect Email")
            
        
        if(emailValidate(email) && ((mobile+"").length==10) )
        {
        let resData;
        if(mode==="new")
        {
         resData =await axios.post(`${commonConstant.BACKEND_API_URL}partners/user/subagent`, postData,{  headers: headers()});
         if(resData.data.responseCode==400)
         throw new Error(resData.data.responseMessage)
         
         setUsers( (pre)=>{return [...pre,resData.data.responseData?.responseData?.rows[0]]})
        showSuccessNotification("Added Successfully")
        setCurrentUser(null)
        closeModal();
        }
        else {

            postData.userid=currentUser.id
            resData =await axios.put(`${commonConstant.BACKEND_API_URL}partners/user/subagent`, postData,{  headers: headers()});           
            let u=resData.data.responseData?.responseData?.rows[0];

            if(resData.data.responseCode==400)
                throw new Error(resData.data.responseMessage)

            
           if(u){
            let updatedUsers=[];
            setUsers((pre)=>{

                if(pre.id===u.id)
                {
                    pre.name=name;
                    pre.email=email;
                    pre.mobile=mobile
                    updatedUsers.push(pre);

                }else updatedUsers.push(pre);
                
                
            })
            setUsers(updatedUsers)
            showSuccessNotification("Updated Successfully")
            setCurrentUser(null)
           closeModal();
        }
       
        }
    } 
    }catch(e){
        console.log(e)
        showErrorNotification(e.message)
    }
    }


    if (!closeModal) return <></>;
    return (
        <>
            <div className="modal-heading">
                <h3>{mode==="new"?"Add User":"Edit"}</h3>
                <button
                    type="button"
                    className="btn-close close"
                    aria-label="Close"
                    onClick={closeModal}
                ></button>
            </div>
            <div className="col-md-12">
                <form>
                    <div className="row" id="share_container_user">
                        <div className="col-12">
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">
                                    Name<span>*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Name"
                                    name="name"
                                    onKeyDown={setAlphaNumeric}                          
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                    value={name}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-3">
                                <label htmlFor="mobile" className="form-label">
                                    Mobile Number<span>*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    maxLength={10}
                                    placeholder="Enter Mobile"
                                    name="mobile"
                                    onKeyDown={setNumeric}
                                    onChange={(e) => {                                      
                                        if((e.target.value+"").length<=10)
                                        setMobile(e.target.value);
                                    }}
                                   
                                    value={mobile}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">
                                    Email ID<span>*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    onKeyDown={setEmailValidation}
                                    placeholder="Enter Email"
                                    name="email"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    value={email}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mrg-t30">
                        {!showloader && (
                            <button
                                type="button"
                                className="btn btn-primary mrg-r20"
                                onClick={() => {
                                    buttonClick();
                                    
                                }}
                            >
                               {mode==="new"?"Add":"Save"} 
                            </button>
                        )}
                        {showloader && (
                            <button
                                type="button"
                                className="btn btn-primary mrg-r20"
                                disabled
                            >
                                <div className="text-center">
                                    <Spinner
                                        animation="border"
                                        variant="light"
                                    />
                                </div>
                            </button>
                        )}
                        <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={closeModal}
                        >
                            Close
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}
