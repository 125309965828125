import React from 'react';

const AdminSidebar = () => {
  return (
    <aside>
      <ul>
        <li>Dashboard</li>
        <li>Users</li>
        <li>Products</li>
      </ul>
    </aside>
  );
};

export default AdminSidebar;
