export default function AreYouInterested({ checkedOptions, setCheckedOptions }) {
    // Function to handle checkbox change
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedOptions({
            ...checkedOptions,
            [name]: checked,
        });
    };

    return (
        <>
            <div className="mb-3">
                <div className="checkbox_custom">
                    <div className="label">Are you Interested in?</div>
                    <label>
                        <input
                            type="checkbox"
                            name="travelservices"
                            checked={checkedOptions.option1}
                            onChange={handleCheckboxChange}
                            inline
                        />
                        &nbsp; Travel Services
                    </label> &nbsp;
                    <label>
                        <input
                            type="checkbox"
                            name="educationloan"
                            checked={checkedOptions.option2}
                            onChange={handleCheckboxChange}
                            inline
                        />
                         &nbsp;Education Loan
                    </label>
                    <br />
                </div>
            </div>
        </>
    );
}
