import React from 'react';
function PlaceOrder() {
  return (
    <div className="page-body-fx container mrg-t30">
      <div className='row'>
      <div className='col-md-6'><h2>Customer Details</h2></div>
      <div className='col-md-6 timerout'>Complete your order before rate expires in <span>05 Minutes 30 Seconds</span></div>
        <div className='col-md-12 mrg-t20 mrgb-4'>
          <div className='row'>
            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label text-bold">First Name<span>*</span></label>
                <input className="form-control" type="text" value="Sumit" placeholder='Enter name'/>
              </div>
            </div>
            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label text-bold">Last Name<span>*</span></label>
                <input className="form-control" type="text" value="Kumar" placeholder='Enter name'/>
              </div>
            </div>
            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label text-bold">Mobile Number<span>*</span></label>
                <input className="form-control" type="text"  value="8826464269" placeholder='Enter Mobile'/>
              </div>
            </div>
            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label text-bold">Email<span>*</span></label>
                <input className="form-control" type="text" value="sumit.kumar@gmail.com" placeholder='Enter Mobile'/>
              </div>
            </div>
          </div>
          <div className='row mrg-t20'>
            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label text-bold">Remitx Branch<span>*</span></label>
                <select className="form-select">
                  <option>Please Select</option>
                </select>
              </div>
            </div>
          </div>

          <h3 className='mrg-t20'>Documents</h3>
          <div className='row'>
          <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label text-bold">Document Type<span>*</span></label>
                <select className="form-select">
                  <option>Please select</option>
                </select>
              </div>
            </div>
            <div className='col-md-4'>
              <div className="mb-3">
                <label htmlFor="product" className="form-label text-bold">Document Type<span>*</span></label>
                <input className="form-control" type="file" placeholder='Enter name'/>
              </div>
            </div>
            
          </div>
          <div className='col-md-12 mrg-t30'>
                <button type="button" className="btn btn-primary mrg-r20">Place Order</button>
                <button type="button" className="btn btn-outline-primary">Back</button>
              </div>
        </div>
      </div>
    </div>
  );
}

export default PlaceOrder;
