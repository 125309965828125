import { useEffect, useState } from "react";
import { getFormattedDate } from "../CommonElements/commonFunctions";
import { getWebsiteLeadListing } from "../services/Service";

const searchColumns = ["name", "email", "mobile", "product_type"];

export default function useWebsiteLeads(
    dateRange,
    filterQuery = "",
    triggerButton,
    type,
    orderNo
) {
    const [data, setData] = useState([]);
    const [listingData, setListingData] = useState(null);
    const [prevDateRange, setPrevDateRange] = useState("");
    // console.log("render 0")
    useEffect(() => {
        // console.log("clicked", dateRange, filterQuery);
        let list = [];
        async function call() {
            if ((!dateRange && prevDateRange) || (dateRange && !prevDateRange) || (dateRange?.[0] !== prevDateRange?.[0]) || (dateRange?.[1] !== prevDateRange?.[1]) || !listingData) {
                let startDate = null;
                let endDate = null;
                if (dateRange?.[0]) {
                    startDate = getFormattedDate(dateRange[0]);
                }
                if (dateRange?.[1]) {
                    endDate = getFormattedDate(dateRange[1]);
                }
                let requestData = { type, startDate, endDate };
                let reports = await getWebsiteLeadListing(requestData);
                setPrevDateRange(dateRange);
                setListingData(reports?.responseData || []);
                // setData(reports.responseData);
                list = reports?.responseData || [];
            }
            if (!list?.length && listingData?.length) {
                list = listingData;
            }
            if (type === 'orders' && orderNo) {
                let filteredData = list?.filter((item) => item.orderid.includes(orderNo));
                list = filteredData;
            }
            if (filterQuery) {
                let filteredData = list?.filter((item) => {
                    let updatedVal = searchColumns
                        .map((cols) => {
                            return item?.[cols]?.toLowerCase();
                        })
                        .join(" ");
                    return updatedVal?.includes(filterQuery?.toLowerCase());
                });
                list = filteredData;
            }
            
            // console.log(list);
            setData(list);
        }
        // console.log("render 1");
        call();
    }, [triggerButton]);
    return [data];
}
