import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { commonConstant } from '../CommonElements/constants';
import './../css/App.css';
import './../css/main-style.css';
// import Addsubagent from '../Components/Subagent/Addsubagent';

// const ModuleList = ({mappingModules}) => {
//   return <>
//     {mappingModules.map((item => {

//     }))}
//   </>;
// }

function Menu({agentType,agentName,mappingModules,agentCode, allowedModules,agentLogo}) {
  let navigate = useNavigate();
  //   console.log(mappingModules);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };
  // const openModal = () => {
  //   setIsModalOpen(true);
  // };
  let modulesArray=[]
  for(let i=0;i<mappingModules.length;i++){
    modulesArray.push(mappingModules[i].name)
  }

  let modulesList = mappingModules.filter((item) => item.inmenu).map((item) => {
    let url_string = (!!item.url_name) ? item.url_name : '';
    if(item.name==='LoanLeads')
    return

    if(item.name=='LeadJunction')
    return;

    if (item.name === 'Leads')
      return <NavDropdown title="Leads" id="navbarScrollingDropdown"> 
        <NavDropdown.Item href="/LeadReport">Forex lead</NavDropdown.Item>
        {
          modulesArray.includes('LeadJunction')&&
        <NavDropdown.Item href="/LeadJunctionList">Lead Junction</NavDropdown.Item>
       }
        {
         modulesArray.includes('LoanLeads') &&
        <NavDropdown.Item href="/loanleads">
          Loan Lead
        </NavDropdown.Item>
        }
      
      </NavDropdown>

    else return<NavLink to={"/" + url_string}>{item.name}</NavLink>;
  });

  
  function logout() {
    const cookies = new Cookies();
    cookies.remove('access_token');
    localStorage.clear();
    window.location.href = "/login";
  }
  function checkCookieAndRedirect() {
    const cookies = new Cookies();
    if (!cookies.get('access_token')) {
      window.location.href = "/login";
    }
  }

  useEffect(() => { checkCookieAndRedirect(); }, []);
  let expand = "lg";
  return (
    <div className="Menu">
      <Navbar key={expand} expand={expand} className="">
        <Container>
          <Navbar.Brand href="/">
            {
              
                <img
                  src=   {agentLogo?agentLogo:`https://www.remitx.com/remitx-images/remitx.svg`}
                  alt="Logo"
                  title="" style={{ width: 'auto' }}
                />
              
            }
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <Navbar.Brand href="#home">
                  {
                    commonConstant.AGENT_CODES_FOR_LOGOS.includes(agentCode) ? (
                      <img
                        src={`/${agentCode}.png`}
                        alt="Logo"
                        title="" style={{ width: 'auto' }}
                      />
                    ) : <img src="https://www.remitx.com/remitx-images/remitx.svg" alt="Logo" title="" />
                  }
                </Navbar.Brand>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="">
                {modulesList}
                {/* {
              agentType === 'agent' && 
              <>
                <Nav.Link href='/EarningReport'>Earnings</Nav.Link>
                <Nav.Link href='/Payouts'>Payouts</Nav.Link>
              </>
            } */}
                {/* <NavDropdown title="leads" id="navbarScrollingDropdown">
              <NavDropdown.Item href="#action3">Loan</NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Loan 1
              </NavDropdown.Item>
              <NavDropdown.Divider />
            </NavDropdown> */}


                <NavDropdown title={agentName} id="basic-nav-dropdown">

                  { modulesArray.includes("Subagents")&&<NavDropdown.Item onClick={() => { window.location.href = "/subagents" }}>Manage Users</NavDropdown.Item>}
                  <NavDropdown.Item href="javascript:void(0)" onClick={logout}>Logout</NavDropdown.Item>
                </NavDropdown>
              </Nav>

              {/*  */}

              {/* <Modal
            isOpen={isModalOpen}
            className="planModal"
            onRequestClose={closeModal}
          >
            <Addsubagent closeModal={closeModal} />
          </Modal> */}

              {/*  */}
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

    </div>

  );
}

export default Menu;
