export default function NestroChargesPayer({
    nestroChargesPayerOption,
    setNestroChargesPayerOption,
}) {
    const handleOptionNestroCharges = (event) => {
        // console.log(event.target.value);
        setNestroChargesPayerOption(event.target.value);
    };
    return (
        <>
            <div className="col-md-6">
                <div className="mb-3">
                    <label htmlFor="product" className="form-label d-flex">
                        Nestro Charges Payer
                    </label>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            value="remitter"
                            checked={nestroChargesPayerOption === "remitter"}
                            onChange={handleOptionNestroCharges}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                        >
                            Remitter
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            value="beneficiary"
                            checked={nestroChargesPayerOption === "beneficiary"}
                            onChange={handleOptionNestroCharges}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                        >
                            Beneficiary
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
}
