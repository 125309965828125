import { utils as XLSXUtils, writeFile } from 'xlsx';
import './../css/main-style.css';

export const DownloadExcel = (props) => {
    const handleExcelDownload = (moduleName = props.moduleName || 'earning',moduleData = props.moduleData,fileName= props.fileName ||'sample.xlsx') => {
        let defineColumns = [];
        if(moduleName == 'earning'){
        defineColumns = ["#","Date","Invoice No","Lead Info","FX Amount","Quotation Rate","Total Revenue"];
        }else{
        //define other modules columns
        defineColumns = ["#","Date","Invoice No","Lead Info","FX Amount","Quotation Rate","Total Revenue"];
        }
    
        // Create a sample workbook with a single sheet
        const workbook = XLSXUtils.book_new();
        const dynamicArray = Object.entries(moduleData).map(([key, value]) => {
        return [ (parseInt(key) + 1),value.OrderDate.replace(' 12:00AM',''), value.TransactionNo,value.Name+"\n"+value.Email+"\n"+value.Mobile,value.FxAmount,value.QuatationRate,value.TotalRevenue ];
        });
        let finalArray = [defineColumns,...dynamicArray];
        const worksheetData = finalArray;
        const worksheet = XLSXUtils.aoa_to_sheet(worksheetData);
        XLSXUtils.book_append_sheet(workbook, worksheet, 'Sheet1');
        // Generate the Excel file
        writeFile(workbook, fileName, { bookType: 'xlsx', type: 'array' });
    };
    return <button className='download-excel' onClick={() => handleExcelDownload()}>
        <img src='https://remitxb2c.s3.ap-south-1.amazonaws.com/1689831758151-excel.png' /> Export Excel
    </button>;
}
  