import React, { useState, useEffect } from "react";
import { commonConstant } from "../CommonElements/constants";
import {
    RateRoundof,
    getFormattedDate,
    sortByOrderDate,
} from "../CommonElements/commonFunctions";
import { getEarningListing } from "../services/Service";
import { DateRangePicker } from "rsuite";
import { DownloadExcel } from '../Components/DownloadExcel';
import moment from "moment";

function EarningReport(props) {
    const [dateRange, setDateRange] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [earnings, setEarnings] = useState([]);
    const [totalEarnings, setTotalEarnings] = useState([]);
    let totalPages = 0;
    let currentData = [];
    const SingleRow = ({ idx, data }) => {
        return (
            <tr key={idx}>
                <td>{(currentPage - 1) * 10 + (idx + 1)}</td>
                <td>
                    {moment(data.OrderDate.slice(0, 11)).format("DD MMM YYYY")}
                </td>
                <td>{data.TransactionNo}</td>
                <td>
                    {data.Name}
                    <br /> {data.Email}
                    <br /> {data.Mobile}
                </td>
                <td>{RateRoundof(data.FxAmount)}</td>
                <td>{RateRoundof(data.QuatationRate)}</td>
                {/* <td>{data.RemitxRate}</td> */}
                <td>{RateRoundof(data.TotalRevenue)}</td>
            </tr>
        );
    };
    const getEarnings = () => {
        let data = {};
        if (dateRange) {
            data.FromDate = getFormattedDate(dateRange[0]);
            data.ToDate = getFormattedDate(dateRange[1]);
        }
        getEarningListing(data)
            .then((res) => {
                if (res.responseCode == 200) {
                    if (res.responseData.earningListing[0].Code == 200) {
                        res.responseData.earningListing = sortByOrderDate(
                            res.responseData.earningListing
                        );
                        setEarnings(res.responseData.earningListing);
                        setTotalEarnings(res.responseData.earningListing);
                    } else {
                        setTotalEarnings([]);
                        setEarnings([]);
                    }
                } else {
                    setTotalEarnings([]);
                    setEarnings([]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    if (earnings.length > 0) {
        const itemsPerPage = 10;
        const totalItems = earnings.length;
        totalPages = Math.ceil(totalItems / itemsPerPage);
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        currentData = earnings.slice(startIndex, endIndex);
    }
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const handleChange = (e) => {
        setDateRange(e);
    };

    const Search = () => {
        let invoiceVal = document.getElementById("InvoiceNo").value;
        if (invoiceVal) {
            let dataCurrentData = totalEarnings.filter((item) => {
                if (item.TransactionNo.includes(invoiceVal)) return item;
            });
            setEarnings(dataCurrentData);
            setCurrentPage(1);
        } else {
            getEarnings();
        }
    };
    useEffect(() => {
        getEarnings();
    }, []);
    return (
        <>
            <div className="loadenv hide" id="middleLoader">
                <div className="loader text-center">
                    <img
                        src={
                            commonConstant.REMITX_URL +
                            "remitx-images/loader.gif"
                        }
                    />
                </div>
            </div>
            <div className="page-body-fx container mrg-t30">
                <div className="row">
                    <div className="col-md-12">
                        <h3>Search By</h3>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="mb-3">
                                    <label
                                        htmlFor="product"
                                        className="form-label"
                                    >
                                        Date
                                    </label>
                                    <DateRangePicker
                                        format="yyyy-MM-dd"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="mb-3">
                                    <label
                                        htmlFor="product"
                                        className="form-label"
                                    >
                                        Invoice No.
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="InvoiceNo"
                                        placeholder="Please enter"
                                    />
                                </div>
                            </div>
                            <div className="col-md-2 hide">
                                <div className="mb-3">
                                    <label
                                        htmlFor="product"
                                        className="form-label"
                                    >
                                        Lead Info
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="searchKeyword"
                                        placeholder="Please enter"
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <button
                                    type="button"
                                    className="btn btn-primary mrg-t30"
                                    onClick={Search}
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-md-12 mrg-t30"
                        style={{ position: "relative" }}
                    >
                        <h3>Earning Listing ({earnings.length})</h3>
                        {currentData.length > 0 && (
                            <DownloadExcel
                                moduleName="earning"
                                moduleData={earnings}
                                fileName="earning.xlsx"
                            />
                        )}
                        <div className="mb-3 table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Date</th>
                                        <th>Invoice No</th>
                                        <th scope="col">Lead Info</th>
                                        <th scope="col">FX Amount</th>
                                        <th scope="col">Quotation Rate</th>
                                        {/* <th scope="col">RemitX Rate</th> */}
                                        <th scope="col">Total Revenue</th>
                                    </tr>
                                </thead>
                                <tbody id="leadListingTbody">
                                    {currentData.length > 0 ? (
                                        currentData.map((item, i) => (
                                            <SingleRow data={item} idx={i} />
                                        ))
                                    ) : (
                                        <tr id="noRecordFound">
                                            <td colSpan="7">
                                                No Listing available
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {/* Render pagination controls here */}

                            <div className="pagination">
                                <button
                                    onClick={handlePreviousPage}
                                    disabled={currentPage === 1}
                                >
                                    Previous
                                </button>
                                {Array.from({ length: 3 }, (_, index) => (
                                    <>
                                        {currentPage + index - 1 > 0 &&
                                            currentPage + index - 1 <=
                                                totalPages && (
                                                <button
                                                    key={
                                                        currentPage + index - 1
                                                    }
                                                    onClick={() =>
                                                        handlePageClick(
                                                            currentPage +
                                                                index -
                                                                1
                                                        )
                                                    }
                                                    disabled={
                                                        currentPage ===
                                                        currentPage + index - 1
                                                    }
                                                >
                                                    {currentPage + index - 1}
                                                </button>
                                            )}
                                    </>
                                ))}
                                <button
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EarningReport;
