import React, { useEffect, useState } from 'react';
import { BrowserRouter, json, Route, Routes } from "react-router-dom";
import Cookies from 'universal-cookie';
import Footer from "../CommonElements/Footer";
import Menu from "../CommonElements/Menu";
import { capitalizeFirstLetter } from '../CommonElements/commonFunctions';
import AdminPanel from '../Components/Admin/AdminPanel';
import AdminUsers from '../Components/Admin/AdminUsers';
import CreateLead from "../Pages/CreateLead";
import Dashboard from "../Pages/Dashboard";
import EarningReport from "../Pages/EarningReport";
import LeadReport from "../Pages/Leads/LeadReport";
import UserShare from '../Pages/Leads/UserShare.js';
import LoanLeadsReport from '../Pages/LoanLeads/LoanLeadsReport.js';
import Login from "../Pages/Login";
import ManageSubagents from '../Pages/ManageSubagents.js';
import NoPage from "../Pages/NoPage";
import OrderReport from "../Pages/Orders/OrderReport";
import PaymentSuccessScreen from '../Pages/PaymentSuccessScreen';
import Payouts from "../Pages/Payouts/Payouts";
import PlaceOrder from "../Pages/PlaceOrder";
import LeadJunctionList from "../Pages/LeadJunction/LeadJunctionList";


import { checkToken } from "../services/Service";

import QuotationApproveScreen from '../Pages/QuotationApproveScreen.js';
// import axios from 'axios';
// import { commonConstant } from '../CommonElements/constants';
/**
 * Routes Page Requirement
 * 
 * 1. Authenticated Pages
 * 2. Public Pages
 * 
 * Redirect Authenticated Pages to login Page
 * 
 * Plain Pages do not Contains Header And Footer
 */
let authenticatedPaths = ["", "CreateLead", "EarningReport", "LeadReport", "OrderReport", "Payouts", "PlaceOrder", "Subagents","LoanLeadsReport","loanleads","LeadJunctionList"];
let plainPages = ["login", "UpdateStatus","payment-success", "userinfo"];
export default function Router() {
  const [agentCode, setAgentCode] = useState(false);
  const [agentId,setAgentId]=useState(null);
  const [agentName, setAgentName] = useState(false);
  const [agentType, setAgentType] = useState(false);
  const [showMenus, setShowMenus] = useState(false);
  const [agentLogo,setAgentLogo]=useState('');
  const [agentFavicon,setAgentFavicon]=useState('');
  const [mappingModules,setMappingModules] = useState([]);
  const [allowedModules, setAllowedModules] = useState({});
  const [commission, setCommission] = useState({
    commissionType: 'ratio',
    commissionValue: 0
  });
  // const [Authenticated, setAuthenticated] = useState(false);
  const cookies = new Cookies();
  let path = window.location.pathname;
  
  const checkPath = (paths) => paths.find((item) => {
    if (item !== "" && path?.toLowerCase()?.indexOf(item?.toLowerCase()) !== -1) {
      return true;
    }
    return false;
  });
  const isAuthenticated = () => new Promise(async (resolve) => {
    if (!cookies.get('access_token')) {
      resolve(false);
    } else {
      const is_check = await checkToken();
       if (is_check.responseCode !== 200) {
        resolve(false);
      } else {
        let isAllowed = {};
        is_check.responseData.mappingData.map(item => {
          isAllowed[(item.url_name || 'Dashboard')] = true;
        });
        setAllowedModules(isAllowed);
        setMappingModules(is_check.responseData.mappingData);
        setCommission(is_check.responseData.commission)
        setAgentType(is_check.responseData.agenttype);
        setAgentId(is_check.responseData.id)
        setAgentCode(is_check.responseData.code);
        setAgentName(capitalizeFirstLetter(is_check.responseData.name));
        setAgentFavicon(is_check.responseData.favicon_url);
        setAgentLogo(is_check.responseData.logo_url)
        resolve(true);
      }
    }
  })

  useEffect(() => {
    if(agentFavicon){
    let faviconLink = document.querySelector("link[rel*='icon']") || document.createElement('link');
    faviconLink.href= agentFavicon ;
    faviconLink.type = 'image/png';
    faviconLink.rel = 'icon';
    document.head.appendChild(faviconLink);
    }
  }, [agentFavicon]);

  const redirect = async () => {
    let paths = authenticatedPaths;
    // console.log("Path", path);
    let validate = checkPath(paths, path)
    // console.log(validate);
    if (path === '/') {
      validate = 'Root';
    }
    if (!!validate) {
      let responseAuth = await isAuthenticated();
      if (responseAuth) {
        // setAuthenticated(true);
      } else {
        // setAuthenticated(false);
        window.location.href = '/login';
      }
      return false;
    }
  }
  const checkPublicPage = async () => {
    let validate = checkPath(plainPages);
    if (validate) {
      setShowMenus(false);
      //if login page
      if (validate === plainPages[0]) {
        let responseAuth = await isAuthenticated();
        if (responseAuth) {
          // setAuthenticated(true);
          window.location.href = '/';
        } else {
          // setAuthenticated(false);
        }
      }
    }else {
      setShowMenus(true);
    }
    return false
  }
  useEffect(() => {
    redirect();
    checkPublicPage();
  }, []);
  return (
    <BrowserRouter>
      {
        showMenus &&
        <Menu agentCode={agentCode} agentType={agentType} agentName={agentName} mappingModules={mappingModules} allowedModules={allowedModules} agentLogo={agentLogo}/>
      }
      <Routes>
        <Route path="/">
          {/* Authenticated Pages */}
          {
            !!cookies.get('access_token') &&
            <>
              <Route path="/admin" element={<AdminPanel />} />
              <Route path="/admin/users" element={<AdminUsers/>} />
              <Route path="/LoanLeads" element={<LoanLeadsReport/>}/>
              {/* <Route path="/Loan" element={<LoanLeadsReport/>}/> */}

              {allowedModules.Dashboard && <Route path={authenticatedPaths[0]} element={<Dashboard allowedModules={allowedModules} agentType={agentType} />} />}
              {/* <Route path={authenticatedPaths[1]} element={<CalenderBar />} /> */}
              {allowedModules.CreateLead && <Route path={authenticatedPaths[1]+"/:id?"} element={<CreateLead commission={commission} />} />}
              {/* <Route path={authenticatedPaths[3]} element={<CustomerDetails />} /> */}
              {allowedModules.EarningReport && <Route path={authenticatedPaths[2]} element={<EarningReport payoutLink={allowedModules.Payouts} />} />}
              {allowedModules.LeadReport && <Route path={authenticatedPaths[3]} element={<LeadReport allowedModules={allowedModules} />} />}
              {allowedModules.OrderReport && <Route path={authenticatedPaths[4]} element={<OrderReport agentType={agentType} allowedModules={allowedModules} />} />}
              {/* {allowedModules.LeadJunctionList &&} */}
              {allowedModules.Payouts && <Route path={authenticatedPaths[5]} element={<Payouts />} />}
              {allowedModules.PlaceOrder && <Route path={authenticatedPaths[6]} element={<PlaceOrder />} />}
              {allowedModules.Subagents && <Route path={authenticatedPaths[7]} element={<ManageSubagents agentType={agentType} allowedModules={allowedModules}/>}/>
              }
                        {allowedModules.LeadJunctionList && <Route path={authenticatedPaths[10]} element={<LeadJunctionList AgentCode={agentCode} AgentId={agentId} agentType={agentType} allowedModules={allowedModules} />} />}

            </>
          }
          {/* Public Pages */}
          <Route exact path={plainPages[0]} element={<Login agentCode={agentCode}/>} />
          <Route path={plainPages[1] + "/:status/:id/:bankid"} element={<QuotationApproveScreen/>} />
          <Route path={plainPages[2] + "/:id"} element={<PaymentSuccessScreen />} />
          <Route path={plainPages[3] + "/:id"} element={<UserShare allowEmpty={true} />} />
          <Route path="*" element={<NoPage />} />
          <Route path="/Loan" element={<LoanLeadsReport/>}/>
         
        </Route>
      </Routes>
      {
        showMenus &&
        <Footer mappingModules={mappingModules}/>
      }
    </BrowserRouter>
  );
}

