export default function SourceOfFund({ sourceOfFundOption, setSourceOfFundOption }) {
    const handleOptionSourceOfFund = (event) => {
        // console.log(event.target.value);
        setSourceOfFundOption(event.target.value);
    };
    return (
        <>
            <div className="col-md-6">
                <div className="mb-3">
                    <label htmlFor="product" className="form-label d-flex">
                        Source of Fund<span>*</span>
                    </label>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions1"
                            id="inlineRadio3"
                            value="self"
                            checked={sourceOfFundOption === "self"}
                            onChange={handleOptionSourceOfFund}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="inlineRadio3"
                        >
                            Self Funded
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions1"
                            id="inlineRadio4"
                            value="loan"
                            checked={sourceOfFundOption === "loan"}
                            onChange={handleOptionSourceOfFund}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="inlineRadio4"
                        >
                            Loan Fund
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
}
