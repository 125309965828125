import React, { useState } from 'react';
import { headers, showErrorNotification, showSuccessNotification } from '../../CommonElements/commonFunctions';
import { commonConstant } from "../../CommonElements/constants";
import { getUserDetails, saveUserModules } from '../../services/Service';
const AdminUsers = () => {
    const [inputValue, setInputValue] = useState('');
    const [agentData,setAgentData] = useState(null);
    const [checkboxes, setCheckboxes] = useState([]);
    const handleCheckboxChange = (event, itemId) => {
        const { checked } = event.target;
        if (checked) {
          setCheckboxes((prevCheckboxes) => [...prevCheckboxes, itemId]);
        } else {
          setCheckboxes((prevCheckboxes) =>
            prevCheckboxes.filter((id) => id !== itemId)
          );
        }
      };
      const handleInputChange = (event) => {
        setInputValue(event.target.value);
      };
    const handleSubmit = () => {
        if(!inputValue){
            showErrorNotification("Please enter the valid Partner Code.","top");
            return false;
        }
        // Make an API call to the backend
        getUserDetails({code:inputValue})
       .then((res) => {
         if(res.responseCode == 200){
            showSuccessNotification("Details has been fetched successfully.");
            setAgentData(res.responseData);
            const valuesArray = res.responseData.moduleMapping.filter((item) => item.is_enabled).map(item => item.id);
            setCheckboxes(valuesArray)
         }else{
            showErrorNotification(res.responseMessage);
         }
       })
       .catch((e) => {
         console.log(e.message)
       });
    };
      const savePermissions = () => {
        const data = {
            code: inputValue,
            module_ids: checkboxes.join(',')
        };
        saveUserModules(data)
        .then((res) => {
          if(res.responseCode == 200){
            console.log(res);
            showSuccessNotification("Details has been successfully added.");
          }else{
            showErrorNotification(res.responseMessage);
          }
        })
        .catch((e) => {
          console.log(e.message)
        });
      }
  return (
    <div className='container' style={{ marginTop: '30px' }}>
        <h2>Users Module(s) Management</h2>
        <div className='abc mb-3 row'>
            <div className='col-3'>
                <label>Enter the Partner Code</label>
                <input type='textfield' onChange={handleInputChange} className='form-control' placeholder='XXXXXX' id='agentCode' />
            </div>
            <div className='col-2' style={{ marginTop: '30px' }}><input type='button' className='btn btn-primary' value='Submit'  onClick={handleSubmit}/></div>

        </div>
    {
        !!agentData &&  
        <div className='container'>
            <div className="card mb-3">
                <div className="card-body information_box">
                <div>
                    <label>Partner Code:</label>
                    {agentData.code}
                </div>
                <div>
                    <label>Name:</label>
                    {agentData.name}
                </div>
                <div>
                    <label>Email:</label>
                    {agentData.email}
                </div>
                <div>
                    <label>Mobile:</label>
                    {agentData.mobile}
                </div>
                <div>
                    <label>Last Login:</label>
                    {agentData.last_login}
                </div>
                </div>
            </div>

    <div className="mappingBox mb-3">
        <ul className="list-group list-group-flush">
        {Object.entries(agentData.moduleMapping).map(([key, item]) => (
          <li  className="list-group-item" key={item.id}>
            <input type='checkbox' value={item.id} name='moduleId[]' 
            checked={checkboxes.includes(item.id)}
            onChange={(event) => handleCheckboxChange(event, item.id)} />&nbsp;
            <label> {item.name}</label>
          </li>
        ))}
        </ul>
    </div>
    <div className='mb-3'><input type='button' className='btn btn-primary' value='Save Permissions' onClick={savePermissions}/></div>
    </div>
    }
    

      {/* Add your user management content here */}
    </div>
  );
};

export default AdminUsers;
