export const commonConstant = {
    BASE_URL : process.env.REACT_APP_BASE_URL || 'http://localhost:3005/',
    BACKEND_API_URL : process.env.REACT_APP_BACKEND_API_URL || 'http://localhost:5999/',
    //BACKEND_API_URL :'https://alb.remitx.com/',
    DEFAULT_IMG_URL_SCOOTER: 'https://images.91wheels.com/images/scooter.png',
    REMITX_NODE_API_URL : process.env.REACT_APP_REMITX_BACKEND_API_URL || 'https://alb.remitx.com/',
    REMITX_URL : process.env.REACT_APP_REMITX_BASE_URL || 'https://alb.remitx.com/',
    API_KEY : 'a6a377e47016c1ac0672b2299dc05e48',
    PRODUCT: {
        'tt': ["overseas_education","guaranteed_investment_certificate","examination_fee","medical_treatment_abroad","emigration_fee_payment","VISA_fees_payment","leisure_travel"],
        'fc': ["buy_card"],
        'cn': ["buy_currency"]
    },
    PRODUCT_PURPOSE: {
        'buy_currency': ["personal_trip","education","medical_treatment"],
        'buy_card': ["personal_trip","education","medical_treatment"]
    },
    TOKENS: ['country-list', 'employees'],
    QUOTSTATUS: ['Approved', 'Expired', 'Rejected', 'Pending'],
    ORDERSTATUS: ['Cancelled', 'In Progress', 'Converted'],
    AGENT_CODES_FOR_LOGOS : ['38619', '91111','51293'],
    PAYMENT_ENV : process.env.REACT_APP_PAYMENT_ENV,
    UAT_AMOUNT : process.env.REACT_APP_UAT_AMOUNT,
    PAYMENT_LINK : process.env.REACT_APP_PAYMENT_LINK,
    PAYMENT_API_KEY : process.env.REACT_APP_PAYMENT_API_KEY,

    QUOTATION_EXPIRY_TIME:3600 , // means after 1 hour after the mail is sent

    ICICIBANK:{
        Id:1,
        Name:"ICICI Bank Ltd",
        AccountHolder:"CAPITAL INDIA FINANCE LIMITED REMITX",
        AccountNumber:"055505009567",
        IFSCCode:"ICIC0000555",
        Address:"ICICI Bank Ltd, ICICI Bank Tower, Bandra Kurla Complex, Bandra East, Mumbai 400051"
    
    },
    
    HDFCBANK:{
        
        Id:2,
        Name:"HDFC Bank Ltd",
        AccountHolder:"CAPITAL INDIA FINANCE LIMITED REMITX",
        AccountNumber:"50200061768964",
        IFSCCode:"HDFC0002869",
        Address:"Modh Vanik Boarding School, Bldg No.4, Swami Gyanjivandas Marg, Next Ramee Hotel, Station Road, Dadar East, Mumbai 400014"
    
    },
    
    YESBANK:{
        Id:3,
        Name:"Yes Bank Ltd",
        AccountHolder:"CAPITAL INDIA FINANCE LIMITED REMITX",
        AccountNumber:"026881300001785",
        IFSCCode:"YESB0000268",
        Address:"Unit No.2-A 1, Ground Floor, Hallmark Business Plaza, Gurunanak Hospital Road, Kalanagar, Bandra East, 400051"
    
    },
    SERVER_ERROR:"Apologies, our server encountered an issue. We're on it! Please retry shortly. Thanks for your understanding."


}
